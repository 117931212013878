#header-create-conversation-list {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 8px 15px;
      cursor: pointer;

      &:hover {
        background: var(--flex-grey1002);
      }

      .icon-text-wrapper {
        .ant-typography {
          font-size: 13px;
          padding-left: 3px;
        }
      }
    }
  }
}

.create-conversation-popover {
  min-width: 80px;

  .ant-popover-inner {
    padding-bottom: 5px;

    .ant-popover-title {
      color: var(--color) !important;
      background: transparent !important;
      padding: 12px 16px 8px !important;
    }
  }
}
