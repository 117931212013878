@import "../../../styles/partials/mixins";

.header-auth-user {
  background-color: transparent;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  position: relative;

  @include clickable();

  figure {
    @include clickable();
  }

  .notifications-disabled {
    background: var(--red-main);
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -2px;
    margin-top: -25px;
    z-index: 1;
    svg {
      color: white;
    }
  }
}
