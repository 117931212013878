.absolute-voice-controllers {
  position: absolute;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  right: 0;

  .conversation-message-content {
    background: var(--BG-FFFFFF) !important;
  }

  .conversation-message-replies {
    display: none;
  }

  .conversation-message-voice-call {
    background: transparent;
  }

  &.minimized {
    bottom: 36px;
    background: transparent;
  }

  &.mini {
    bottom: 0;
    padding: 5px 15px 10px;
    background: var(--BG-FFFFFF);
  }

  .call-selector {
    width: 100%;
    height: 25px;
    z-index: 1;
    position: relative;

    .background {
      width: 100%;
      background: var(--COLOR-FFFFFF);
      border: 1px solid var(--flex-grey1001);
      border-radius: 4px;
      display: flex;
      align-items: end;
      position: absolute;
      bottom: 0;
    }

    .calls {
      flex-shrink: 0;
      flex-grow: 1;
      font-size: 10px;
      padding: 2px;
    }

    .call {
      padding: 1px 3px;
      border-radius: 3px;
      &.selectable {
        cursor: pointer;
        padding: 7px 3px;
        margin-bottom: 2px;
        &:hover {
          background: var(--flex-grey1001);
        }
      }
      .voice-controller-info {
        margin-bottom: 0;
        justify-content: start;
        width: calc(100% - 16px);
      }
    }

    .arrow {
      position: absolute;
      right: 3px;
      bottom: 2px;
      display: none;
      transition: transform .25s;
    }

    &.has-many {
      .arrow {
        display: block;
      }
    }

    &:hover {
      .arrow {
        transform: rotate(180deg);
      }

      .call-dropdown {
        display: block;
      }
    }

    .call-dropdown {
      display: none;
      width: 100%;
    }

    .call-selected {
      display: flex;


    }
  }
}
