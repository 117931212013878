@import "../../../../styles/partials/mixins";

.conversation-message {

  .conversation-message-replies {
    position: relative;
    .conversation-message-replies-reply {
      display: flex;
      transition: opacity .25s;

      &:hover {
        opacity: 1;
      }

      .arrow {
        padding-left: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
      }
      .message {
        padding: 9px 13px;
        font-size: 12px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 3px;
        border: 1px solid var(--flex-grey1001);
        flex: 1;

        .title {
          display: flex;
          align-items: center;
          padding-right: 5px;
          font-size: 11px;

          .text {
            line-height: 16px;
            white-space: nowrap;
          }
        }

        .channel-icon {
          margin-right: 8px;

          .channel-icon-secondary {
            > .circle-icon {
              border: 1px solid rgba(255, 255, 255, 0.25);
            }
          }
        }

        .message-title {
          max-width: 450px;
          line-height: 16px;
          font-size: 11px;

          @include ellipsis;
        }

        .view-details {
          font-size: 11px;
          color: var(--blue-main);
          margin-left: auto;
          opacity: 0;
          transition: opacity .25s;
        }
      }

      &:hover {
        .view-details {
          opacity: 1;
        }
      }
    }
  }

}

.tab-mini-conversation-list-view-container {
  .conversation-message-replies {
    .conversation-message-replies-reply {
      opacity: .75;
      .arrow {
        width: 25px;
      }
      .message {
        padding: 5px 4px;
        .title {
          font-size: 10px;
          padding-right: 3px;
        }
        .message-title {
          font-size: 10px;
          max-width: 130px;
        }
        .view-details {
          display: none;
        }
      }
    }
  }
}

.master-wrp.dark {
  .conversation-message-replies-reply {

  }
}
