.conversation-disposition-note {
  .conversation-message-content {
    font-size: 12px;
    background: rgba(102, 107, 211, 0.1) !important;

    .disposition-wrapper {
      margin: 10px 0 7px;
    }
  }
}
