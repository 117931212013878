@import "../../../../../styles/partials/mixins";

.conversation-message-form-submission {
  .conversation-message-content {
    background: rgba(114, 195, 219, 0.25) !important;

    .missing_body,
    .anonymized_body {
      opacity: 0.5;
      border: 1px dashed var(--flex-grey1003);
      padding: 15px 15px;
      display: block;
      transition: opacity 0.4s;
      text-align: center;

      &:hover {
        opacity: 1;
      }
    }
  }
  .attachments {
    opacity: 0.75;
    margin: 5px 0;
  }

  .attachment {
    font-size: 10px;
    display: flex;
    float: left;
    margin-right: 5px;
    margin-top: 5px;

    .text {
      max-width: 140px;
    }
      &.anonymized {
        cursor: not-allowed;
      }

      .text {
        max-width: 140px;
      }

    .download {
      margin-left: 8px;
    }
  }

  table.data {
    margin-top: 20px;
    font-size: 12px;
    border-top: 1px solid var(--flex-grey1501);
    tr {
      td {
        padding: 5px 12px;
        border-bottom: 1px solid var(--flex-grey1501);
        &.key {
          font-size: 11px;
          background: var(--flex-grey1501);
          span {
            opacity: .7;
            @include ellipsis;
            max-width: 180px;
          }
        }
      }
    }
  }
}
