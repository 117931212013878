.conversation-wrapper {
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow-x: hidden;

  >.secondary-view {
    min-width: 35%;
    overflow: hidden;
    border: 1px solid var(--COLOR-E8ECEE);
    box-shadow: 0 0 10px var(--COLOR-E8ECEE);
    position: relative;

    >.secondary-list {
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .conversation-messages-timeline {
        overflow-y: initial;
      }

      .conversation-message-voice-call {
        .call-info {
          .call-waiting {
            display: none;
          }
        }
      }
    }
  }

  .conversation-container {
    flex-direction: column;
    display: flex;
    height: 100%;
    background-color: var(--COLOR-FDFEFE);
    flex-grow: 1;
    overflow-x: hidden;

    .header {
      display: flex;
      flex-direction: row;
      background-color: var(--COLOR-FAFBFC);
      padding: 15px 0;

      >.box {
        flex: 1;
        padding: 5px 16px;
        position: relative;

        >.vr {
          background-color: var(--COLOR-E8ECEE);
          color: var(--COLOR-E8ECEE);
          width: 2px;
          height: 90%;
          position: absolute;
          right: 0;
          top: 5%;
        }

        >.customer-journey {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          position: relative;
          padding-right: 0px;

          .journey-open {
            svg {
              margin-left: 15px;
              background-color: var(--COLOR-E7E9EE);
              color: var(--COLOR-516395);
              border-radius: 3px;
              padding: 3px;
              position: absolute;
              right: -15px;
              top: -5px;
            }
          }
        }

        &:nth-child(2) {
          max-width: 300px;
        }

        &.conversation-contact-box,
        &.conversation-journey-box {
          max-width: 280px;
        }

        &:last-child {
          .vr {
            display: none;
          }
        }
      }
    }

    &>.body {
      display: flex;
      overflow: hidden;
    }
  }

  .conversation-message-draft {
    &>.body {
      display: block;
    }
  }
}