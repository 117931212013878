aside {
  background-color: var(--COLOR-F3F3F5);
  position: relative;

  #stats-hide-preview {
    position: absolute;
    z-index: 1;
    opacity: .2;
    transition: opacity .4s;

    .icon-wrapper {
      padding: 2px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    #stats-hide-preview {
      opacity: .7;
    }
  }

  .aisde-switch {
    position: absolute;
    top: -16px;
    right: 0;
    padding: 2px;
    cursor: pointer;
  }
}
