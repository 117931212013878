@import "../../../styles/partials/mixins";

.stats-widget-wrapper {
  .splits {
    .split-row {
      overflow: hidden;
      display: flex;
      gap: 5px;
      .split {
        position: relative;
        h2 {
          @include ellipsis;
        }
      }
    }
  }
}
