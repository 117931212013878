#admin-menu {
  width: 100%;
  top: 95px;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  overflow: hidden;
  opacity: 0;
  display: none;
  transition: opacity 2.5s;
  flex-shrink: 0;

  &.active {
    display: flex;
    opacity: 1;

    .menu {
      margin-left: 0;
    }
  }

  .right-cover-layout {
    display: flex;
    flex-grow: 1;
    background-color: transparent;
  }

  &.fixed {
    display: block;
    opacity: 1;
    width: 300px;
    position: relative;
    top: 0;

    .right-cover-layout {
      display: none;
    }

    .menu {
      margin-left: 0;
    }
  }

  .menu {
    transition: margin-left 0.45s linear;
    transition: margin-left 0.45s linear;
    transition: margin-left 0.45s linear;
    position: absolute;
    width: 300px;
    margin-left: -300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    background-color: var(--COLOR-F7F7F9);
    top: 0;
    bottom: 0;
    left: 0;

    .ant-menu-item {
      font-size: 12px;
      text-transform: capitalize;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title .-title-content,
      .ant-menu-submenu-title svg,
      .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: var(--color);
      }
    }

    .ant-menu-sub,
    .ant-menu-item {
      background-color: transparent;

      &.ant-menu-item-selected {
        background-color: var(--flex-grey1001);
      }
    }

    .ant-menu-sub {
      background-color: var(--flex-grey1001);
    }

    .ant-menu-item {
      &.ant-menu-item-selected {
        background-color: var(--flex-grey1001);

        &::after {
          border-color: var(--blue-main);
        }
      }
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        span,
        svg {
          color: var(--color);
        }
      }
    }

    .ant-menu-submenu-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin-right: 16px;
      }

      span {
        font-size: 12px;
        color: rgba(0, 5, 51, 1);
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}
