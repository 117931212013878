@mixin error {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--red-main);
  position: absolute;
  cursor: help;
}

.journey-nodes-container {
  svg {
    overflow: visible;
  }

  .react-flow__edges {
    z-index: 0 !important;
  }

  position: static;

  #journey-nodes-container-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    height: 650px;

    >.side-div {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;

      >.side-list {
        display: flex;
        width: 212px;
        flex-grow: 1;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .ant-collapse {
          .ant-collapse-content-box {
            overflow: hidden;

            .icon-text-wrapper {
              width: 100%;
              height: 30px;
              border-bottom: 1px solid rgba(150, 150, 150, 0.2);
              float: left;
              padding: 2px 5px;
              font-size: 12px;
              transition: background-color 0.25s;

              .icon {
                opacity: 0.7;
              }

              &:last-child {
                border: 0;
              }

              &:hover {
                background: rgba(150, 150, 150, 0.2);
                border: 0;
              }
            }
          }
        }

        >.side-list-item {
          min-width: 0;
          font-size: 10px;
          margin: 6px;
          overflow-x: hidden;
          cursor: pointer;
        }
      }

      .side-buttons {
        margin-top: 10px;

        > button {
          display: inline-block;
          font-size: 13px;
          cursor: pointer;
          width: 100%;

          &:first-child {
            margin-right: 2%;
          }

          &:last-child {
            margin-top: 4px;
            width: 100%;
          }
        }
      }
    }
  }

  .port-input-view {
    width: 16px;
    height: 16px;
    background-color: grey;
    border: 1px solid grey;
    border-radius: 50%;
    left: 19px;
    top: 12px;
    position: absolute;

    &.required {
      background-color: black;
    }

    .dot {
      width: unset;
      height: unset;
      margin: unset;
    }

    .error {
      @include error;

      width: 60%;
      height: 60%;
      top: -20%;
      left: -20%;
    }
  }

  .port-output-view {
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-bottom: -10px;
    font-size: 10px;
  }
}

.node-modal {
  display: flex;
  flex-direction: column;

  >.title {
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  >.body {
    margin-top: 10px;
  }
}

.react-flow__node-input {
  padding: 0;
  border: none;
}

.react-flow__node-output {
  padding: 0;
  border: none;
}

.react-flow {
  .react-flow__controls {
    button.react-flow__controls-button {
      color: var(--COLOR-000533) !important;

      svg {
        color: var(--COLOR-000533) !important;
      }
    }
  }

  .react-flow__edges {
    .react-flow__edge {
      cursor: pointer;
      .react-flow__edge-path {
        opacity: .5;
        transition: opacity .25s;

        &:hover {
          opacity: .9;
        }
      }

      &.selected {
        .react-flow__edge-path {
          stroke: #FF0072;
          animation: dashdraw 0.5s linear infinite;
          stroke-dasharray: 6;
          opacity: 1;
        }
      }
    }

    .react-flow__connection-path {
      animation: dashdraw 0.5s linear infinite !important;
      stroke-dasharray: 6;
    }
  }

  .react-flow__nodes {
    .react-flow__node {
      &.selected {
        .node {
          border: 1px solid var(--blue-main);
        }
      }

      &.highlight {
        .node {
          border: 1px solid var(--orange-dark);
        }
      }

      .node {
        display: flex;
        background-color: white;
        box-shadow: 0 0 9px 6px rgba(0, 5, 51, 0.1);
        border: 1px solid white;
        border-radius: 2px;
        flex-direction: column;
        min-width: 250px;
        padding: 7px;

        >.body {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 15px;
          font-size: 12px;
        }

        .title {
          .icon-text-wrapper {
            .ant-typography,
            .icon-wrapper svg {
              color: #000000;
            }

            .icon-wrapper svg {
              opacity: 0.5;
            }
          }
        }

        .error {
          @include error;

          top: 5px;
          right: 5px;
        }

        .node-header {
          display: flex;
          flex-direction: column;
          width: 100%;

          >.hr {
            width: 100%;
            height: 1px;
            background-color: var(--COLOR-E8ECEE);
            border: none;
          }

          >.row1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            >.title {
              margin-left: 5px;
            }
          }

          >.row2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 7px;

            >.subtitle {
              font-size: 10px;
            }
          }
        }
      }

      .react-flow__handle {
        &.react-flow__handle-bottom {
          background: var(--COLOR-E7E9EE);
          color: var(--COLOR-000533);
          border: 0;

          .error {
            @include error;

            width: 10px;
            height: 10px;
            left: -3px;
            top: -3px;
          }

          .info {
            @include error;

            opacity: 0.4;
            background: var(--blue-grey);
            width: 10px;
            height: 10px;
            right: -3px;
            top: -3px;
          }
        }
      }
    }
  }
}

.ant-form {
  .ant-form-element-properties_conditions_rules_key {
    width: 190px;
  }

  .ant-form-element-properties_body_key,
  .ant-form-element-properties_headers_key,
  .ant-form-element-properties_query_params_key {
    width: 200px;
    margin-right: 5px !important;
  }

  .ant-form-element-properties_conditions_rules_comparison {
    width: 120px;
    margin-right: 5px !important;
  }

  .ant-form-element-properties_body_type,
  .ant-form-element-properties_headers_type,
  .ant-form-element-properties_query_params_type {
    width: 120px;
    margin-right: 5px !important;
  }

  .ant-form-element-properties_conditions_rules_value,
  .ant-form-element-properties_body_value,
  .ant-form-element-properties_headers_value,
  .ant-form-element-properties_query_params_value {
    width: 180px;
    margin-right: 5px !important;
  }

  .ant-form-element-properties_query_params_variable_alias {
    margin-right: 5px !important;
  }

  .ant-form-element-properties_conditions_rules_data_key {
    width: 190px;
  }
}
