.admin-list-page-header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin: 0;

    span {
      opacity: .7;

      &.filters {
        font-size: 12px;
        font-weight: lighter;
        padding: 2px 7px 3px;
        margin-left: 5px;
        font-family: Manrope, serif;
        background: var(--flex-grey1003);
        border-radius: 5px;
        cursor: pointer;
        transition: all .25s;
        &:hover {
          background: var(--flex-grey1004);
          opacity: 1;
        }
      }
    }
  }

  .display-deleted {
    > .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      > span {
        height: 16px;
        display: flex;
        align-items: center;
      }
      .ant-checkbox {
        top: 0;
      }
      .count {
        background: var(--flex-grey1003);
        padding: 3px 7px 4px;
        border-radius: 5px;
        margin-left: 5px;
        font-size: 11px;
        font-weight: bold;
        line-height: 11px;
      }
    }
  }
}
