.channel-icon-primary {
  z-index: 1;
}

.channel-icon-bordered{
}

.channel-icon-secondary,
.channel-icon-secondary-additional {
  position: absolute;
  left: 14px;
  z-index: 0;
}

.channel-icon-secondary-additional {
  z-index: 1;

  figure {
    font-size: 8px;
    line-height: 8px;
    align-items: center;
    justify-content: center;
    background-color: var(--COLOR-000533);
    color: var(--COLOR-FDFEFE);
  }
}
