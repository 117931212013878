.summary-open {
    height: auto;
    max-height: 1000px;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.summary-closed {
    height: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}

.toggle-button-container {
    position: relative;
    bottom: 8px;
    border-radius: 2px;
    width: fit-content;
    height: 0;
    margin: 2px auto;
    z-index: 100;

    .toggle-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border: 1px solid var(--flex-grey1001);
        border-radius: 2px;
        cursor: pointer;
    }
}

.conversation-summary-wrapper {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;

    .conversation-summary-content {
        display: flex;
        flex-direction: column;
        background: var(--COLOR-F6F8F9);
        border-radius: 4px;
        padding: 24px;
        margin: 8px auto 8px;
        max-width: 80%;

        .conversation-summary-content-header {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 8px;

            .conversation-summary-content-header-title {
                margin: 2px 0 0 0;
            }
        }

        .conversation-summary-content-footer {
            margin-top: 8px;
            font-size: 10px;

        }
    }
}

.ant-divider {
    margin: 0;
    box-shadow: 2 2 2 2px var(--flex-grey1001);
}

.conversation-summary-error {
    color: red;
    font-size: 12px;
    margin: 12px auto 24px;
}

.conversation-summary-loading {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.conversation-summary-button {
    position: relative;
    margin: 12px auto;

    .conversation-summary-button-content {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.icon-wrapper {
    margin-top: 2px;
}