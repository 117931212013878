.search-section-header {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  .search-section-title {
    display: flex;
    justify-content: flex-start;
    font-size: 8px;
    color: var(--COLOR-000533);
    opacity: 0.5;
    font-weight: 700;
    text-transform: uppercase;
  }

  .s-clear-btn {
    display: flex;
    justify-content: flex-end;
    font-size: 8px;
    font-weight: 800;
    color: var(--blue-main);
    cursor: pointer;
    text-transform: uppercase;
  }
}

.recent-searched-list,
.search-result-list,
.recent-viewed-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    width: auto;
    height: auto;
    cursor: pointer;
    transition: background-color 0.25s;
    padding: 6px 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;

    &:hover {
      background: var(--flex-grey1751);
    }

    .channel-wrp {
      margin-right: 6px;
    }

    .result-text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .result-row {
        font-size: 10px;
        line-height: 16px;
        display: flex;
        opacity: .7;

        &:first-child {
          opacity: 1;
        }

        span, strong {
          margin-right: 3px;
        }

        strong {
          opacity: .5;
        }

        .emails, .phones {
          max-width: 250px;
        }
      }
    }

    &:hover {
      .closeable-icon {
        display: flex;
      }
    }

    .closeable-icon {
      cursor: pointer;
      display: none;
      margin-left: auto;
    }

  }

  .contacts-wrapper {
    display: flex;
    flex-direction: row;

    >.avatar {
      margin: 0;
      margin-right: 10px;
    }

    > .contact-info {
      display: flex;
      flex-direction: column;

      .contact-data {
        display: flex;
      }
    }
  }
}
