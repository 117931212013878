@import "../../../styles/partials/mixins";

.voice-endpoint-suggestions {
  font-size: 12px;

  ul.voice-suggestions-list {
    margin: 0;
    list-style-type: none;
    padding: 0;
    max-height: 220px;
    overflow-x: auto;

    .no-results {
      opacity: 0.8;
      font-style: italic;
      text-align: center;
      display: block;
      padding: 12px 0;
      font-size: 12px;
    }

    li {
      opacity: 0.8;
      transition: all 0.25s;
      position: relative;
      border-top: 1px solid var(--flex-grey1001);

      &.group-title {
        padding: 2px 5px;
        font-size: 11px;
        opacity: .5;
        font-weight: bold;
        text-transform: uppercase;
        border-top: 0;
        padding-top: 10px;
      }

      .call-btn {
        position: absolute;
        padding: 4px 7px;
        top: 50%;
        margin-top: -9px;
        right: 5px;
        height: auto;
        font-size: 10px;
        line-height: 10px;
        opacity: 0;
        border-radius: 3px;
      }

      &:hover,
      &.selected {
        opacity: 1;
        background: var(--flex-grey1001);
        border-top-color: transparent;
      }

      &:first-child {
        border-top: 0;
      }

      .clickable:hover,
      &.selected .clickable {
        .call-btn {
          opacity: 1;
        }
      }

      .li-content {
        padding: 8px 7px;
      }

      .postfix {
        font-size: 10px;
        opacity: 0.7;
        flex-grow: 0;
        text-align: right;

        .color {
        }
      }

      ul {
        padding: 0;

        li {
          .li-content {
            padding-left: 14px;
          }
        }
      }
    }
  }

  .queue-route-selection {
    width: 100%;
    overflow: hidden;
    background: var(--flex-grey1002);

    .ant-select {
      width: 100%;
      font-size: 12px;

      .ant-select-selector {
        border: 0 !important;
        height: 28px;

        .ant-select-selection-item {
          @extend .queue-route-selection-option;
        }
      }
    }
  }

  &.standalone {
    width: 100%;
    background: var(--BG-FFFFFF);

    .call-transfer-input {
      padding: 3px 8px;
      background: var(--BG-FFFFFF);
      border: 0;
      width: 100%;
      font-size: 16px;

      &::placeholder {
        font-style: italic;
        opacity: 0.7;
      }
    }
  }
}

.queue-route-selection-option {
  .arrow {
    margin: 0 5px;
    opacity: 0.5;
  }

  .icon-text-wrapper {
    .text {
      max-width: 100px;

      @include ellipsis;
    }
  }
}

.ant-popover.voice-endpoint-suggestions {
  width: 220px;
}

.queue-route-selection-options {
  padding: 0 !important;

  .ant-select-item {
    font-size: 12px;
  }

  .ant-select-item-option-content {
    display: flex;

    @extend .queue-route-selection-option;
  }
}
