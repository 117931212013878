.invoice-print {
  max-width: 800px;
  margin: 20px auto;
  padding: 65px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  position: relative;
  display: flex;
  flex-direction: column;

  .invoice-header {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    .right {
      margin-left: auto;
      width: 400px;
      .main-info {
        display: flex;
        border: 1px solid #7a7a7a;
        margin: 5px 0;
        padding: 5px 12px;
        font-size: 12px;
        justify-content: space-between;
        span {
          padding-left: 3px;
          font-weight: lighter;
          opacity: .75;
          font-size: 80%;
        }
      }
      .period {
        padding: 5px 3px;
        text-align: right;
      }
    }
  }

  .subheader {
    display: flex;

    .address {
      margin-bottom: 25px;
      & > div:first-child {
        font-weight: bold;
      }
    }

    .invoice-info {
      width: 320px;
      margin-left: auto;
      padding: 0 12px;
      font-size: 11px;
      .info-row {
        display: flex;
        margin-bottom: 3px;
        .info-title {
          width: 140px;
          opacity: .7;
        }
      }
    }
  }



  .helpdesk {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 11px;
  }

  .details {
    .organization {
      margin-bottom: 15px;
      break-inside: avoid;
      h3 {
        margin-bottom: 8px;
        font-weight: lighter;
        border-bottom: 1px solid var(--flex-grey1006);
        overflow: hidden;

        .invoice-organization-period {
          font-weight: lighter;
          font-family: Manrope, serif;
          padding-left: 6px;
          font-size: 12px;
          opacity: .7;
          float: right;
        }
      }

      table {
        margin-left: 2%;
        width: 98%;
      }

    }
  }

  .addons {
    margin-top: 40px;
    .invoice-post-period {
      span {
        opacity: .7;
      }
    }
  }

  .total {
    margin-top: 60px;
    break-inside: avoid;
    table {
      tr {
        &.total {
          border-bottom: 0;
          font-size: 14px;
          td {
            padding-top: 15px;
          }
        }
      }
    }
  }

  table.price-list {
    width: 100%;
    tbody {
      tr {
        border-bottom: 1px solid #F0F0F0;
        td {
          padding: 2px 5px;
          &.right {
            text-align: right;
          }
          .amount,
          .pieces,
          .percent,
          .file-size {
            span {
              padding-left: 3px;
              font-weight: lighter;
              opacity: .75;
              font-size: 80%;
            }
          }
          .percent span {
            opacity: .75;
            padding-left: 0;
          }
          .duration {
            font-size: 11px;
          }
        }
        &.total {
          font-weight: bold;
        }
      }
    }
  }
  .footer {
    break-inside: avoid;
    border-top: 1px solid var(--flex-grey1001);
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    section {
      .title {
        font-size: 11px;
      }
    }
  }
}

.is-print {
  .invoice-print {
    max-width: none;
    box-shadow: none;
    margin: 0 auto;
    padding: 0;
  }
}
