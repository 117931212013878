.pauses-menu-popup{

}

.pauses-menu-popup-content {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  background-color: var(--COLOR-FDFDFD);

  >.menu-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    margin: 12px;

    >.title {
      font-weight: normal;
      font-size: 12px;
    }

    >.span {
      background-color: #F2A100;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-left: auto;
      display: none;
    }

    >.selected {
      font-weight: bold;
    }
  }
}
