.tag-popover-container {
  width: 260px;
  .select-all {
    background: var(--blue-main);
    color: white;
    padding: 3px 0;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
  }
}
