.tab-mini-conversation-list-view-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  >.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--COLOR-FDFEFE);
    padding: 10px 0;

    > .avatar {
      flex: 0 0 20px;
    }

    > .conversation-message-row {
      .conversation-message {
        max-width: 100%;

        &.conversation-message-voice-call {
          .call-flow {
            display: block;
            .call-bridges {
              margin-left: 3px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
