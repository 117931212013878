.header-notifications {
  height: 100%;
  display: flex;
  align-items: center;

  .ant-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    height: 60px;
    width: 60px;
    background: var(--COLOR-FDFDFD);

    svg {
      color: var(--COLOR-000533);
    }

    sup {
      font-size: 10px;
      line-height: 16px;
      height: 16px;
      width: 16px;
      min-width: 16px;
      padding: 0;
      border: 0;
      box-shadow: none;
      top: 1px;
      right: 1px;
    }
  }
}
