@mixin wrap-height {
  display: flex;
  align-self: baseline;
}

@mixin medium-screens() {
  @media only screen and (max-width: 680px) {
    @content;
  }
}

@mixin clickable() {
  cursor: pointer;
}

@mixin container() {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@mixin verticalList() {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

@mixin title {
  font-size: 12px;
  color: var(--COLOR-000533);
  font-weight: bold;
}

@mixin ellipsis() {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin spin() {
  animation: _spin 1.2s linear infinite;
}

@keyframes _spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin selectable-list() {
  list-style-type: none;
  margin: 0;
  padding: 5px 0;
  background: var(--flex-grey1001);

  li {
    border-bottom: 1px solid var(--flex-grey1002);
    padding: 3px 12px 3px 10px;
    color: var(--COLOR-000533);
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.25s;
    position: relative;
    display: flex;
    width: 100%;

    &:last-child {
      border: 0;
    }

    &.selected,
    &:hover {
      background: var(--flex-grey1002);
    }

    > .text {
      @include ellipsis;
    }

    > .select-btn {
      flex-shrink: 0;
      display: none;
      margin-left: auto;
    }

    &:hover {
      .select-btn {
        display: block;
      }
    }
  }
}

@mixin tabStyle() {
  box-shadow: 0 -1px 8px 0 rgba(0, 5, 51, 0.2);
  border: 1px solid var(--flex-grey1001);
  margin-left: 5px;
  border-radius: 5px 5px 0 0;
  background: var(--BG-FFFFFF);
}

@mixin pulse() {
  @keyframes pulse {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
}
