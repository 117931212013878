.widget-type-pie,
.widget-type-polar {
  .summary {
    position: absolute;
    right: 10px;
    text-align: right;
    .metric {
      font-size: 16px;
      padding-right: 3px;
      border-radius: 3px;
      & > .alignment {
        justify-content: center;
      }
      & > .alignment > .value {
        padding: 1px 3px;
        border-radius: 3px;
      }
      .trend {
        margin-left: 3px;
        .value {
          padding: 0;
        }
      }
    }
    .total {
      opacity: .35;
      display: block;
    }

  }
}
