.conversation-message {

  .conversation-message-reply {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    z-index: 1;
    transition: opacity .25s;

    &.mini {
      top: -17px;
      .actions {
        height: auto;
        border: 0;
        padding: 0;
        background: transparent;
        li {
          background: var(--blue-main);

          .icon-text-wrapper {
            .ant-typography,
            .icon-wrapper svg {
              color: #FFFFFF !important;
            }
          }
        }
      }
    }

    .actions {
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      padding-top: 5px;
      justify-content: center;
      align-items: center;
      margin: 0;
      list-style: none;

      li {
        padding: 4px 9px;
        text-align: center;
        color: var(--blue-main);
        font-size: 12px;
        font-weight: 700;
        border: 1px solid rgba(0, 5, 51, 0.1);
        background: var(--flex-grey1001);
        border-radius: 2px;
        cursor: pointer;
        margin: 0 2px;
        height: 27px;
        display: flex;

        .icon-text-wrapper {
          > .icon-wrapper svg {
            color: var(--blue-main) !important;
            stroke-width: 2px;
            margin-right: 4px;
          }
          > .text {
            color: var(--blue-main);
          }
        }

        &:hover {
          background-color: rgba(57, 83, 254, 1);
          border-color: rgba(57, 83, 254, 1);

          .ant-typography,
          .icon-wrapper svg {
            color: #FFFFFF !important;
          }
        }
      }
    }
  }

  &:hover {
    .conversation-message-reply {
      opacity: 1;
    }
  }
}

