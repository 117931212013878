.voice-calls-modal {
  color: var(--COLOR-000533) !important;
  .ant-modal-footer, .ant-modal-close {
    display: none !important;
  }
  .ant-modal-body {
    display: block !important;
    text-align: left !important;
  }
  h3 {
    text-align: center;
  }
  .voice-call-options {
    .voice-call-option {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 4px 3px;
      cursor: pointer;
      border-bottom: 1px solid var(--flex-grey1001);
      transition: background .25s;
      &:hover {
        background: var(--flex-grey1001);
      }
      .number {
        font-weight: bold;
        margin-right: 5px;
      }
      .postfix {
        margin-right: 5px;
        font-size: 12px;
      }
      .asterisk {
        margin-left: auto;
        font-size: 11px;
        padding: 2px 8px;
        background: var(--flex-grey1001);
      }
    }
  }
}
