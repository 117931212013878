@import '../../../styles/partials/_mixins';

@include pulse;

.call-bar {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .call-transfer-input {
    &::placeholder {
      font-style: italic;
      opacity: 0.7;
    }
  }

  .sip-info {
    position: absolute;
    top: -7px;
    left: 0;
    z-index: 1;
    .sip-state {
      font-size: 10px;
      .sip-name {
        background: var(--flex-grey1003);
        padding: 1px 5px;
        margin-right: 3px;
        border-radius: 3px;
      }
      .title {
        font-size: 10px;
        opacity: .4;
        font-weight: lighter;
      }

      .state {
        font-weight: bold;
        padding: 1px 5px;
        border-radius: 3px;
      }

      &.unregistered {
        .state {
          animation: pulse 2s infinite;
          background: var(--red-main);
          color: #FFFFFF;
        }
      }
    }

  }

  .actions {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: var(--COLOR-FDFDFD);
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    position: relative;

    .audio-settings {
      &.warning {
        background-color: var(--orange-dark);
        border-color: var(--orange-dark);
        svg {
          color: black !important;
        }
      }
      &.error {
        background-color: var(--red-main);
        border-color: var(--red-main);
        svg {
          color: white !important;
        }
      }
    }

    &:hover {
      .volume-icon {
        display: block;
      }
    }

    .volume-icon {

      display: block;

      .slider-wrapper {

        padding: 9px 0 9px 0;
        border-radius: 2px;
        border-left: 0 !important;
        position: absolute;
        width: 0;
        top: -1px;
        left: 23px;
        z-index: 1;
        background: var(--COLOR-FDFDFD);
        margin: 0;
        box-sizing: border-box;
        transition: width .25s;
        overflow: hidden;

        .volume-slider {
          margin: 0 15px 0 15px;
        }
      }


      &:hover {
        .slider-wrapper {
          border: 1px solid var(--COLOR-F4F3F6);
          width: 100px;
        }
      }
    }

    li {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      border: 1px solid var(--COLOR-F4F3F6);
      background: var(--BG-FFFFFF);
      box-sizing: border-box;
      color: var(--color);
      margin-right: 4px;
      cursor: pointer;
      position: relative;

      > .icon-wrapper {
        padding: 9px;
      }

      svg {
        margin-right: 0 !important;
      }

      &.action-grow {
        background-color: var(--COLOR-FDFEFE);
        flex-grow: 1;
        position: relative;
        min-width: 140px;

        .sip-states {
          padding: 0 5px 0 7px;

          .sip-state {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: var(--COLOR-000533);

            &.sip-state-Registered {
              background: var(--green-main);
            }

            &.sip-state-Unregistered,
            &.sip-state-Terminated {
              background: var(--red-main);
            }

            &.sip-state-Initial {
              opacity: 0.5;
            }
          }
        }

        input {
          width: 100%;
          height: 100%;
          border: 0;
          background: transparent;
          padding-left: 0;
          font-size: 14px;
          outline: none;

          &::placeholder {
            font-size: 11px;
            font-style: normal;
          }
        }
      }
    }
  }
}
