@import "../../../../../styles/partials/mixins";

.widget-type-table,
.widget-type-grid {
  display: block;
  width: 100%;
  .unit {
    opacity: .5;
    font-size: 90%;
  }
  /*
  .avatar {
    position: relative;
    padding-left: 32px;
    img {
      position: absolute;
      left: -5px;
    }
  }

   */
  table {
    width: 100%;
    border-spacing: 0;
    thead,
    tfoot {
      .total-title {
        color: #A7A7A7;
      }
    }
    .trend {
      height: 80%;
    }
    thead {
      tr {
        th {
          font-size: 12px;
          height: 26px;
          font-weight: 300;
          background-color: #ffffff;
          position: relative;
          min-width: 30px;
          background: var(--flex-grey15005);
          .prefixes {
            top: -15px;
            white-space: nowrap;
            display: flex;
            left: 0;
            right: 0;
            overflow: hidden;
            flex-wrap: wrap;
            position: relative;
          }
          .text {
            @include ellipsis;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            white-space: nowrap;
            text-align: left;
            padding: 4px 5px;
            font-weight: bold;
          }
        }
      }
    }
    tbody {
      tr {
        border-width: 0;
        td, th {
          font-size: 12px;
          vertical-align: middle;
          border-width: 0;
          .value {
            padding: 2px 6px 2px 5px;
            border-radius: 3px;
            &.ellipsis {
              max-width: 180px;
              @include ellipsis;
            }
          }
        }

        td.y-summary {
          background: var(--flex-grey150025);
          font-weight: bold;
        }

        &.sub {
          td, th {
            padding: 2px 8px;
            font-size: 14px;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          border-top: 1px solid rgba(150,150,150,.35);
          font-weight: 900;
          padding: 0;
          .metric {
          }
          .value {
            font-weight: 900;
            padding: 3px 5px;
          }
        }
        &.total {
          td {
            background: var(--flex-grey1501);
            font-weight: bold;
          }
        }
      }
    }
  }

  & > table {
    tbody {
      tr.min:nth-child(odd) {
        background-color: rgba(100,100,100,.18);
      }
      tr.min:nth-child(even) {
        background-color: rgba(100,100,100,.15);
      }

      tr.sub:nth-child(odd) {
        background-color: rgba(100,100,100,.08);
      }
      tr.sub:nth-child(even) {
        background-color: rgba(100,100,100,.05);
      }

    }
  }
}
