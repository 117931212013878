@import "../../../styles/partials/mixins";

.tabs-wrapper {

  .expanded {
    background-color: var(--COLOR-000533) !important;

    >.title-container {
      >.text {
        color: var(--COLOR-FDFEFE) !important;
      }
    }

    >.tab-header-icons {
      >.icon-wrapper {
        svg {
          color: var(--COLOR-FDFEFE);
        }
      }
    }
  }

  .tab-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1px 7px;
    background-color: var(--COLOR-FAFBFC);
    border-bottom: 1px solid var(--COLOR-E8ECEE);
    cursor: pointer;
    flex-shrink: 1;
    overflow: hidden;
    height: 32px;

    >.title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      position: relative;

      &:hover {
        .add-call {
          opacity: 1;
        }
      }

      >.text {
        margin-left: 5px;
        color: var(--COLOR-000533);
        flex-shrink: 1;
        height: 24px;
        display: flex;
        font-size: 12px;
        align-items: center;
        position: absolute;
        left: 18px;
        right: 0;

        span {
          line-height: 18px;
        }

        span:not(:first-child) {
          @include ellipsis;
        }
      }
    }

    >.tab-header-icons {
      display: flex;
      flex-shrink: 0;
      margin-left: auto;

      >.icon-wrapper {
        padding: 2px;
        margin-left: 3px;
        border-radius: 2px;
        &.active {
          background: var(--COLOR-000533);
          svg {
            color: var(--BG-FFFFFF);
          }
        }
      }
    }
  }

}
