.admin-list-view {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 95px);
  background: var(--admin-page-back-color);
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.ant-table-content {
  .ant-table-tbody {
    .table-cell-deleted_at {
      font-size: 11px;
    }
  }
}

.table-custom {
  .ant-table {
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  .ant-table-thead {
    border: 0;
    background: transparent;

    >tr {
      border: 0;

      >th {
        border: 0;
        background: transparent;
        color: var(--table-cell-color);
        font-weight: bold;
      }
    }
  }

  .ant-table-tbody {
    >tr {
      border-radius: 5px !important;
      border: 0;

      td {
        border: 0;
        border-top: 1px solid var(--table-row-border-color);
        border-bottom: 1px solid var(--table-row-border-color);
        background: var(--table-row-back-color);
        color: var(--table-cell-color);
        font-weight: normal;

        &:first-child {
          border-left: 1px solid var(--table-row-border-color);
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-right: 1px solid var(--table-row-border-color);
          border-radius: 0 5px 5px 0;
        }
      }

      &:hover {
        background: transparent;

        td {
          background: var(--table-row-hover-back-color);
        }
      }
    }
  }
}

.table-operation-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  > span,
  > div {
    margin: 0 2px;
  }

  .table-icon-wrp {
    cursor: pointer;

    svg {
      color: var(--table-icons-color);
    }

    &:first-child {
      margin-right: 8px;
    }
  }
}

.table-tooltip-custom {
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      width: 10px;
      height: 10px;
      background-color: rgba(0, 5, 51, 1);
    }
  }

  .ant-tooltip-inner {
    background-color: rgba(0, 5, 51, 1);
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    border-radius: 5px;
  }
}

.table-tooltip-custom.dark {
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: #FFFFFF;
    }
  }

  .ant-tooltip-inner {
    background-color: #FFFFFF;
    color: rgba(0, 5, 51, 1);
  }
}
