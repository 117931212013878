@import "fonts";
@import "partials/colors";
@import "partials/variables";
@import "partials/mixins";
@import "./table";
@import "./file-viewer";

body {
  margin: 0;
  font-family: Manrope, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0.5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #666666;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.no-display {
  display: none;
}

hr {
  all: unset;
  border-top: 1px solid var(--color);
  height: 0;
  opacity: 0.5;
  width: 100%;
  display: block;
}

.title {
  @include title();
}

.ant-timeline-item-tail {
  border-left-style: dotted;
}

.ant-timeline-item-content {
  min-height: 10px !important;
  top: 0;
}

.ant-timeline-item-head-custom {
  background: transparent;
}

.ant-timeline-customer-journey {
  > li {
    > .ant-timeline-item-content {
      margin-left: 20px;
      top: -3.001px;
    }

    > .ant-timeline-item-tail {
      left: unset;
    }
  }
}

.ant-row-checkbox {
  .ant-radio {
    top: 0;
  }
}

.agent-pause-radio-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reply-edit-icon {
  width: 24px;
  height: 24px;
  background: var(--COLOR-F6F8F9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--COLOR-000533);
  border-radius: 50%;
}

.ant-checkbox-inner {
  background: var(--checkbox-background);
  border-color: var(--checkbox-border);
}

.reply-timeline-custom {
  &.reply-not-editable {
    .timeline-contents {
      background: rgba(253, 0, 0, 0.2);
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid var(--ant-menu-border-right-color);
}

/*
.menu-custom .ant-menu-submenu-title svg{
  color: var(--ant-menu-svg-icon-color);
}
.menu-custom .ant-menu-submenu-title span{
  color: var(--ant-menu-text-color);
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
  color: var(--ant-menu-arrow-icon-color);
}
.ant-menu-sub.ant-menu-inline{
  background: var(--ant-sub-menu-back-color);
}
.ant-menu {

  > .ant-menu-submenu-selected {
    > .ant-menu-submenu-title {
    }
  }

  .ant-menu-submenu {
    .ant-menu-item {
      .ant-menu-submenu-title{
        color: var(--flex-grey1001);
      }
      &.ant-menu-item-selected,
      &:active {
        background: var(--flex-grey1001);
        &:after {
          border-color: var(--blue-main);
        }
      }
    }
  }
}
*/

/*
.m-cj-timeline-item{

  width: 100%;
  margin-left: 10px;
  .ant-timeline-item-head{
    margin-top: 26px;
    z-index: 1;
  }
  >.ant-timeline-item-tail{
    top: 36px !important;
  }
  &:last-child{
    >.ant-timeline-item-tail{
      display: none;
    }
  }
}

 */

.ant-tooltip {
  font-size: 12px;

  &.error {
    .ant-tooltip-inner,
    .ant-tooltip-arrow .ant-tooltip-arrow-content {
      background: var(--red-main);
      color: white;
    }
  }
}

.content-master-wrp {
  display: flex;
  background: var(--COLOR-FDFEFE);
  flex-grow: 1;
  height: 100px;

  .content-children-wrp {
    flex-grow: 1;
    overflow: auto;
  }
}

.form-error-wrp {
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;

  li {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 12px;
    color: red;
  }
}

.clickable {
  cursor: pointer;
}

a,
.link {
  color: var(--blue-main);
}

.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-primary:hover,
.ant-btn.ant-btn-primary:active {
  background-color: var(--blue-main) !important;
  border-color: var(--blue-main) !important;
  color: #ffffff !important;

  > span {
    color: #ffffff !important;
  }

  .text,
  svg {
    color: #ffffff !important;
    border-color: #ffffff !important;
  }
}

.ant-btn.ant-btn-primary:disabled {
  opacity: 0.5;
}

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-text-wrapper {
    display: flex;
  }
}

button:not(.ant-switch),
.ant-btn {
  background: rgba(150, 150, 150, 0.05) !important;
  border-color: rgba(150, 150, 150, 0.4) !important;
  color: var(--COLOR-000533) !important;

  > span {
    color: var(--COLOR-000533) !important;
  }

  &:hover {
    background: rgba(150, 150, 150, 0.2);
  }

  &[disabled] {
    border-color: rgba(150, 150, 150, 0.4) !important;
    color: rgba(150, 150, 150, 0.4) !important;
  }
}

.ant-btn-primary {
  background-color: rgb(70, 94, 251);
}


.ant-select-clear {
  background: transparent !important;
  color: var(--COLOR-000533) !important;
}

.ant-collapse {
  background: transparent;
  border: 0;

  .ant-collapse-item {
    border: 0;

    .ant-collapse-header {
      color: var(--COLOR-000533);
      border: 0;
      background: rgba(150, 150, 150, 0.2);
      padding: 3px 10px;
      font-size: 12px;
    }

    .ant-collapse-content {
      color: var(--COLOR-000533);
      background: rgba(150, 150, 150, 0.02);
      border: 0;

      .ant-collapse-content-box {
        padding: 3px;
      }
    }
  }
}

.ant-popover {
  min-width: 240px;

  .ant-popover-arrow {
    .ant-popover-arrow-content {
      background: transparent;

      &::before {
        background: var(--COLOR-FDFDFD);
      }
    }
  }

  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 4px;
      color: var(--color);
      background-color: var(--COLOR-FDFDFD);

      .ant-popover-title {
        background-color: var(--COLOR-FDFEFE);
        color: rgba(150, 150, 150, 0.5);
        border-bottom-color: rgba(100, 100, 100, 0.1);
        border-bottom: 0;
        font-size: 12px;
        min-height: unset;
        padding: 4px 12px;
      }

      .ant-popover-inner-content {
        padding: 2px;
        border-radius: 5px;
        color: var(--color);
        width: 100%;
        overflow: hidden;

        .popover-header {
          background-color: var(--COLOR-000533);
          color: var(--COLOR-FFFFFF);
          display: flex;
          flex-direction: column;
          font-size: 11px;
          padding: 6px 0;

          .popover-title {
            padding: 2px 12px;
          }
        }

        .popover-content {
          width: 100%;
          padding: 2px 0;

          .ant-form {
            padding: 3px 7px;
          }
        }

        .ant-tabs-nav {
          margin-bottom: 0;

          .ant-tabs-nav-operations {
            display: none;
          }

          .ant-tabs-nav-wrap {
            background: var(--COLOR-000533);
            color: var(--color);

            .ant-tabs-nav-list {
              width: 100%;

              .ant-tabs-ink-bar {
                width: 100%;
                background: var(--COLOR-FFFFFF);
              }

              .ant-tabs-tab {
                width: 50%;
                margin: 0 !important;

                .ant-tabs-tab-btn {
                  height: 100%;
                  width: 100%;

                  > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    height: 100%;
                    padding: 0 5px 0 11px;

                    > span {
                      font-style: normal;
                      font-weight: bold;
                      font-size: 10px;
                      line-height: 14px;
                      color: var(--COLOR-FFFFFF) !important;
                      opacity: 0.5;
                      justify-content: flex-start;
                      align-items: center;

                      @include ellipsis;

                      &.col-btn {
                        opacity: 1;
                      }

                      &:first-child {
                        text-transform: uppercase;
                      }
                    }
                  }
                }

                &.ant-tabs-tab-active {
                  .ant-tabs-tab-btn {
                    > div > span {
                      &:last-child {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .done-btn {
      background-color: rgba(70, 94, 251, 1);
      color: #ffffff;
      outline: none;
      width: 100%;
      border-radius: 5px;
      min-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 19px;
      border: 0;
      padding: 5px 7px;
      margin-top: 10px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  &.circle {
    border-radius: 50%;
  }

  &.loading {
    .anticon-loading {
      position: absolute;
    }
  }

  .ant-typography {
    overflow-wrap: normal;

    @include ellipsis;

    padding-left: 5px;
  }

  border-color: var(--flex-grey1751);

  svg {
    color: var(--COLOR-000533);
    border-color: var(--flex-grey1751);
  }
}

.ant-typography {
  color: var(--COLOR-000533);
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.disabled {
    opacity: 0.5;
  }

  .icon-wrapper {
    margin-right: 5px;
    flex-grow: 0;
  }

  .ant-typography {
    @include ellipsis;

    flex-grow: 1;
  }
}

.ellipsis {
  @include ellipsis;
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  .full-name {
    margin-left: 5px;
    @include ellipsis;
    max-width: 90px;
  }
}
figure {
  all: unset;
  border-radius: 50%;
  border: 0 solid var(--flex-grey1003);
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  object-fit: cover;
  position: relative;
  cursor: default;
  user-select: none;

  &.avatar {
    img {
      all: unset;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    background: linear-gradient(90deg, #5c258d 0%, #4389a2 100%);
    color: #ffffff;
  }

  &.circle {
    background: var(--COLOR-516395);
  }
}

.ant-modal {
  width: 50%;
  min-width: 800px;
  margin: 50px auto;
  &.ant-custom-modal-form {
    .ant-modal-content {
      border-radius: 0;
      overflow: hidden;
      vertical-align: top;

      .ant-modal-close-x {
        position: absolute;
        background: var(--color);
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
        svg {
          color: var(--BG-FFFFFF);
        }
      }

      .ant-modal-body {
        background: var(--COLOR-FDFEFE);
        color: var(--COLOR-000533);

        .ant-modal-confirm-title {
          color: var(--COLOR-000533) !important;
        }

        .ant-modal-confirm-content {
          color: var(--COLOR-000533) !important;
        }
      }
    }
  }

  .ant-modal-content {
    border-radius: 8px;
    background: var(--COLOR-F3F3F5);

    .ant-modal-header {
      background: var(--COLOR-000533);
      color: var(--COLOR-FFFFFF);

      .ant-modal-title {
        color: var(--COLOR-FFFFFF);
      }
    }

    > button {
      background: transparent !important;

      .ant-modal-close-x {
        color: var(--COLOR-FDFEFE);
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .ant-modal-confirm-body-wrapper {
        width: 100%;
      }

      .antd-img-crop-control {
        button {
          background: transparent !important;
        }
      }

      .ant-modal-confirm-title {
        color: var(--COLOR-000533);
        font-size: 21px;
        font-family: Manrope, serif;
        margin-top: 15px;
        margin-bottom: 20px;
      }

      .ant-modal-confirm-content {
        color: var(--COLOR-000533);
        font-size: 14px;
      }

      .ant-modal-btns {
        margin-top: 10px;
        margin-bottom: 5px;
        display: flex;
        justify-items: center;
        justify-content: center;
        gap: 5px;
      }

      .ant-btn {
        color: var(--COLOR-FFFFFF) !important;
        height: auto;
        font-size: 14px;
      }

      .ant-modal-confirm-btns {
        display: none;
      }
    }

    .ant-modal-footer {
      display: flex;
    }
  }
}

.ant-tag {
  border-color: var(--flex-grey1003);
  background: var(--flex-grey1002);
  color: var(--COLOR-000533);

  &.tag-error {
    background: var(--red-main);
    border-color: var(--red-main);
    color: #ffffff;
  }

  &.tag-warning {
    background: var(--orange-dark);
    border-color: var(--orange-dark);
    color: #ffffff;
  }
}

body {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--COLOR-000533);

    &.ant-typography {
      color: var(--COLOR-000533);
    }
  }

  h1 {
    font-style: normal !important;
    font-weight: lighter !important;
    font-size: 24px !important;
    line-height: 30px !important;
    margin: 0;
  }

  h2 {
    font-style: normal !important;
    font-weight: lighter !important;
    font-size: 20px !important;
    line-height: 27px !important;
    margin: 0;
  }

  h3 {
    font-style: normal;
    font-weight: lighter;
    font-size: 16px;
    line-height: 23px;
    margin: 0 0 10px;
  }
}

.ant-timeline-item-tail {
  border-color: var(--COLOR-C4C4C4);
}

.ant-select {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.ant-select-single {
  .ant-select-selection-item {
    padding-right: 0 !important;
    margin-right: 14px;
  }
}

.ant-select-status-error {
}

.ant-select-multiple {
  .ant-select-selection-item-wrapper {
    display: flex;

    .drop-element {
      display: flex;
      align-items: center;
      padding: 0 10px;
      border: 1px dashed var(--flex-grey1003);
      margin: 2px 3px;
      font-size: 12px;
      text-transform: uppercase;
    }

    .deleted {
      background: var(--red-light);
      border-color: var(--red-main);
      color: var(--red-main);
      svg {
        color: #ff003d;
      }
    }
  }

  .ant-select-selection-item {
    &.draggable {
      cursor: move;
    }

    .drop-element {
    }

    .ant-select-selection-item-content {
      max-width: 100px;
      min-width: 30px;

      @include ellipsis;

      font-size: 12px;
      display: inline-block !important;
    }

    .ant-select-selection-item-remove {
      color: unset;
      opacity: 0.5;
      transition: opacity 0.2s;
      display: flex;
      align-items: center;

      &:hover {
        color: unset;
        opacity: 1;
      }
    }
  }
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
  color: #c0ccf2;

  span {
    font-size: 12px;
    color: var(--COLOR-000533);
  }
}

.ant-checkbox-group {
  &.ant-checkbox-disabled + span {
    color: var(--COLOR-000533);
    opacity: 0.5;
  }

  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    opacity: 0.8;
    margin-right: 2px;

    &:hover {
      opacity: 1;
      transition: opacity 0.2s;
    }

    span {
      font-size: 12px;
    }
  }
}

.create-contact-link,
.select-btn {
  color: rgba(70, 94, 251, 1);
  font-size: 9px;
  font-weight: 700;
  border: 1px solid rgba(70, 94, 251, 1);
  border-radius: 4px;
  outline: none;
  background: transparent;
  margin-left: 8px;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;
  padding: 0 5px;
  display: block;
}

.select-btn {
  line-height: 16px;
  height: 18px;
}

.create-contact-link {
  padding: 3px 10px;
  display: block;
  margin: 5px 0 5px 10px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  width: 180px;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.tag-list {
  .ant-tag {
    margin: 0 8px 5px 0;
  }
}

.assign-wrapper {
  display: flex;

  .assign-btn,
  .unassign-btn {
    color: rgba(70, 94, 251, 1);
    font-size: 9px;
    font-weight: 700;
    border: 1px solid rgba(70, 94, 251, 1);
    border-radius: 4px;
    outline: none;
    background: transparent;
    margin-left: 5px;
    cursor: pointer;
    line-height: 14px;
    text-transform: capitalize;
    position: relative;
    padding: 0 5px;
    display: block;
    white-space: nowrap;
  }
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .ant-select-item {
    min-width: 180px !important;
    background: transparent;
    color: var(--COLOR-000533);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--flex-grey1751);
  }

  .select-options {
    width: 100%;
    overflow: hidden;

    > div {
      position: relative !important;
      top: unset;
      left: unset;
      overflow-y: auto;
      overflow-x: hidden;

      .ant-select-dropdown {
        position: relative !important;
        left: unset !important;
        top: unset !important;
        width: unset !important;
        background: transparent;

        .rc-virtual-list-holder {
          max-height: 180px !important;

          .ant-select-item-option-content {
            @include ellipsis;

            flex: 1;
            display: flex;
            align-items: center;
          }

          .ant-select-item-option-state {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.ant-select-dropdown,
.ant-select-tree,
.ant-table-filter-dropdown {
  background: var(--BG-FFFFFF);
  color: var(--COLOR-000533);

  .ant-select-item {
    color: var(--COLOR-000533);
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected,
  .ant-select-tree-list-holder .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected,
  .ant-select-tree-list-holder .ant-select-tree-node-content-wrapper:hover {
    background-color: var(--flex-grey1001);
  }
}

.ant-select-dropdown {
  .rc-virtual-list-holder-inner {
    .ant-select-item {
      &.ant-select-item-deleted {
        display: none;
      }
    }
  }
}

.ant-select-tree {
  .ant-select-tree-treenode {
    &.ant-select-tree-treenode-deleted {
      display: none;
    }
  }
}

.ant-empty-small {
  color: var(--COLOR-000533);
}

.ant-tree-select-dropdown .ant-select-tree-list .ant-select-tree-list-holder {
  padding-right: 10px;
}

.ant-select-item-option-content,
.ant-select-selection-item,
.ant-select-selection-item-content {
  figure {
    flex-shrink: 0;
    margin-right: 4px;
  }

  .option-title {
    overflow: hidden;
    flex-grow: 1;
    height: 18px;
    position: relative;
    min-width: 70px;

    > span {
      line-height: 18px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      @include ellipsis;
    }
  }
}

.ant-select {
  .ant-select-selector {
    .ant-select-selection-item {
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
      min-width: 10px;
      padding-right: 3px;
      left: 7px;
      margin-inline-start: 0;
      -webkit-margin-start: 0;
    }
  }
}

.ant-empty {
  margin: 8px;
  color: var(--COLOR-000533);
  opacity: 0.5;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
}

.phone-number {
  display: flex;

  .flag,
  .phone {
    display: flex;
    justify-content: center;
    /* color: var(--COLOR-000533); */ // removed due to reverted color on conversation.contact number tooltip
  }

  .flag {
    margin-right: 3px;
  }
}

.table-wrp {
  width: 100%;
  height: auto;
  margin-top: 16px;
}

.display-phone {
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;

  > * {
    margin-right: 5px;
  }
}

.ant-slider {
  .ant-slider-handle {
    border-color: var(--blue-main) !important;
  }
  .ant-slider-track {
    background: var(--blue-main) !important;
  }
}

.status-info {
  display: flex;
  gap: 5px;
  align-items: center;
  .info {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    gap: 5px;
  }
  .badge {
    background: var(--grey-main);
    color: white;
    width: 16px;
    height: 16px;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 7px;
    padding: 1px 2px;
  }
}

.react-grid-layout {
  .react-grid-item {
    .draggable-handle {
      cursor: move !important;
    }
  }
}

.status-color {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}

.status-color-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  .status {
    padding-top: 1px;
  }
}

.ant-input-number-input {
  padding-right: 15px;
}

.is-print {
  height: auto;
  .content-master-wrp {
    height: auto;
  }
}

.currency-display {
  display: flex;
  gap: 5px;

  .currency {
    opacity: 0.7;
  }

  .currency-prefix {
    display: none;
  }

  &.currency-display-eur {
    gap: 0;
  }

  &.currency-display-usd {
    gap: 0;
    .currency-postfix {
      display: none;
    }
    .currency-prefix {
      display: inline-block;
    }
  }
}

body {
  & > iframe {
    display: none;
  }
}

#doc-preview-no-render {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  div {
    display: inline-block;
    padding: 10px 25px;
    background-color: var(--red-main);
    color: white;
    font-weight: bold;
  }
}

#document-viewer-body {
  #react-doc-viewer {
    #pdf-renderer {
      #pdf-controls {
        #pdf-download {
          display: none;
        }
        #pdf-zoom-out,
        #pdf-zoom-in,
        #pdf-zoom-reset,
        #pdf-toggle-pagination {
          width: 22px;
          height: 22px;
          margin-left: 2px;
        }
        #pdf-zoom-reset,
        #pdf-toggle-pagination {
          svg {
            width: 60%;
            height: 60%;
            margin-top: 2px;
          }
        }
        #pdf-pagination {
          #pdf-pagination-info {
            font-size: 12px;
          }
          button {
            width: 16px;
            height: 16px;
            box-shadow: none;
          }
          #pdf-pagination-next {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.table-cell_changed_props .wrapper {
  display: flex;
  gap: 3px;
}
.tag-audit-property {
  font-size: 12px;
  background: var(--flex-grey1001);
  padding: 1px 5px;
  border-radius: 12px;
}

.page-error {
  padding: 50px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .error {
    background: var(--red-light);
    color: var(--red-main);
    border: 1px dashed var(--red-main);
    font-weight: bold;
    padding: 20px 40px;
  }
}

.load-more {
  font-size: 9px;
  color: var(--blue-main);
  border-top: 1px solid var(--blue-light);
  display: inline-block;
  padding: 2px 3px 0;
  font-weight: bold;
  opacity: 0.5;
  flex-grow: 0;
  text-align: center;
  transition: opacity 0.5s;
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    opacity: 1;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel {
    .ant-picker-header {
      button {
        background: transparent !important;
        color: #000533 !important;
        span {
          border-color: #000533 !important;
          &::before {
            border-color: #000533 !important;
          }
          &::after {
            border-color: #000533 !important;
          }
        }
      }
    }
  }
}
[id^="points_system_edit_"] {
  .ant-form-element-organization_id,
  .ant-form-element-mail_message_sent,
  .ant-form-element-inbound_voice_call,
  .ant-form-element-outbound_voice_call,
  .ant-form-element-internal_voice_call,
  .ant-form-element-outbound_conversation_created,
  .ant-form-element-outbound_conversation_resolved,
  .ant-form-element-inbound_conversation_resolved,
  .ant-form-element-outbound_conversation_closed,
  .ant-form-element-inbound_conversation_closed {
    > .ant-form-item-row > .ant-form-item-label {
      label .text {
        width: 200px !important;
        span {
        }
      }
      .required {
        display: none;
      }
    }
  }

  /*
  .ant-form-section .ant-form-section .ant-form-item {
    width: 18%;
  }

  .ant-form-element-points {
    width: 10%;
  }

   */
}

.conversation-filter-sort-dropdown-list {
  min-width: 200px !important;
}

.disposition-note {
  white-space: initial !important;
}

.ant-pagination-disabled {
  opacity: 0.5;
  svg {
    opacity: 0.5;
  }
}

.conversation-tags-squeezed {
  display: flex;
  .conversation-tags-badge {
    margin-left: -7px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.conversation-tags {
  cursor: pointer;

  svg {
    color: var(--blue-main);
  }

  &:hover {
    z-index: 1;
    svg {
      color: initial;
    }
  }
}

.conversation-tags-badge {
  .ant-badge-count {
    background: transparent !important;
    color: var(--blue-main);
    border: 0 !important;
    box-shadow: none !important;
    font-size: 8px;
    padding: 0;
    min-width: 5px;
    right: 3px;
    display: none;
  }
}

.ai-responding {
  position: absolute;
  min-width: 170px;
  display: flex;
  justify-content: center;
  left: 50%;
  margin-left: -85px;
  background: var(--flex-white01);
  z-index: 1;
  text-transform: uppercase;
  opacity: 0;
  margin-top: -100px;
  transition: all 1s;
  gap: 10px;
  background-color: var(--blue-main);
  height: 20px;

  &.active {
    margin-top: 5px;
    opacity: 1;
  }

  .loader {
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    --c: no-repeat repeating-linear-gradient(90deg, #fff 0 calc(100% / 7), #0000 0 calc(200% / 7));
    background: var(--c), var(--c), var(--c), var(--c);
    background-size: 140% 26%;
    animation: l27 0.75s infinite linear;
  }

  .text {
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
  }
}

@keyframes l27 {
  0%,
  20% {
    background-position: 0 calc(0 * 100% / 3), 100% calc(1 * 100% / 3), 0 calc(2 * 100% / 3),
      100% calc(3 * 100% / 3);
  }
  80%,
  100% {
    background-position: 100% calc(0 * 100% / 3), 0 calc(1 * 100% / 3), 100% calc(2 * 100% / 3),
      0 calc(3 * 100% / 3);
  }
}

.ant-drawer-header,
.ant-drawer-body {
  background-color: var(--BG-FFFFFF);
}