.stats-wrp {
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  height: 100%;
  background-color: var(--COLOR-F3F3F5);
  border-left: 1px solid var(--COLOR-E8ECEE);
  overflow: auto;
  position: relative;

  &.hidden,
  &.hidden-by-choice {
    display: none;
  }

  h2 {
    font-size: 16px;
    font-weight: lighter;
    padding: 8px 10px 0;
    display: none;
  }

  .report-selection {
    display: flex;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 5px;
    align-items: center;

    .title {
      margin-right: 10px;
      opacity: .5;
      transition: opacity .25s;
      text-transform: uppercase;
    }

    .select-wrapper {
      flex-grow: 1;

      .ant-select {
        .ant-select-selector {
          border-color: transparent !important;
          padding: 0 0 0 7px;

          .ant-select-selection-item {
            font-weight: bold;
          }
        }
      }
    }

    &:hover {
      .icon-wrapper {
        opacity: 1;
      }
    }

    >.icon-wrapper {
      padding: 3px 1px 3px 3px;
      opacity: .7;
      transition: opacity .25s;
    }
  }

  .report-selection-depricated {
    border-bottom: 1px solid var(--flex-grey1501);
    padding-bottom: 10px;
  }

  .widget-add {
    border-top: 1px solid var(--flex-grey1501);
    padding: 10px 10px 20px;

    >.icon-wrapper {
      background: var(--blue-main);
      margin-left: 10px;
      width: 32px;
      height: 32px;
      justify-content: center;

      svg {
        color: white;
      }
    }
  }

  .report-not-selected,
  .no-preview-widgets {
    height: 150px;
    display: flex;
    border: 1px dashed var(--red-main);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    text-transform: uppercase;
    opacity: .4;
    font-weight: bold;
    font-size: 16px;
    transition: opacity .25s;

    &:hover {
      opacity: 1;
    }
  }

  .report-widget-default-selection {
    display: flex;

    .ant-select {
      border: 0 !important;
      outline: none;

      .ant-select-selector {
        border-color: transparent !important;
        padding: 0 !important;
        box-shadow: none !important;

        .ant-select-selection-item {
          cursor: pointer;
          margin-right: 0;
          font-size: 10px;
          background: #808299;
          color: #FFFFFF;
          padding: 0 8px !important;
          line-height: 22px !important;
          height: 21px;
          margin-top: 5px;
          border-radius: 4px;
        }
      }

      .ant-select-arrow {
        display: none;
      }
    }

    >div {
      width: 50%;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 3px;

      &.selected {
        background: var(--flex-grey1501);
      }
    }
  }

  >.s-d {
    width: 100%;
  }
}

@media screen and (max-width: 1698px) {
  .stats-wrp {
    display: none;
  }
}