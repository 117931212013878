.form-phone-element {
  display: flex;
  .country-code {
    width: 40%;
    max-width: 160px;
    background: var(--flex-grey1001);
    .ant-select-selector {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0 !important;
    }
  }
  .phone-number {
    width: 60%;
    max-width: 180px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
