@import "../../../../styles/partials/mixins";

.inbox-left-list {
  &:hover {
    .inbox-row .inbox-row-col-1 .ant-checkbox-wrapper .ant-checkbox {
      opacity: .6;
    }
  }
}
.inbox-row {
  padding: 15px 10px 15px 15px;
  border: 1px solid transparent;
  border-bottom: 1px dotted rgba(150, 150, 150, 0.3);

  @include clickable();

  display: flex;
  flex-direction: row;
  position: relative;
  color: var(--COLOR-000533);

  > div > .row {
    .col:last-child {
      justify-content: right;
      flex-shrink: 0;
    }
  }

  .additional-marker {
    display: none;
  }

  &.from-additional {
    opacity: .7;
    .additional-marker {
      width: 4px;
      height: 50%;
      top: 25%;
      background: var(--color);
      position: absolute;
      left: -1px;
      display: block;
      opacity: .75;
      z-index: 9;
      border-radius: 2px;
    }
  }

  .conversation-update-text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    padding: 2px 5px;
    background: var(--blue-main);
    color: #FFFFFF;
    margin-top: -7px;
    margin-left: 3px;
    border-radius: 3px;
  }

  &.updated {
    background: rgba(235, 241, 140, 0.2) !important;
  }

  &.closed {
    opacity: 0.7;
  }

  &.selected {
    background: rgba(150, 150, 150, 0.05);
    border-bottom: 1px dotted rgba(150, 150, 150, 0.7);
  }

  .col {
    overflow: hidden;
  }

  .icon-text-wrapper {
    width: 100%;
    max-width: 220px;
    overflow: hidden;
  }

  &.full-width {
    > div > .row {
      padding-right: 15px;
    }

    .assign-wrapper {
      .icon-text-wrapper {
        width: auto;
      }
    }

    .id-wrapper, .disposition-wrapper, .contact_email_phone {
      width: 100%;
    }

    .id-wrapper small {
      @include ellipsis;
    }

    .inbox-row-col-3 {
      > .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .col {
          position: relative;
          height: 19px;

          .title {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 5px;

            @include ellipsis;
          }
        }

        > .col-1 {
          flex: 1;
        }

        > .col-2 {
          flex: 2;
        }

        > .col-3 {
          flex: 3;
        }
      }
    }
  }

  &.shortened {

    .inbox-row-col-1 .ant-checkbox-wrapper .ant-checkbox {
      opacity: 0;
    }

    .inbox-row-col-2 {
      margin-left: 3px;
    }

    padding-bottom: 10px;
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;

      .disposition-wrapper .ant-typography {
        max-width: 130px;
      }
    }

    .row-1 {
      display: flex;
      flex-direction: row;

      > .col-1 {
        flex-shrink: 0;
        margin-left: auto;
        text-align: right;
        padding-right: 0 !important;
        padding-left: 5px;
      }
    }

    .row-3 {
      display: flex;
      flex-direction: row;
      justify-content: right;

      > .col {
        height: 24px;
        &:first-child {
          margin-right: auto;
        }

        &:last-child {
          padding-right: 0 !important;
          padding-left: 5px;
        }
      }

      .disposition-wrapper {
        .icon-wrapper, .icon-text-props {
          height: 16px;
          line-height: 16px;
        }
      }
    }
  }

  &:hover {
    .inbox-row-col-1 .ant-checkbox-wrapper .ant-checkbox {
      opacity: .75 !important;
    }
  }

  .inbox-row-col-1 {
    position: absolute;
    padding-top: 3px;
    z-index: 1;
    margin-right: 0;
    width: 46px;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-checkbox-wrapper {
      padding: 15px 12px;
      height: 100%;
      display: flex;
      justify-content: center;
      cursor: context-menu;
      &:hover {
        .ant-checkbox {
          opacity: 1 !important;
        }
      }
      .ant-checkbox {
        background-color: var(--BG-FFFFFF);
        opacity: 1;
        transition: opacity .5s;
      }
      &.ant-checkbox-wrapper-checked {
        .ant-checkbox {
          opacity: 1 !important;
        }
      }
    }


    >.cb {
      .ant-checkbox-inner {
        border-color: var(--COLOR-C0CCF2);
      }
    }
  }

  .inbox-row-col-2 {
    margin-right: 10px;
    display: flex;
    align-items: flex-start;
    margin-left: 38px;
    margin-top: 7px;
    justify-content: center;

    .timeline {
      align-items: center;
      justify-content: center;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .ant-timeline-item-last {
      padding: 0;
      margin-top: 1px;
    }
  }

  .inbox-row-col-3 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: hidden;

    > .row {
      > .col {
        display: flex;
        align-items: center;
        padding-right: 5px;
      }
      > .col-1 {
        flex-shrink: 0 !important;
      }
    }

    .icon-text-props {
      font-size: 10px;
      color: var(--COLOR-000533);
    }

    .id {
      color: var(--COLOR-000533);
      font-size: 12px;
      opacity: 0.4;
    }

    .queue {
      max-width: 125px;
    }

    .assign-wrapper {
      width: 100%;
    }

    .time {
      line-height: 16px;
    }

    .icon-style {
      opacity: 0.5;
    }

    .icon-props {
      size: 10px;
      color: var(--COLOR-808299);
    }

    .status-flag-container {
      align-items: center;
      line-height: 12px;

      .status-container {
        color: var(--COLOR-516395);
        font-size: 10px;
        margin-right: 5px;
      }

      .flag-container {
        font-size: 10px;
      }
    }

    .title {
      font-size: 12px;
      font-weight: bold;
      color: var(--COLOR-000533);

      &.title-missing {
        opacity: 0.5;

        &::before {
          content: "[";
        }

        &::after {
          content: "]";
        }
      }
    }

    .watch-container {
      .watch {
        justify-content: center;
        align-items: center;
        background-color: var(--flex-grey1751);
        border-radius: 3px;
        padding: 5px;

        figure {
          width: 15px;
          height: 15px;
          margin-left: 0;
          margin-right: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.circle {
            background-color: var(--COLOR-000533);
            color: var(--COLOR-FDFEFE);
            line-height: 12px;
          }
        }
      }
    }
  }
}
