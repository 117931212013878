@import "../../styles/partials/mixins";

#stats-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  #stats-report-header {
    background: var(--flex-grey1501);
    padding: 15px 20px;
    flex-grow: 0;
  }

  #stats-reports {

    display: flex;
    flex: 1 1;
    height: 100%;
    overflow: hidden;
    background-color: var(--flex-grey150025);

    #reports, #dashboards {
      width: 50%;
      display: flex;
      flex-direction: column;

      .header {
        padding: 15px 30px 5px;
        display: flex;
        .title {
          font-size: 16px;
          svg {
          }
        }
        button {
          margin-left: auto;
          border-color: var(--blue-main) !important;
          color: var(--blue-main) !important;
          border-radius: 5px;
          transition: background-color .25s;
          padding: 5px 15px;
          &:hover {
            background: var(--blue-main) !important;
            color: white !important;
            span {
              color: white !important;
            }
          }
          svg {
            margin-right: 5px;
          }
          span {
            color: var(--blue-main) !important;
          }
        }
      }
      .add-first-report,
      .add-pre-built-report,
      .no-shared-reports,
      .no-first-report {
        border-bottom: 1px solid var(--flex-grey1501);
        padding: 15px 15px 30px;
        margin: 0 15px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .display-icon svg {
          width: 39px;
          height: 52px;
        }
        h1 {
          line-height: 36px;
          margin-top: 10px;
        }
        h2 {
          opacity: .5;
          font-size: 18px;
        }
      }
      .filters {
        display: flex;
        padding: 8px 30px;
        gap: 10px;
        .filter {
          font-size: 10px;
          font-weight: bold;
          padding: 5px 10px;
          border-radius: 24px;
          border: 1px solid var(--flex-grey1005);
          cursor: pointer;
          transition: background-color .25s;
          &.active,
          &:hover {
            background: var(--flex-grey1008);
            color: white;
          }
        }
      }
      .scroll-view {
        padding: 5px 20px 15px;
        .prebuilt-reports {
          flex-wrap: wrap;
          display: flex;
          gap: 10px;
          .template {
            flex: 1 1 220px;
            background: var(--BG-FFFFFF);
            border-radius: 5px;
            overflow: hidden;
            border: 1px solid var(--flex-grey1001);
            cursor: pointer;
            position: relative;
            .img,
            .default-img,
            .background {
              width: 100%;
              height: 120px;
              background-size: 100% auto;
              background-position: center;
            }
            .default-img {
              position: absolute;
              z-index: 0;
            }
            .background {
              position: absolute;
              z-index: 2;
              background: black;
              top: 0;
              transition: all .25s;
              opacity: 0;
            }
            .title {
              padding: 9px 15px;
              font-size: 14px;
            }
            .description {
              position: absolute;
              top: 90px;
              opacity: 0;
              text-align: center;
              left: 0;
              right: 0;
              color: white;
              text-transform: uppercase;
              transition: all .25s;
              z-index: 3;
            }
            .display-icon {
              position: absolute;
              top: 50px;
              left: 50%;
              margin-left: -20px;
              z-index: 3;
              transition: all .25s;
              svg path {
                fill: white !important;
              }
            }
            &:hover {
              .description {
                top: 70px;
                opacity: 1;
              }
              .display-icon {
                top: 35px;
              }
              .background {
                opacity: .5;
              }
            }
          }
        }
        .reports {
          flex-wrap: wrap;
          display: flex;
          gap: 10px;

          .report {
            flex: 1 1 320px;
            background: var(--BG-FFFFFF);
            overflow: hidden;
            padding: 20px 24px;
            border-radius: 8px;
            border: 1px solid var(--flex-grey1001);
            cursor: pointer;
            position: relative;

            .report-buttons {
              position: absolute;
              top: 6px;
              right: 6px;
              z-index: 1;
              opacity: 0;
              transition: opacity .25s;
              padding: 10px;
              display: flex;
              gap: 5px;
            }

            &:hover {
              .report-buttons {
                opacity: 1;
              }
            }

            .display-icon {
              margin-bottom: 15px;
            }
            .title {
              font-size: 14px;
              max-width: 260px;
              @include ellipsis;
            }
            .organization {
              font-size: 12px;
              opacity: .7;
            }
            .shared {
              background: var(--blue-light);
              padding: 5px 16px;
              border-radius: 24px;
              font-size: 10px;
              font-weight: bold;
              color: #000533;
            }

            .info {
              display: flex;
              width: 100%;
              .left {
                flex-grow: 1;
              }
              .right {
                margin-left: auto;
                flex-grow: 0;
              }
            }
          }
        }
      }
    }

    #reports {

    }

    #dashboards {
      border-right: 1px solid var(--flex-grey1501);
      .display-icon {
        svg path {
          fill: var(--blue-main)
        }
      }
    }
  }

}
