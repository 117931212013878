.tabs-wrapper {
  width: auto;
  height: auto;
  left: 0;
  padding-left: 10px;
  padding-top: 10px;
  bottom: 0;
  position: fixed;
  z-index: 90;
  pointer-events: none;
  display: flex;
  align-items: end;
  right: 0;

  #inactive-tabs {
    background: red;
    min-height: 250px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: white;
    width: 250px;
    pointer-events: initial;
    animation: pulse 5s infinite;
    flex-shrink: 0;
    h1 {
      color: white;
      font-size: 80px !important;
      line-height: unset !important;
    }
    .line-2 {
      font-size: 12px;
      white-space: initial;
      margin: 15px 0 10px;
      text-align: center;
    }
  }

  .tabs {
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    overflow: hidden;
    padding-top: 7px;
    scrollbar-color: dark var(--BG-FFFFFF);

    &::-webkit-scrollbar-track {
      background: var(--BG-FFFFFF);
    }

    .drop-element {
      width: 120px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--COLOR-000533) !important;
      color: var(--COLOR-FFFFFF) !important;
    }
  }
}
