.create-conversation-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 5px;

  .create-conversation {
    width: 43px;
    height: 43px;
    border-radius: 5px;
    background: var(--blue-main);
    display: flex;
    align-items: center;
    color: #FFFFFF;
    justify-content: center;
    cursor: pointer;

    .icon-wrapper {
      svg {
        color: #FFFFFF;
      }
    }
  }
}
