@import "../../../styles/partials/mixins";

.tabs-wrapper {
  .tab-wrapper,
  .drop-element {
    pointer-events: initial;
    width: 320px;
    min-height: 40px;
    @include tabStyle;
    display: flex;
    flex-direction: column;

    &.focused {
      .tab-header {
        opacity: 1;
      }
    }

    &.tab-container {
      display: flex;
      flex-direction: column;
      z-index: 100;
      width: 320px;
      min-height: 32px;
      position: relative;

      .tab-container-inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: white;
        border-radius: 5px 5px 0 0;
        position: relative;

        .tab-mini-view {
          display: flex;
          flex-direction: column;
          background-color: var(--BG-FFFFFF);
          position: relative;

          &.has-voice-controllers {
            > .body {
              margin-bottom: 77px;
              height: 223px;
            }
          }

          >.body {
            height: 300px;
            overflow-y: auto;

            >.list-view{

            }
          }
        }

      }

    }

    >.tab-content-wrp {
      width: inherit;
      height: inherit;
      position: relative;
      padding: 0 10px;
      display: flex;
      flex-direction: column;

      .full-size-contents {
        position: fixed;
        width: 0;
        height: 0;
        bottom: 0;
        z-index: 100;
        background-color: #FFFFFF;
        box-shadow: 0 10px 20px 0 rgba(0, 5, 51, 0.1);
        border: 2px solid var(--full-size-content-border-color);
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .tab-header {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.6;

      .tab-header-icons-wrp {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon-wrp {
          cursor: pointer;
          color: var(--tab-header-icon-color);

          svg {
            margin-right: 0 !important;
            margin-left: 4px;
          }
        }

        .channel-icon-wrp {
          overflow: hidden;
          position: relative;
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          transform: rotate(80deg);
          transition: all 0.2s linear 0.45s;

          .channel-icon {
            position: absolute;
            z-index: 10;
            top: 3px;
            right: 3px;
            transform: rotate(-80deg);
            transition: all 0.2s linear 0.45s;
          }

          &::before {
            position: absolute;
            width: inherit;
            height: inherit;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px #39C660;
            content: "";
          }

          .cover {
            position: absolute;
            margin: 0 -50%;
            width: 200%;
            height: inherit;
            transform-origin: 50% 0;
            background: var(--tab-wrp-back-color);
            transform: rotate(-124deg);
            transform: rotate(-124deg);
            transform: rotate(-124deg);
            transform: rotate(-124deg);
          }
        }

        .tab-header-title {
          color: var(--color);
          font-weight: 600;
          font-size: 12px;
          padding-left: 6px;
          height: 100%;
          justify-content: flex-start;
          align-items: center;

          @include ellipsis;

          display: flex;
          max-width: 150px;
        }

        .tab-duration {
          font-weight: 400;
          font-size: 10px;
          color: var(--tab-duration-color);
          margin-left: 8px;
        }
      }
    }

    .tab-body {
      width: 100%;
      height: auto;
    }
  }
}


