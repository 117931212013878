@import '../../../../../styles/partials/_mixins';

@include pulse;

.conversation-message-voice-call {

  &.mini {
    .conversation-message-content {
      .voice-controller-info {
        width: calc(100% - 6px);
        margin: 0 auto 5px;
      }

      .head {
        margin-bottom: 5px;
        font-size: 10px;
      }

      .voice-ongoing-container {
        margin-top: 3px;
      }

      .call-data {
        margin-bottom: 0;
        padding-left: 3px;
        .call-flow {
          padding: 5px 0;
          .bridge-callee {
            > div {
              font-size: 9px;
              &:first-child {
                font-size: 11px;
                height: 20px;
              }
              &:last-child {
                margin-top: 0;
              }
            }
          }
          .call-arrow {
            margin: -1px 3px 0;
          }
        }
      }

      .bridge-recordings .audio-player-wrapper .audio-player {
        margin-left: 10px;
      }

      .bridge-ongoing,
      .bridge-abandoned {
        width: 2px;
        left: 0;
      }

      .listened-in {
        padding: 9px 5px 4px;
        display: flex;
        border-top: 1px solid var(--flex-grey1001);
        margin-top: 5px;
        font-size: 10px;
        .title {
          font-weight: lighter;
          font-size: 10px;
          opacity: .5;
          padding-right: 5px;
        }
      }
    }

  }

  .conversation-message-content {
    background: rgba(0, 0, 0, 0.1);
    position: relative;


    .call-ongoing {
      width: 8px;
      height: 8px;
      background: var(--green-main);
      animation: pulse 2s infinite;
      border-radius: 50%;
      margin: 0 3px;
    }

    .bridge-ongoing,
    .bridge-abandoned {
      position: absolute;
      left: 3px;
      width: 3px;
      top: 3px;
      bottom: 3px;
      border-radius: 3px;
      box-sizing: initial;
    }

    .bridge-abandoned {
      background: var(--red-main);
    }

    .bridge-ongoing {
      background: var(--green-main);
      animation: pulse 2s infinite;
    }

    .head {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .missed-call,
      .ongoing-call {
        padding-left: 5px;
        font-weight: bold;
      }
      .missed-call {
        color: var(--red-main);
      }
      .ongoing-call {
        color: var(--green-main);
      }
      .call-length,
      .pre-queue-time {
        margin: 0 5px;
      }
      .pre-queue-time {
        opacity: .5;
        &:hover {
          opacity: 1;
          transition: opacity .25s;
        }
      }
      .language {
        display: flex;
        align-content: center;
        align-items: center;
        gap: 7px;

        .select-wrapper {
          .ant-select-selector {
            height: 17px;
            padding: 0 5px;
            &:after {
              line-height: 16px;
            }
          }
          .ant-select-arrow {
            right: 5px;
          }
          .ant-select-selection-item {
            font-size: 11px;
            line-height: 16px;
          }
        }
      }
      .icon-wrapper {
        margin-right: 2px;
        opacity: .5;
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .toggle-timeline {
        margin-left: 5px;
        transition: opacity .25s;
        &.active {
          opacity: 1;
        }
        &:hover {
          opacity: .75;
        }
      }
    }

    .voice-distributing-container {
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      padding: 10px;
      background: var(--flex-grey15005);
      border-radius: 3px;
      margin: 3px;
    }


    .call-data {
      margin-bottom: 10px;
      .call-flow {
        padding: 10px 0;
        display: flex;
        align-items: start;
        white-space: nowrap;
        flex-grow: 1;
        .call-caller {
          padding-top: 9px;
        }
        .call-caller,
        .call-callee,
        .bridge-callee {
          .phone-number {
            display: flex;
            align-items: center;
            margin-right: 0;
          }

          > div {
            font-size: 11px;
            opacity: .5;

            .text,
            .phone {
              @include ellipsis();
              max-width: 160px;
            }

            &:first-child {
              font-size: 16px;
              line-height: 24px;
              padding-bottom: 2px;
              opacity: 1;
              height: 24px;
              display: flex;
              align-items: center;
            }
            &:last-child {
              margin-top: 1px;
            }
          }
          &.call-callee {
            > div {
              &:first-child {
                color: var(--blue-grey);
              }
            }
          }
        }
        .call-arrow {
          margin: 2px 10px;
        }
        .call-bridges {
          flex-grow: 1;
          margin-left: 10px;
          .call-bridge {
            position: relative;
            display: flex;
            padding-top: 7px;
            margin-top: 3px;
            padding-bottom: 7px;
            padding-right: 5px;
            background: var(--flex-grey15005);
            border-radius: 5px;
            .bridge-info {
              align-items: center;
            }
            /*
            .bridge-info {
              & > div {
                margin-top: 9px;
                .title {
                  display: none;
                }
              }
            }
             */
          }
        }
      }
      .call-transcript {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-items: center;
        align-content: center;
        justify-content: center;
      }
      .bridge-right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .bridge-recordings {
        padding-right: 3px;
        align-items: center;
        & > div:first-child .audio-player-wrapper {
          margin-top: 0;
        }
      }
      .call-info,
      .bridge-info {
        display: flex;
        > div {
          padding: 0 8px;
          display: flex;
          flex-direction: column;
          font-size: 12px;

          &:last-child {
            text-align: right;
          }
          .title {
            opacity: .5;
            font-weight: lighter;
            font-size: 10px;
          }
          .value {
            white-space: nowrap;
            display: flex;
          }
        }
      }
    }
  }

  &.outbound {
    .conversation-message-content .call-bridges {
      .call-bridge {
        background: var(--flex-white01) !important;
      }
    }
  }

}

.master-wrp.dark {
  .conversation-message-voice-call {
    .conversation-message-content {
      .call-data {
        .call-flow {
          .call-callee {
            > div:first-child {
              color: var(--blue-light);
            }
          }
        }
      }
    }
  }

}
