.table-import {
  display: none;
}

.reply-wrapper {
  display: flex;
  flex-direction: column;

  .editor-toggle {
    svg {
      color: #333333 !important;
    }
  }

  .ant-select {
    &.bold {
      font-weight: 700;
    }

    .ant-select-selector {
      background: var(--flex-grey1001);
      min-width: 120px;
      border: 0;
      min-height: 30px;
      color: var(--color);
      font-size: 12px;
      box-shadow: none;
      outline: none;
      border: 0;

      &:hover {
        box-shadow: none;
        outline: none;
        border: 0;
      }
    }
  }

  .subject-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    > .text {
      color: var(--COLOR-000533);
      opacity: 0.5;
      margin-right: 6px;
      font-size: 12px;
    }

    >.subject-input {
      color: var(--COLOR-000533);
      border: 1px solid var(--flex-grey1002);
      padding: 3px 8px;
      border-radius: 3px;
      flex: 1;
      background: transparent;
    }
  }

  .reply-contents {
    position: relative;
    display: flex;
    flex-direction: column;

    .ql-editor {
      min-height: 200px;
    }

    .reply-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      .h-left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        clear: both;

        label {
          margin: 0 12px 0 0;
          color: var(--color);
          font-size: 10px;
          flex: 0 0 auto;
          padding: 10px 0;
          line-height: 12px;
        }

        .section {
          margin-right: 12px;
          display: flex;
          align-items: center;
        }
      }

      .h-right {
        display: flex;
        align-items: center;
        height: 31px;
      }
    }

    .reply-footer {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 6px;
      padding: 0 8px;

      label {
        word-break: keep-all;
      }

      .signature {
        display: flex;
        align-items: center;
      }

      .signature {
        margin-right: 10px;
      }

      .update-since {
        display: flex;
        justify-content: center;
        align-content: flex-end;
        margin-left: auto;
        padding-right: 15px;
        word-break: keep-all;
        white-space: nowrap;

        label,
        div {
          font-size: 10px;
          color: var(--color);
        }

        label {
          opacity: 0.5;
          margin-right: 8px;
        }
      }

      .ant-select-selector {
        min-width: 180px !important;
      }

      label {
        margin: 0;
        padding: 0;
        color: var(--color);
        font-size: 10px;
        margin-right: 12px;
      }

      button {
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        height: 36px;
        padding: 0 12px;
        border-radius: 4px;
        box-shadow: none;
        outline: none;
        border: 0;
        cursor: pointer;

        &:hover {
          box-shadow: none;
          outline: none;
          border: 0;
        }
      }

      button.draft {
        background: transparent;
        color: var(--gray-color);
        margin-right: 6px;
      }
    }
  }

  .preview-signature {
    border-top: 1px solid var(--flex-grey1006);
    margin-top: 20px;
    padding: 15px;
    background: var(--flex-grey1501);
    position: relative;

    label {
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      top: -15px;
      left: 0;
      opacity: .7;
      cursor: help;
    }
  }

  .reply-history {
    padding-top: 10px;
    position: relative;
  }

}

@media screen and (max-width: 900px) {
  .reply-contents .reply-footer {
    label {
      display: none;
    }
  }
}

