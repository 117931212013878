.conversation-message-mail {

  &.auto_reply {
    opacity: .5;
    transition: opacity .5s;
    &:hover {
      opacity: 1;
    }
  }

  .conversation-message-content {
    > .head {
      > .info {
        > .attachments {
          opacity: 0.75;
          margin: 5px 0;

          .attachment {
            font-size: 10px;
            display: flex;
            float: left;
            margin-right: 5px;
            margin-bottom: 5px;

            &.anonymized {
              cursor: not-allowed;
            }

            .text {
              max-width: 140px;
            }

            .download {
              margin-left: 8px;
            }
          }
        }
      }

      .subject {
        display: flex;
        .eml-download {
          margin-left: 8px;
          opacity: .5;
          transition: opacity .5s;
        }

        &:hover {
          .eml-download {
            opacity: 1;
          }
        }
      }
    }

    > .isolated-mail-body {
      display: block !important;
      margin-top: 10px;
      position: relative;

      img:not(.image-lazyload) {
        max-width: 700px !important;
        height: auto !important;
      }

      > * {
        box-sizing: unset;
        white-space: initial !important;
      }

      /*
      > div,
      > br,
      > blockquote,
      > div > .gmail_quote,
      > div > .gmail_signature,
      #atlas-message,
      #atlas-reply {
        display: none;
      }

      div:first-child,
      &.show-more > div,
      &.show-more > blockquote,
      &.show-more > div > .gmail_quote,
      &.show-more > div > .gmail_signature,
      &.show-more #atlas-message,
      &.show-more #atlas-reply {
        display: initial;
      }
       */

      .missing_body,
      .anonymized {
        opacity: 0.5;
        border: 1px dashed var(--flex-grey1003);
        padding: 15px 15px;
        display: block;
        transition: opacity 0.4s;
        text-align: center;

        &:hover {
          opacity: 1;
        }
      }
    }

    blockquote {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 0;
      margin-inline-start: 40px;
      margin-inline-end: 0;

      blockquote {
        margin-inline-start: 30px;
        blockquote {
          margin-inline-start: 25px;
        }
      }
    }

    .smtp-error {
      padding: 10px;
      background: var(--red-light);
      border: 1px solid var(--red-main);
      border-radius: 3px;
      margin-bottom: 10px;
      color: #000533;
      .title {
        font-weight: lighter;
        font-size: 11px;
        color: #000533;
        span {
          font-size: 12px;
          font-weight: bold;
        }
      }
      .description {
        font-size: 11px;
        margin: 5px 0;
        display: block;
      }
      > button {
        font-size: 11px;
        float: right;
      }
    }

  }

  .email-addresses {
    display: flex;
    .email-address {
      position: relative;
      padding-right: 5px;
      margin-right: 3px;
      .delimiter {
        position: absolute;
        right: 0;
        width: 2px;
        height: 2px;
        background: var(--flex-grey1005);
        top: 50%;
        margin-top: -1px;
      }

      &:last-child {
        .delimiter {
          padding-right: 0;
          display: none;
        }
      }
    }
  }
}
