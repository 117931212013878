@import "../../styles/partials/mixins";

.costumer-journey-mini {
  display: flex;
  flex-direction: column;
  width: 105%;

  >.title {
    opacity: 0.5;
    color: var(--COLOR-000533);
    font-size: 8px;
    margin-bottom: 10px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    padding-left: 7px;

    >.ant-timeline-item {
      padding-bottom: 7px;

      .ant-timeline-item-head-custom {
        padding-bottom: 0;
        padding-top: 4px;
      }
    }
  }

  .journey-row-mini {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;

    >.text {
      font-size: 10px;
      color: var(--COLOR-000533);
      opacity: 0.5;
      margin-left: 3px;
      margin-right: 3px;
      transition: opacity 0.25s;
      white-space: nowrap;
      position: relative;
      padding-left: 7px;

      &.id {
        display: flex;
        margin-right: 0;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: flex-end;
        justify-items: flex-end;
        width: 59px;
        margin-left: 0;
        padding-left: 2px;
        padding-right: 2px;
        & > span {
          @include ellipsis;
        }
      }

      &.dot {
        width: auto;
        height: auto;
        background: none !important;
        &::before {
          content: "•";
          font-size: 12px;
          line-height: 5px;
          margin-top: 4px;
          position: absolute;
          left: -3px;
          width: 10px;
          opacity: 0.5;
        }
      }
    }

    &:hover {
      .text {
        opacity: 1;
      }
    }
  }

  .journey-row-full {
    display: flex;
    flex-direction: column;
  }

}

.conversation-journey-box {
  position: relative;
  .costumer-journey-mini {
    .timeline {
      max-height: 150px;
      overflow-y: auto;
      width: 103%;
      margin-left: -3%;
    }
  }
  .journey-open {
    position: absolute;
    top: 3px;
    right: 12px;
  }
}
