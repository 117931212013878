@import "./manrope";
@import "./rubik";

h1,
h2,
h3,
h4,
h5 {
  font-family: Rubik, serif;
}
