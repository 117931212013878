@import "../../styles/partials/mixins";

.notification {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 14px;
  margin-bottom: 6px;
  background: var(--COLOR-000533);
  border-radius: 8px;
  z-index: 55;

  &.notification-type-K {
    background: #d93f3f;
  }

  &.notification-type-L,
  &.notification-type-M
  {
    background: var(--orange-darker);
    .title {
      color: var(--orange-light) !important;
      opacity: 1 !important;
    }
    .texts .conversation .title {
      color: #000000 !important;
      opacity: 1;
      font-weight: lighter;
      white-space: initial !important;
      line-height: 18px !important;
    }
  }

  @for $i from 4 through 50 {
    &:nth-child(#{$i}) {
      z-index: #{50 - $i};
      margin-top: -30%;
      opacity: #{1 - (($i - 3) / 10)};
      transform: scale(#{1 - (($i - 3) / 20)});
      -webkit-transform: scale(#{1 - (($i - 3) / 20)});
      -ms-transform: scale(#{1 - (($i - 3) / 20)});
      -o-transform: scale(#{1 - (($i - 3) / 20)});
    }
  }

  > .close-wrp {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;

    svg {
      color: var(--COLOR-FFFFFF);
    }
  }

  .header {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-start;
    align-items: center;

    .texts {
      padding-left: 10px;

      .conversation {
        display: flex;
        align-content: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        gap: 7px;
        margin-right: 7px;
        .conversation-id {
          flex-shrink: 0;
        }
        .title {
          margin-bottom: 0;
          line-height: 14px;
        }
      }

      .title,
      .description,
      .time {
        font-size: 12px;
        display: block;
      }

      .title {
        opacity: 0.4;
      }

      .time {
        font-size: 10px;
        opacity: 0.8;
      }

      .title,
      .description,
      .time {
        color: var(--COLOR-FDFEFE);
        margin-bottom: 3px;
      }

      .description .phone {
        color: unset;
      }

      .email {
        font-size: 12px;
        opacity: .7;
      }

      .description {
        font-size: 14px;
        font-weight: lighter;
        margin-top: 8px;

        .ant-typography,
        .icon-wrapper .icon {
          color: var(--COLOR-FDFEFE) !important;
        }

        > span,
        > div {
          float: left;
        }
      }
    }

    .channel-icon-wrp {
      overflow: hidden;
      position: relative;
      width: 36px;
      height: 36px;
      padding: 4px;
      flex-shrink: 0;

      .circle-progress {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .buttons {
    width: 80%;
    margin: 19px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    button {
      padding: 8px 32px;
      font-size: 12px;
      font-weight: 700;
      border-radius: 2px;
      flex-grow: 1;

      svg {
        color: rgba(128, 130, 153, 1);
        margin-right: 6px;
      }

      &:last-child {
        background: rgba(57, 198, 96, 1) !important;

        span {
          color: #FFFFFF !important;
        }

        svg {
          color: #FFFFFF;
        }
      }

      &:first-child {
        background: var(--COLOR-FDFEFE) !important;
        color: var(--COLOR-000533);

        svg {
          color: rgba(128, 130, 153, 1);
          transform: rotate(136deg);
        }

        span {
          color: var(--COLOR-000533) !important;
        }
      }
    }
  }
}
