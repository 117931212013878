@import "../../styles/partials/mixins";

.audio-player-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 5px;

  &.size-s {
    .audio-player {
      width: 32px;
      justify-content: center;
      padding: 0;
      overflow: visible;
      .title, .duration {
        display: none;
      }
      .left-button {
        width: 100%;
      }
      .right-button {
        position: absolute;
        right: -7px;
        top: -7px;
        z-index: 0;
      }
      .progress {
        border-radius: 16px;
        .inner {
        }
      }
    }
    margin-top: 0;
  }

  &.anonymized,
  &.anonymized * {
    cursor: not-allowed !important;
  }

  &.anonymized {
    .audio-player {
      background: var(--grey-middle) !important;
      color: #000533 !important;
    }
    .title {
      color: #000533 !important;
      opacity: .5;
    }
    .left-button {
      display: none !important;
    }
    .title {
      padding-left: 5px;
    }
  }

  .audio-player {
    display: flex;
    align-items: center;
    background: var(--grey-middle);
    padding: 4px;
    height: 32px;
    border-radius: 16px;
    font-size: 11px;
    width: 240px;
    position: relative;
    cursor: col-resize;
    user-select: none;
    overflow: hidden;
    margin-right: 5px;

    &.recording {
      cursor: wait;
    }

    .title {
      padding-right: 5px;
      @include ellipsis();
      flex-grow: 1;
      line-height: 16px;
      font-size: 11px;
    }

    .duration {
      flex-shrink: 0;
      line-height: 12px;
      margin-right: 5px;
    }

    .progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      overflow: hidden;
      .inner {
        background-color: var(--flex-grey1001);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }

    .left-button,
    .right-button {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 24px;
      cursor: pointer;
      border-radius: 50%;
      transition: all .25s;
      &:hover {
        background: var(--flex-grey1002);
      }
    }

    .left-button {
      margin-right: 1px;
      svg {
        stroke-width: 2px;
      }
    }

    &.theme-playback {
      color: var(--blue-main);
      .title {
        color: var(--blue-main);
      }
    }
    &.theme-recording-playable {
      color: var(--blue-main);
      background: var(--blue-light);
      .title {
        color: var(--blue-main);
      }
    }
    &.theme-voicemail-playable {
      background: var(--green-light);
      color: var(--green-main);
      .title {
        color: var(--green-main);
      }
    }
    &.theme-recording-recording,
    &.theme-voicemail-recording {
      background: var(--red-light);
      color: var(--red-main);
      .title {
        color: var(--red-main);
      }
    }

    &.theme-red {
      color: var(--red-main);
      .title {
        color: var(--red-main);
      }
    }

    &.error {
      color: var(--red-main);
      .title {
        color: var(--red-main);
      }
    }

    .loader {
      height: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 3px solid;
      border-color: var(--blue-main) #0000;
      animation: l1 1s infinite;
      position: absolute;
    }
    @keyframes l1 {to{transform: rotate(.5turn)}}
  }
  .download-button {
    margin-right: 5px;
    cursor: pointer;
    svg {
      color: var(--blue-main);
      stroke-width: 2px;
    }
  }

  .transcript-button,
  .generate-transcript-button {
    margin-right: 5px;
    cursor: pointer;
  }

  .hover-marker {
    position: absolute;
    top: -2px;
    margin-left: -14px;
    font-size: 10px;
  }
}
