.conversation-message-text {
    .conversation-message-content {

        display: flex;
        gap: 10px;

        .head {
            min-width: 200px;

            .phone-number {
                font-size: 16px;
                color: var(--grey-dark);
            }


            .source-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                color: var(--COLOR-000533);
                opacity: 0.5;
                font-size: 10px;
            }

            .to-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                color: var(--COLOR-000533);
                opacity: 0.5;
                font-size: 10px;
            }

            .user {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .user-info {
                display: flex;
                align-items: center;
                gap: 5px;
                opacity: 0.5;
            }
        }

        .text {
            font-size: 14px;
            border-radius: 2px;
            width: 100%;
        }
    }
}