body {
  --flex-grey1008: rgba(100, 100, 100, 0.8);
  --flex-grey1007: rgba(100, 100, 100, 0.7);
  --flex-grey1006: rgba(100, 100, 100, 0.6);
  --flex-grey1005: rgba(100, 100, 100, 0.5);
  --flex-grey1004: rgba(100, 100, 100, 0.4);
  --flex-grey1003: rgba(100, 100, 100, 0.3);
  --flex-grey1002: rgba(100, 100, 100, 0.2);
  --flex-grey1001: rgba(100, 100, 100, 0.1);
  --flex-grey1501: rgba(150, 150, 150, 0.1);
  --flex-grey15005: rgba(150, 150, 150, 0.05);
  --flex-grey150025: rgba(150, 150, 150, 0.025);
  --flex-grey1751: rgba(175, 175, 175, 0.1);
  --flex-grey2001: rgba(200, 200, 200, 0.1);
  --flex-white01: rgba(255, 255, 255, 0.5);
  --flex-white001: rgba(255, 255, 255, 0.1);
  --green-grey: #82BF8C;
  --green-light: #E5F5F3;
  --green-main: #39C660;
  --blue-grey: #516395;
  --orange-dark: #F2A100;
  --orange-darker: #d08a00;
  --grey-main: #808299;
  --grey-middle: #E8ECEE;
  --grey-light: #F6F8F9;
  --blue-main: #465EFB;
  --blue-light: #D9E2FE;
  --orange-light: #FDF1C8;
  --red-light: #FCD9D9;
  --red-light-light: #fff7f7;
  --red-main: #E45779;
  --red: #D95959;
  --green: green;

  color: var(--COLOR-000533);
  background: var(--BG-FFFFFF);

  --gray-color: #A7A7A7;

  --phone-outbound-circle-backcolor: red;

}

.master-wrp {
  --status-red: rgba(217, 89, 89, 1);

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body:not(.dark) {
  --COLOR-F7F7F9: #F7F7F9;
  --COLOR-FDFEFE: #FDFEFE;
  --BG-FFFFFF: #FFFFFF;
  --BG-WIDGET: #FFFFFF;
  --COLOR-FFFFFF: #FFFFFF;
  --COLOR-FAFBFC: #FAFBFC;
  --COLOR-FDFDFD: #FFFFFF;
  --COLOR-F3F3F5: #F3F3F5;
  --COLOR-F6F8F9: #F6F8F9;
  --COLOR-FDF1C8: #FDF1C8;
  --COLOR-F4F3F6: #F4F3F6;
  --COLOR-757683: #757683;
  --COLOR-000533: #000533;
  --color: #000533;
  --COLOR-C0CCF2: #C0CCF2;
  --COLOR-D9E2FE: #D9E2FE;
  --COLOR-3953FE: #3953FE;
  --COLOR-C4C4C4: #C4C4C4;
  --COLOR-E8ECEE: #E8ECEE;
  --COLOR-39C6A2: #39C6A2;
  --COLOR-E7E9EE: #E7E9EE;
  --phone-circle-backcolor: rgba(253, 241, 200, 1);

  /*

     --COLOR-FFFFFF: #FFFFFF;
    --COLOR-516395: #516395;
    --COLOR-808299: #808299;
    --COLOR-000000: #000000;
    --COLOR-CCCDD6: #CCCDD6;
    --COLOR-465EFB: #465EFB;
    --COLOR-F3F4F7: #F3F4F7;
    --COLOR-00053380: #00053380;
    --COLOR-0000000D: #0000000D;
    --COLOR-0005334D: #0005334D;
    --COLOR-FAFBFB: #FAFBFB;
    --COLOR-C0CCF2: #C0CCF2;
    --COLOR-0005330D: #0005330D;
    --COLOR-4D629C: #4D629C;
    --COLOR-FFFEFE:#FFFEFE;
    --COLOR-ECEFF0:#ECEFF0;
    --COLOR-A8B1CA: #A8B1CA;
    --COLOR-FAFAFA: #FAFAFA;
    --COLOR-2A2A3A: #FFFFFF;


    --color: var(--COLOR-000533);
    --pannel-btn-backcolor: #465EFB;
    --pannel-btn-txtcolor: var(--COLOR-FFFFFF);
    --header-header.search-back: var(--COLOR-FFFFFF);
    --actionbox-items-backcolor: transparent;
    --actionbox-border: var(--COLOR-E8ECEE);
    --pause-time-color: rgba(0, 5, 51, 0.3);
    --pause-title-color: rgba(0, 5, 51, 1);
    --user-angle-down-border-color: #000;
    --actionbox-items-bordercolor: var(--COLOR-FFFFFF);
    --stats-bodycolor: var(--COLOR-FFFFFF);
    --contentbody-color: var(--COLOR-FFFFFF);
    --details-history-timeline-item-border-color: #E5E5E5;
    --checkbox-border: #d9d9d9;
    --checkbox-background: var(--COLOR-FFFFFF);
    --inbox-wrp-border-color: rgb(209, 212, 214);
    --list-wrp-border-color: rgb(209, 212, 214);
    --gray-color: rgba(0, 5, 51, .5);
    --details-wrp-border-right-color: rgb(209, 212, 214);
    --details-wrp-back: linear-gradient(0deg, var(--COLOR-FAFBFC), var(--COLOR-FAFBFC)),linear-gradient(0deg, var(--COLOR-FFFFFF), var(--COLOR-FFFFFF));
    --details-row-border: rgba(101, 118, 162, .3);
    --action-icons-backcolor: #E7E9EE;
    --action-icons-color: rgba(81, 99, 149, 1);
    --conversation-status-color: rgba(81, 99, 149, 1);
    --detail-action-box: linear-gradient(0deg, var(--COLOR-FFFFFF), var(--COLOR-FFFFFF)),linear-gradient(0deg, #F4F3F6, #F4F3F6);
    --actionbox-border: rgba(0, 5, 51, 0.1);
    --details-actionbox-color: rgba(0, 5, 51, .7);
    --con-reply-time-counter: #000;
    --col-info-color: rgba(0, 5, 51, 1);
    --col-info-color-gray: rgba(0, 5, 51, .5);
    --con-journey-row-timeline-text: rgba(0, 5, 51, .5);
    --details-timeline-wrp: var(--COLOR-FFFFFF);
    --timeline-content-header-bordercolor: rgba(0,0,0,.4);
    --timeline-channels-more-text: rgba(81, 99, 149, 1);
    --priority1-color: rgba(237, 181, 36, 1);
    --priority1-border-color: rgba(237, 181, 36, 1);
    --priority1-back-color: var(--COLOR-FFFFFF);
    --priority2-color: #ED7575;
    --priority2-border-color: #ED7575;
    --priority2-back-color: var(--COLOR-FFFFFF);

    --details-inbox-row: var(--COLOR-FFFFFF);
    --details-inbox-row-border-bottom: rgb(209, 212, 214);
    --last-sec-info-open-li: rgba(57, 83, 254, 1);
    --more-watchers-backcolor: rgba(0, 5, 51, 1);
    --watcher-more-txt-color: var(--COLOR-FFFFFF);
    --inbox-watcher-more-txt-color: rgba(81, 99, 149, 1);

    --active-inbox-row-border-color: rgba(232, 236, 238, 1);
    --active-inbox-row-border-shadow-color: rgba(232, 236, 238, 1);
    --active-inbox-row-border-back-color: linear-gradient(0deg, var(--COLOR-F6F8F9), var(--COLOR-F6F8F9)),linear-gradient(0deg, #F7F7FB, #F7F7FB);

    --mail-circle-backcolor: #D9E2FE;
    --chat-circle-color: #39C6A2;
    --edit-circle-color: #F2A100;
    --chat-circle-backcolor: #E5F5F3;
    --circle-circle-color: #F2A100;

    --watchers-wrp-backcolor: rgba(243, 244, 247, 1);

    --dropdown-back-color: var(--COLOR-FFFFFF);
    --channel-row-more-txt: rgba(81, 99, 149, 1);

    --header.search-types-color: rgba(0, 0, 0, 1);
    --header.search-types-back-color: rgba(232, 236, 238, 1);
    --header.search-types-border-color: transparent;
    --header-header.search-settings-color: #000;
    --header-srch-clear-btn: rgba(70, 94, 251, 1);
    --header-srch-recent-color: rgba(70, 94, 251, 1);

    --reply-wrp-back-color: var(--COLOR-F6F8F9);
    --reply-type-selectbox-wrp: #E7E9EE;
    --reply-delete-icon-color: var(--COLOR-516395);

    --quile-toolbar-back-color: var(--COLOR-F6F8F9);
    --quil-container-back-color: var(--COLOR-FFFFFF);
    --quild-placeholder-color: rgba(0,0,0,0.6);
    --quil-container-border: var(--COLOR-E8ECEE);
    --quil-editor-color: #000;
    --reply-timeline-border-left-color: #f0f0f0;

    --menu-back-color: var(--COLOR-FFFFFF);
    --ant-menu-border-right-color: #f0f0f0;
    --ant-menu-svg-icon-color: rgba(0, 0, 0, 0.85);
    --ant-menu-text-color: var(--COLOR-000533);
    --ant-menu-arrow-icon-color: rgba(0, 0, 0, 0.85);
    --ant-sub-menu-back-color: var(--COLOR-FFFFFF);
    --ant-submenu-text-color: rgba(0, 0, 0, 0.85);

    --inbox-row-open-color: rgba(57, 83, 254, 1);
    --inbox-row-close-color: rgba(81, 99, 149, 1);
    --inbox-watchers-back-color: rgba(243, 244, 247, 1);

    --users-action-btn-background: transparent;
    --users-action-btn-border-color:  var(--COLOR-E8ECEE);

    --notif-icon-circle-shadow-color: #39C660;
    --notif-back-color: var(--COLOR-000533);
    --notif-title-color: var(--COLOR-FFFFFF);
    --notif-reject-btn-back-color: var(--COLOR-FFFFFF);
    --notif-reject-btn-color: rgba(128, 130, 153, 1);

    --panel-item-hover-color: var(--COLOR-FFFFFF);
    --panel-item-hover-back-color: rgba(0, 5, 51, 1);

    --modal-back-color: rgba(0, 5, 51, 1);
    --modal-color: var(--COLOR-FFFFFF);
    --inbox-row-open-close-color: rgba(81, 99, 149, 1);
    --inbox-row-open-close-gray-color: rgba(81, 99, 149, .5);

    --assign-border-color: var(--COLOR-E8ECEE);
    --assign-header-back-color: var(--COLOR-000533);
    --assign-col-head-colors: rgba(128, 130, 153, 1);
    --assign-header.search-color: rgba(0, 5, 51, 1);
    --assign-header.search-icon-color: #000;
    --assign-list-txt-color: var(--COLOR-000533);

    --main-cj-wrp-back-color: rgba(246, 248, 249, 1);
    --main-cj-wrp-border-color: var(--COLOR-E8ECEE);
    --m-cj-title-circle-back-color: rgba(128, 130, 153, 1);
    --m-cj-contents-back-color: var(--COLOR-FFFFFF);
    --m-cj-contents-border-color: var(--COLOR-E8ECEE);
    --m-cj-chat-text-back-color: var(--COLOR-FDF1C8);
    --m-cj-chat-text-border-color: var(--COLOR-FDF1C8);
    --m-cj-timline-border-color: rgba(196, 196, 196, 1);
    --m-cj-chat-text-color: var(--COLOR-000533);
    --m-cj-chat-date-text-color: rgba(0, 5, 51, .5);

    --full-size-content-border-color: rgba(0, 5, 51, 0.3);
    --tab-wrp-boxshadow-color: rgba(0, 5, 51, 0.2);
    --tab-wrp-back-color: var(--COLOR-FFFFFF);
    --tab-duration-color: rgba(0,0,0,1);
    --tab-header-icon-color: #000;
    --tab-fullsize-header-back-color: var(--COLOR-000533);
    --tab-fullsize-header-icon-color: var(--COLOR-FFFFFF);
    --tab-fullsize-body-back-color: var(--COLOR-F6F8F9);
    --tab-conversation-back-color: var(--COLOR-FFFFFF);
    --tab-conversation-border-right-color: var(--COLOR-E8ECEE);
    --tab-c-header-border-bottom-color: #d5d8da;
    --tab-c-info-id-color: rgba(0, 0, 0, 1);
    --tab-c-h-action-back-color: linear-gradient(0deg, var(--COLOR-FFFFFF), var(--COLOR-FFFFFF)), #F4F3F6;
    --tab-c-h-action-border-color: rgba(0, 5, 51, 0.1);
    --tab-c-h-action-color: var(--COLOR-516395);
    --tab-customer-info-border-color: #6576A2;
    --tab-cj-c-header-back-color: linear-gradient(0deg, var(--COLOR-FFFFFF), var(--COLOR-FFFFFF)), var(--COLOR-F7F7F9);
    --tab-cj-c-header-shadow-color: var(--COLOR-E8ECEE);
    --t-cj-c-id-color: rgba(0,0,0,1);
    --loading-back-color: rgba(0,0,0,.25);
    --main-switch-color: rgba(217, 89, 89, 1);

    --admin-page-header-btn: #465EFB;
    --admin-page-header-text-color: #000;
    --admin-page-back-color: linear-gradient(0deg, var(--COLOR-FAFBFC), var(--COLOR-FAFBFC)), var(--COLOR-FFFFFF);
    --table-row-back-color: var(--COLOR-FFFFFF);
    --table-row-border-color: var(--COLOR-E8ECEE);
    --table-cell-color: rgba(0, 0, 0, 0.85);
    --table-row-hover-back-color: #D9E2FE;
    --table-icons-color: var(--COLOR-808299);
    --table-tooltip-back-color: rgba(0, 5, 51, 1);
    --table-tooltip-color: var(--COLOR-FFFFFF);
    --edit-customer-icon: #465EFB;
    --edit-customer-header-back-color: var(--COLOR-F6F8F9);
    --edit-customer-header-shadow-color: rgba(0, 5, 51, 0.1);

     */
}

body.dark {
  --COLOR-F7F7F9: #2A2A3A;
  --COLOR-FDFEFE: #222130;
  --BG-FFFFFF: #2A2A3A;
  --BG-WIDGET: rgba(0, 0, 0, .15);
  --COLOR-FAFBFC: #2D2D3F;
  --COLOR-FDFDFD: #414150;
  --COLOR-F3F3F5: #2B2B3D;
  --COLOR-F6F8F9: #3D3D54;
  --COLOR-FDF1C8: #443F3C;
  --COLOR-F4F3F6: #002727;
  --COLOR-000533: #FFFFFF;
  --color: #FFFFFF;
  --COLOR-757683: #959494;
  --COLOR-C0CCF2: #C0CCF2;
  --COLOR-D9E2FE: #465EFB;
  --COLOR-3953FE: #FFFFFF;
  --COLOR-C4C4C4: #35354C;
  --COLOR-E8ECEE: #242424;
  --COLOR-39C6A2: #39C6A2;
  --COLOR-E7E9EE: #414150;
  --COLOR-FFFFFF: #000000;
  --phone-circle-backcolor: rgba(242, 161, 0, 1);

  /*

     --COLOR-808299: #808299;
    --COLOR-FFFFFF: #2A2A3A;
    --COLOR-516395: #d4d2d2;
    --COLOR-FAFBFB: #FAFBFB;
    --COLOR-000000: #ffffff;
    --COLOR-CCCDD6: #CCCDD6;
    --COLOR-465EFB: #666BD3;
    --COLOR-F3F4F7: #2A2A3A;
    --COLOR-00053380: #FAFBFB;
    --COLOR-0000000D: #0000000D;
    --COLOR-D9E2FE: #465efb;
    --COLOR-0005334D: #0005334D;
    --COLOR-C0CCF2: #C0CCF2;
    --COLOR-0005330D: #3D3D54;
    --COLOR-4D629C: #ffffff;
    --COLOR-FFFEFE:#414150;
    --COLOR-ECEFF0:#2A2A3A;
    --COLOR-A8B1CA: #A8B1CA;
    --COLOR-FAFAFA: #414150;
    --color: var(--COLOR-000000);
    --COLOR-2A2A3A: #2A2A3A;

    --dropdown-back-color: rgba(65, 65, 80, 1);



      --pannel-btn-backcolor: rgba(102, 107, 211, 1);
      --pannel-btn-txtcolor: var(--COLOR-FFFFFF);
      --header-header.search-back: rgba(65, 65, 80, 1);
      --actionbox-items-backcolor: rgba(45, 48, 62, 1);
      --actionbox-border: rgba(0, 0, 0, 0.3);
      --pause-time-color: var(--COLOR-FFFFFF);
      --pause-title-color: var(--COLOR-FFFFFF);
      --user-angle-down-border-color: var(--COLOR-FFFFFF);
      --actionbox-items-bordercolor: rgba(89, 89, 102, 1);
      --stats-bodycolor: rgba(43, 43, 61, 1);
      --details-history-timeline-item-border-color: rgba(29, 29, 41, 1);
      --checkbox-border: rgba(192, 204, 242, 1);
      --checkbox-background: transparent;
      --inbox-wrp-border-color: transparent;
      --list-wrp-border-color: transparent;
      --gray-color: rgba(255, 255, 255, .5);
      --details-wrp-border-right-color: transparent;
      --details-wrp-back: rgba(45, 45, 63, 1);
      --details-row-border: rgba(97, 97, 108, 1);
      --action-icons-backcolor: linear-gradient(0deg, #414150, #414150);
      --action-icons-color: var(--COLOR-FFFFFF);
      --conversation-status-color: var(--COLOR-FFFFFF);
      --detail-action-box: linear-gradient(0deg, #414150, #414150);
      --actionbox-border: rgba(0, 0, 0, 0.3);
      --details-actionbox-color: var(--COLOR-FFFFFF);
      --con-reply-time-counter: var(--COLOR-FFFFFF);
      --col-info-color: rgba(255, 255, 255, 1);
      --col-info-color-gray: rgba(255, 255, 255, .5);
      --con-journey-row-timeline-text: rgba(255, 255, 255, .5);
      --details-timeline-wrp: linear-gradient(180deg, #1F1D2B 0%, #2A2A3A 45.83%, #1F1D2B 100%), #2A2A3A;
      --timeline-content-background: rgba(42, 42, 58, 1);
      --timeline-content-header-bordercolor: rgba(0, 0, 0, 0.3);
      --timeline-channels-more-text: var(--COLOR-FFFFFF);
      --priority1-color: var(--COLOR-FFFFFF);
      --priority1-border-color: rgba(237, 181, 36, 1);
      --priority1-back-color: rgba(237, 181, 36, 1);

      --priority2-color: var(--COLOR-FFFFFF);
      --priority2-border-color: #ED7575;
      --priority2-back-color: #ED7575;

      --details-inbox-row: rgba(42, 42, 58, 1);
      --details-inbox-row-border-bottom: rgba(0, 0, 0, 0.3);

      --last-sec-info-open-li: var(--COLOR-FFFFFF);

      --more-watchers-backcolor: var(--COLOR-FFFFFF);
      --watcher-more-txt-color: rgba(0, 5, 51, 1);
      --inbox-watcher-more-txt-color: var(--COLOR-FFFFFF);

      --active-inbox-row-border-color: rgba(232, 236, 238, 1);

      --active-inbox-row-border-color: rgba(0, 5, 51, 0.3);
      --active-inbox-row-border-shadow-color: rgba(0, 0, 0, 0.05);
      --active-inbox-row-border-back-color: rgba(30, 30, 47, 1);

      --mail-circle-backcolor: rgba(70, 94, 251, 1);
      --chat-circle-backcolor: rgba(57, 198, 162, 1);
      --circle-circle-color: var(--COLOR-FFFFFF);

      --watchers-wrp-backcolor: transparent;

      --dropdown-back-color: rgba(65, 65, 80, 1);
      --channel-row-more-txt: var(--COLOR-FFFFFF);

      --header.search-types-color: var(--COLOR-FFFFFF);
      --header.search-types-back-color: rgba(45, 48, 62, 1);
      --header.search-types-border-color: rgba(0, 0, 0, 0.3);
      --header-header.search-settings-color: var(--COLOR-FFFFFF);
      --header-srch-clear-btn: var(--COLOR-FFFFFF);
      --header-srch-recent-color: var(--COLOR-FFFFFF);

      --reply-wrp-back-color: rgba(42, 42, 58, 1);
      --reply-type-selectbox-wrp: #414150;
      --reply-delete-icon-color: var(--COLOR-FFFFFF);

      --quile-toolbar-back-color: #2d303e;
      --quil-container-back-color: #414150;
      --quild-placeholder-color: var(--COLOR-FFFFFF);
      --quil-container-border: rgba(0,0,0,.3);
      --quil-editor-color: var(--COLOR-FFFFFF);
      --reply-timeline-border-left-color: rgba(29, 29, 41, 1);
      --menu-back-color: #2a2a3a;
      --ant-menu-border-right-color: #23232e;
      --ant-menu-svg-icon-color: var(--COLOR-FFFFFF);
      --ant-menu-text-color: var(--COLOR-FFFFFF);
      --ant-menu-arrow-icon-color: var(--COLOR-FFFFFF);
      --ant-sub-menu-back-color: #414150;
      --ant-submenu-text-color: var(--COLOR-FFFFFF);

      --inbox-row-open-color: var(--COLOR-FFFFFF);
      --inbox-row-close-color: rgba(255, 255, 255, .5);
      --inbox-watchers-back-color: transparent;

      --users-action-btn-background:  rgba(45, 48, 62, 1);
      --users-action-btn-border-color:  rgba(0, 0, 0, .3);

      --notif-icon-circle-shadow-color: var(--COLOR-000533);
      --notif-back-color: var(--COLOR-FFFFFF);
      --notif-title-color: var(--COLOR-000533);
      --notif-reject-btn-back-color: var(--COLOR-000533);
      --notif-reject-btn-color: var(--COLOR-FFFFFF);

      --panel-item-hover-color: rgba(0, 5, 51, 1);
      --panel-item-hover-back-color: var(--COLOR-FFFFFF);


      --modal-back-color: var(--COLOR-FFFFFF);
      --modal-color: rgba(0, 5, 51, 1);

      --inbox-row-open-close-color: var(--COLOR-FFFFFF);
      --inbox-row-open-close-gray-color: rgba(255, 255, 255, .5);

      --assign-border-color: rgb(54, 54, 63);
      --assign-header-back-color: rgba(45, 48, 62, 1);
      --assign-col-head-colors: var(--COLOR-FFFFFF);
      --assign-header.search-color: var(--COLOR-FFFFFF);
      --assign-header.search-icon-color: var(--COLOR-FFFFFF);
      --assign-list-txt-color: var(--COLOR-FFFFFF);


      --main-cj-wrp-back-color: rgba(42, 42, 58, 1);
      --main-cj-wrp-border-color: rgba(0, 0, 0, .2);
      --m-cj-title-circle-back-color: rgba(255, 255, 255, .5);
      --m-cj-contents-back-color: #242430;
      --m-cj-contents-border-color: #191824;
      --m-cj-chat-text-back-color: rgb(235, 178, 64);
      --m-cj-chat-text-border-color: rgb(235, 178, 64);
      --m-cj-timline-border-color: #191824;

      --m-cj-chat-text-color: var(--COLOR-000533);
      --m-cj-chat-date-text-color: rgba(0, 5, 51, .5);

      --full-size-content-border-color: rgb(31, 29, 43);
      --tab-wrp-boxshadow-color: rgb(51, 51, 70);
      --tab-wrp-back-color: #222130;
      --tab-duration-color: rgba(255,255,255,1);
      --tab-header-icon-color: var(--COLOR-FFFFFF);
      --tab-fullsize-header-back-color: var(--COLOR-FFFFFF);
      --tab-fullsize-header-icon-color: #000;
      --tab-fullsize-body-back-color: #2a2a3a;
      --tab-conversation-back-color: #242332;
      --tab-conversation-border-right-color: #1a1924;
      --tab-c-header-border-bottom-color: #000;
      --tab-c-info-id-color: var(--COLOR-FFFFFF);
      --tab-c-h-action-back-color: rgba(45, 48, 62, 1);
      --tab-c-h-action-border-color: rgba(0, 0, 0, 0.3);
      --tab-c-h-action-color: var(--COLOR-FFFFFF);
      --tab-customer-info-border-color: #242430;
      --tab-cj-c-header-back-color: #242332;
      --tab-cj-c-header-shadow-color: #1a1924;
      --t-cj-c-id-color: rgba(255,255,255, 1);
      --loading-back-color: rgba(255,255,255, .25);
      --main-switch-color: rgba(217, 89, 89, 1);

      --admin-page-header-btn: rgba(102, 107, 211, 1);
      --admin-page-header-text-color: var(--COLOR-FFFFFF);
      --admin-page-back-color: rgba(43, 43, 61, 1);
      --table-row-back-color: #2A2A3A;
      --table-row-border-color: #181820;
      --table-cell-color: var(--COLOR-FFFFFF);
      --table-row-hover-back-color: #1d1d27;
      --table-icons-color: var(--COLOR-FFFFFF);
      --table-tooltip-back-color: var(--COLOR-FFFFFF);
      --table-tooltip-color: rgba(0, 5, 51, 1);
      --edit-customer-icon: #465EFB;
      --edit-customer-header-back-color: #2A2A3A;
      --edit-customer-header-shadow-color: rgba(0, 5, 51, 0.1);
      */
}
