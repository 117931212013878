@import "../../styles/partials/mixins";

.ql-wrapper {
  position: relative;
  border: 1px solid var(--flex-grey1003) !important;

  .drop-info {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    font-size: 10px;
    text-transform: uppercase;
    padding: 1px 6px;
    background-color: var(--green-main);
  }

  .html {
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 15px;
    height: 24px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-style: italic;
    opacity: .75;
    cursor: pointer;
    transition: opacity .25s;
    &:hover {
      opacity: 1;
    }
  }

  .html-content,
  .html-content textarea {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    border: 1px solid #FFF !important
  }

  .html-content.hide {
    display: none;
  }
  .quill-content.hide {
  }

  .html-content {
    & > textarea {
      color: black;
      outline: none;
      resize: none;
      font-family: arial;
      font-size: 12px;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-color: #2A2A3A;
      z-index: 4;
      opacity: .5;
      transition: opacity .25s;
      display: flex;
      justify-content: center;
      align-content: center;
      cursor: pointer;
      color: white;
      svg {
        color: white;
      }
      &:hover {
        opacity: .8;
      }
    }
  }

  .ql-toolbar {
    position: absolute;
    max-width: 98% !important;
    width: auto !important;
    margin-left: 8px;
    bottom: 8px;
    background: var(--COLOR-F6F8F9);
    border-radius: 4px;
    border: 0 !important;
    z-index: 1;
    padding: 5px !important;

    button:not(.ant-btn-primary):not(.ant-switch),
    .ql-picker-label {
      color: var(--COLOR-000533) !important;
      background: none !important;

      .ql-stroke {
        stroke: var(--COLOR-000533) !important;
      }

      .ql-fill {
        fill: var(--COLOR-000533) !important;
      }
    }

    button {
      opacity: 0.75;
      transition: 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .ql-editor {
    min-height: 120px;
    background: #FFFFFF;
    color: black !important;
    max-height: 550px;
    h1, h2, h3, h5, h5, h6, p, a {
      all: revert !important;
    }
  }

  .ql-container {
    border: 0;
    padding-bottom: 48px;
    background: var(--flex-grey1001);
  }

  .ql-container {
    .ql-editor {
      padding: 10px;

      &.ql-blank::before {
        color: var(--color) !important;
      }
    }

    .ql-mention-list-container {
      background: var(--COLOR-FAFBFC);
      border-radius: 2px;
      overflow: auto;
      z-index: 100;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      overflow-x: hidden;

      #quill-mention-list {
        min-width: 120px;
        max-width: 180px;
        max-height: 180px;
        overflow: auto;

        @include selectable-list;

        li {
          @include ellipsis;
        }
      }
    }

    .mention {
      background: var(--flex-grey1003);
      padding: 2px 5px;
      border-radius: 3px;
      margin-left: 3px;
    }
  }

  .editor-additional {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    height: 50px;
    display: flex;
    justify-content: right;
    align-items: center;
    flex-grow: 0;
    width: 350px;

    & > div {
      padding: 0 10px;
      position: relative;
      height: 34px;

      .border {
        width: 1px;
        height: 22px;
        background: var(--flex-grey1003);
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -10px;
      }

      &:last-child {
        padding-right: 0;
        .border {
          display: none;
        }
      }
    }

    .attachments {
      display: flex;
      align-items: center;
      margin-top: 5px;

      label {
        font-size: 10px;
        margin-right: 10px;
        color: #333333;
        opacity: .8;
        line-height: 16px;
      }

      button {
        outline: none;
        border: 0;
        font-size: 12px;
        padding: 0 12px;
        background-color: #747287 !important;
        color: white !important;
      }

      .ant-upload-select {
        .ant-upload {
          display: flex;
          align-items: center;
          color: var(--color);

          .files {
            font-size: 12px;
            opacity: 0.5;
          }

          button {
            margin-right: 8px;
          }
        }
      }

      .ant-upload-list {
        display: none;
        position: absolute;
        background: var(--blue-main);
        color: #FFFFFF;
        bottom: 100%;
        z-index: 3;
        padding: 4px 0;
        margin-left: -123px;
        max-width: 190px;

        .ant-upload-list-item {
          margin-top: 0;
          height: auto;

          .ant-upload-list-item-info {
            padding: 3px 4px;
            font-size: 12px;

            .ant-upload-text-icon {
              display: none;
            }

            .ant-upload-list-item-name {
              max-width: 180px;
              padding: 0 0 0 3px;
              color: white;
              cursor: zoom-in;
            }

            .ant-upload-list-item-card-actions {
              display: flex;

              .ant-btn {
                height: 18px;
                padding: 0 3px;
                width: auto;
                background: transparent !important;

                .icon-wrapper {
                  margin-right: 0;

                  svg {
                    color: white;
                  }
                }
              }
            }

            &:hover {
              opacity: 1;
              background: var(--flex-grey1751);
            }
          }
        }
      }

      &:hover {
        .ant-upload-list {
          display: block;
        }
      }
    }

    .canned-answers {
      display: flex;
      align-items: center;
      width: 120px;
      justify-content: right;

      label {
        font-size: 12px;
        color: #333333;
        margin-right: 5px;
        opacity: 0.5;
        transition: opacity 0.25s;
        padding-top: 6px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

}

.editor-toggle {
  position: absolute;
  right: 0;
  z-index: 9;
  margin-bottom: -10px;
  padding: 5px;
  opacity: .5;
  transition: opacity .5s;
  &:hover {
    opacity: 1;
  }
}

.ant-popover.conversation-message-reply-canned-answers-popover {
  min-width: 200px !important;
  max-width: 440px !important;

  .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    width: 100% !important;
    max-height: 180px;
    overflow-y: auto !important;

    .search {
      input {
        background-color: var(--flex-grey1003);
        width: 100%;
        border: 1px solid var(--flex-grey1003);
        outline: none;
      }
    }
    ul {
      @include selectable-list;
    }
  }
}

/*@media screen and (max-width: 1050px) { */
  .ql-wrapper {
    .ql-container {
      padding-bottom: 85px !important;
    }
    .ql-toolbar {
      bottom: 45px !important;
    }
    .html {
      bottom: 49px !important;
    }
  }
/* } */
