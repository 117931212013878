@import "../../../../styles/partials/mixins";

.conversation-contact-view {
  display: flex;
  flex-direction: column;
  max-width: 300px;

  .select-or-create-contact {
    border: 1px solid var(--flex-grey1002);
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    transition: all .25s;
    opacity: .75;
    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    .conversation-contact-view-edit-button,
    .conversation-contact-toggle-timeline-button,
    .conversation-contact-anonymize {
      opacity: .6;
      &:hover {
        opacity: 1;
      }
    }
  }

  .row {
    display: flex;
    &:first-child {
      gap: 3px;
    }
  }

  .conversation-contact-view-title {
    color: var(--COLOR-000533);
  }

  .conversation-contact-view-edit-button,
  .conversation-contact-toggle-timeline-button,
  .conversation-contact-anonymize {
    color: var(--COLOR-000533);
    opacity: 0.25;
    transition: opacity 0.5s;
    display: flex;
  }

  .action-buttons {
    margin-top: 5px;
  }

  .emails,
  .phones {
    color: var(--COLOR-000533);
    font-size: 10px;
    opacity: 0.5;
    max-width: 150px;

    .display-phone {
      display: inline;
    }

    > span {
      &:before {
        content: '| ';
        margin-left: 3px;
        margin-right: 3px;
        opacity: .25;
      }
      &:first-child,
      &.hidden-count {
        &:before {
          display: none;
        }
      }
      &.hidden-count {
        background: var(--flex-grey1002);
        padding: 1px 3px 1px 2px;
        border-radius: 10px;
        margin-left: 5px;
        font-size: 9px;
      }
    }

    @include ellipsis;

    transition: opacity 0.25s;

    .phone-number {
      margin-right: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  .phones {
    display: flex;
  }

  >.title {
    opacity: 0.5;
    color: var(--COLOR-000533);
    font-size: 8px;
    margin-bottom: 10px;
  }

  >small {
    margin-bottom: 5px;
  }

  .icon1 {
    width: 24px;
    height: 24px;
    background-color: #FCD9D9;

    svg {
      color: #000000;
      opacity: 0.5;
    }

    border-radius: 50px;
    padding: 5px;
    margin-right: 12px;
  }

  .edit.icon-wrapper {
    color: var(--COLOR-000533);
    margin-left: 5px;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .icon-view {
    display: flex;
    margin: 2px;
    border: 1px solid var(--flex-grey1002);
    padding: 4px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.emails-tooltip {
  .ant-tooltip-inner > span {
    display: block;
  }
}
