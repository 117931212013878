.header-popover {
  width: 240px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  > .header {
    border-radius: 3px 3px 0 0;
    background-color: var(--COLOR-000533);
    color: var(--COLOR-FDFEFE);
    padding: 14px;
    display: flex;
    flex-direction: row;

    .title {
      color: var(--COLOR-FDFEFE);
      flex-grow: 1;

      >.name {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .right {
      flex-grow: 0;
    }
  }
}
