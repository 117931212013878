.form-component-widget-types {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  .widget-type {
    width: 100px;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border: 1px solid var(--flex-grey1501);
    flex-shrink: 0;
    cursor: pointer;
    background: white;
    color: black !important;
    transition: all .25s;
    svg {
      color: black !important;
    }
    &.selected {
      background: #CCCCCC !important;
    }
    &:hover {
      background: #DDDDDD;
    }
    .type {
      font-size: 12px;
      margin-top: 3px;
    }
    .icon-wrapper {
      margin-top: 10px;
    }
  }
}
