.ant-table {
  .boolean-column {
    .ant-typography {
      opacity: 0.5;
      font-size: 12px;
    }
  }

  .array-column {
    .array-item {
      font-size: 12px;
      padding: 3px 12px;
      background: var(--flex-grey1002);
      border-radius: 8px;
      margin-right: 3px;
      margin-bottom: 3px;
    }
  }

  .number-column,
  .length-column {
    font-size: 12px;
    padding: 3px 12px;
    background: var(--flex-grey1002);
    border-radius: 8px;
    margin-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }

  .date-column {
    font-size: 13px;
    small {
      font-size: 10px;
      opacity: .5;
    }
  }

  .list-description {
    max-width: 120px;
    @include ellipsis;
    font-size: 12px;
  }

  .with-info {
    display: flex;
    align-items: center;
    > .icon-wrapper {
      padding-left: 5px;
    }
  }

  background: transparent;
  color: var(--COLOR-000533);

  .ant-table-container {
    .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          border-bottom: 0;
          color: var(--COLOR-000533);
          background: var(--flex-grey1001);
        }
      }

      .ant-table-tbody {
        .ant-table-row,
        .ant-table-placeholder {
          &:hover .ant-table-cell,
          .ant-table-cell-row-hover {
            background: var(--flex-grey1501);
          }

          .ant-table-cell {
            border-bottom: 1px solid var(--flex-grey1002);

            &.ant-table-column-sort {
              background: var(--flex-grey1501);
            }
          }
        }
      }
    }
  }

  .ant-table-cell-fix-left {
    background-color: var(--flex-grey1751);
  }
}

.ant-table-filter-dropdown {
  min-width: 220px;

  & > div > div {
    max-height: 220px;
    overflow: auto;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .ant-btn {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        margin-right: 3px;
      }
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}
