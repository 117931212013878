@import "../../../styles/partials/mixins";

.inbox-container {
  @include container();

  .conversation-list {
    outline: none;
  }

  flex-direction: row;
  height: 100%;

  .inbox-left {
    flex: 2;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--COLOR-E8ECEE);
    background-color: var(--BG-FFFFFF);
    padding-right: 0;
    overflow-y: hidden;

    &.hidden {
      display: none;
    }

    .buttons {
      display: flex;
      button {
        margin: 0;
      }
      .ant-btn-primary {
        margin-left: 8px;
      }
    }

    .inbox-selected {
      display: flex;
      flex-direction: column;
      padding: 7px;
      background-color: var(--flex-grey1751);

      &:not(.shortened) {
        flex-direction: row;
        align-items: center;
        padding: 10px 20px;

        .title {
          margin-bottom: 0;
          margin-right: 10px;
        }

        .options {
          flex-grow: 0;
        }

        .buttons {
          margin: 0 10px;
        }
      }

      &.shortened {
        .buttons {
          .ant-btn-primary {
            flex-grow: 1;
          }
        }
      }

      .title {
        padding-right: 5px;
        margin-bottom: 8px;
      }

      .options {
        flex-grow: 1;
        display: flex;

        .option {
          margin-right: 7px;
          display: flex;
          align-items: center;

          .option-title {
            font-size: 12px;
            margin-right: 5px;
          }

          .option-action {
            min-width: 80px;
          }
        }
      }

      .buttons {
        margin: 12px 5px 2px;
      }
    }

    &.short {
      max-width: 420px;
      min-width: 420px;
      .conversation-filter {
        padding-bottom: 2px;
      }
    }

    .inbox-left-header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 10px 5px;

      .count-bar {
        display: flex;
        flex-direction: row;
        margin: 5px;
        align-items: center;

        .ant-checkbox-inner {
          border-color: var(--COLOR-C0CCF2);
        }

        .ant-checkbox-wrapper {
          margin-right: 16px;
        }

        > .text {
          color: var(--COLOR-000533);
          opacity: 0.5;
          font-size: 12px;
        }
      }

      .conversation-filter,
      .conversation-sort {
        display: flex;
        justify-content: center;
        align-items: center;

        .select-conversation-filter,
        .ant-select,
        .edit-conversation-filter {
          padding: 8px 10px;
          border-radius: 3px;
          cursor: pointer;
          height: 32px;
          font-size: 13px;
        }

        .select-conversation-filter,
        .ant-select {
          height: 32px;
          margin-left: 2px;
          // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
          background: var(--COLOR-FDFEFE);
          color: var(--COLOR-000533);

          .ant-select-selector {
            height: 34px;
          }

          .ant-typography {
            color: var(--COLOR-000533);
            font-size: 12px;
          }
        }

        .select-conversation-filter {
          border: 1px solid var(--flex-grey1003) !important;
        }
      }

      .conversation-filter {
        min-width: 200px;
        justify-content: flex-start;
        margin-right: 15px;

        .select-conversation-filter {
          width: 225px;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        .ant-badge-count {
          background: var(--COLOR-000533);
          color: var(--COLOR-FDFEFE);
          border: 0;
          font-size: 9px;
          min-width: 15px;
          height: 15px;
          border-radius: 50%;
          padding: 0;
          line-height: 15px;
          box-shadow: none;
          transform: translate(35%, -35%);
        }

        .edit-conversation-filter {
          background: var(--blue-grey);
          color: #ffffff;
        }
      }

      .conversation-sort,
      .conversation-reload {
        //margin-left: auto;

        .ant-select {
          margin-right: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          .ant-select-selector {
            border: 0;
            background: transparent;
            padding: 0 15px;
          }
        }

        .icon-wrapper {
          padding: 8px 11px;
          height: 34px;
          border-radius: 3px;
          border: 1px solid var(--flex-grey1003);
          color: var(--COLOR-000533);
          &:hover {
            background: var(--flex-grey1003);
          }
        }
      }

      .conversation-reload {
        margin-left: 2px;
      }

      .conversation-download {
        margin-left: auto;

        .ant-select {
          margin-right: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          .ant-select-selector {
            border: 0;
            background: transparent;
            padding: 0 15px;
          }
        }

        .icon-wrapper {
          padding: 8px 11px;
          height: 34px;
          border-radius: 3px;
          border: 1px solid var(--flex-grey1003);
          color: var(--COLOR-000533);
          &:hover {
            background: var(--flex-grey1003);
          }
        }
      }
    }
  }

  .inbox-right {
    overflow: hidden;
  }

  .inbox-right,
  .create-conversation-form {
    flex: 5;
  }

  .create-conversation-form {
    padding: 15px;
    overflow-y: auto;
  }
}

.inbox-left-list {
  overflow: hidden;
  padding-top: 7px;
  padding-bottom: 100px;
}

.filter-bar {
  display: flex;
  flex-direction: row;
}

.conversation-filter-popover-form {
  width: 480px;
  max-height: 640px;
  overflow: auto;
  padding: 0 10px 10px;

  .ant-form {
    .ant-form-item-label {
      label {
        .text {
          padding-right: 0 !important;
        }
      }

      .after {
        display: none;
      }
    }
  }
}

.conversation-filter-select-popover {
  padding-bottom: 5px;

  button.ant-btn {
    border-color: var(--blue-main) !important;
    color: var(--blue-main) !important;
    margin: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
  }

  ul {
    color: var(--COLOR-000533);
    list-style: none;
    padding: 3px 0;
    margin: 0;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      font-size: 13px;

      &:hover {
        .icon-wrapper.delete {
          opacity: 1;
        }
      }

      .text {
        padding: 3px 15px 3px 11px;
        flex-grow: 1;
        cursor: pointer;
        max-width: 300px;

        @include ellipsis;
      }

      .icon-wrapper {
        width: 16px;
        margin: 0;

        &.delete {
          opacity: 0.1;
          transition: opacity 0.25s;
        }

        &.circle {
          width: 16px;
          height: 16px;
          border: 1px solid #cbd0df;

          svg {
            color: #cbd0df;
          }
        }

        &.selected {
          svg {
            color: #ffffff !important;
          }

          background: #3953fe;
          border-color: #3953fe;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .inbox-container.with-id {
    .inbox-left {
      display: none;
    }
  }
}
