@import "../../../styles/partials/mixins";

.conversation-form-preview {
  padding: 10px 28px 5px 15px;
  position: relative;
  background: var(--flex-grey1751);

  .icon-wrapper.edit {
    position: absolute;
    top: 15px;
    right: 10px;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .tag-list {

    width: 100%;
    height: 27px;

    .tag-list-inner {
      display: flex;
      position: absolute;
      left: 0;
      right: 35px;
      padding: 0 10px;
      overflow: auto hidden;
    }

    .ant-tag {
      display: flex;
      &:not(.tag-error):not(.tag-warning) {
        background: transparent;

        .tag-title {
          font-weight: lighter;
          opacity: 0.75;
        }
      }

      .value {
        font-weight: bold;
        display: flex;

        span {
          @include ellipsis;
          max-width: 150px;
          display: inline-block;
          padding-left: 3px;
        }

        &::before {
          content: ": ";
        }
      }

      .error {
        display: none;
      }
    }
    .count {
      font-size: 10px;
      background: var(--flex-grey1001);
      border-radius: 50%;
      height: 21px;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: center;
      padding: 0 5px;
    }
  }
}
