.audio-recorder {
  display: flex;
  align-items: center;
  .round-btn {
    height: 33px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 33px;
    cursor: pointer;
    border-radius: 50%;
    transition: all .25s;
    opacity: .7;
    margin-right: 6px;
    svg {
      color: white;
      stroke-width: 2px;
    }
    &:hover {
      opacity: 1;
    }
    &.upload {
      background: var(--blue-main);
    }
    &.record {
      background: var(--red-main);
    }
    &.refresh {
      background: #000533;
    }
  }

  .upload-info {
    margin-right: 15px;
    cursor: help;
    opacity: .5;
    transition: opacity .25s;
    font-size: 12px;
    &:hover {
      opacity: 1;
    }
    svg {
      stroke-width: 2px;
    }
  }

  > .timer {
    font-size: 12px;
    margin-right: 25px;
    color: var(--COLOR-000533);
  }

  .message {
    font-size: 14px;
    line-height: 36px;
    margin-right: 25px;

    &.success {
      color: var(--blue-main);
    }

    &.fail {
      color: var(--red-main);
    }
  }


}
