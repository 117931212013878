.widget-type-grid {
  table {
    thead {
      tr {
        td {
          &:first-child {
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            background: var(--flex-grey15005);
          }
        }
      }
    }
  }
}
