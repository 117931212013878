#notifications-settings {
  list-style-type: none;
  margin: 0;
  padding: 5px;

  li {
    padding: 5px 8px 5px 12px;
    border-bottom: 1px solid var(--flex-grey1002);
    display: flex;
    align-items: center;
    color: var(--COLOR-000533);
    transition: opacity 0.2s;
    font-size: 12px;
    opacity: 0.7;

    .ant-switch {
      margin-right: 8px;
    }

    &:last-child {
      border: 0;
    }

    &:hover {
      opacity: 1;
    }
  }
}
