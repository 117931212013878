#phonenumber_purchase-cost-preview {
  max-width: 650px;
  th {
    text-align: left;
    font-weight: lighter;
    font-size: 12px;
    color: var(--flex-grey1008);
    width: 155px;
    vertical-align: top;
  }
  td {
    padding-left: 15px;
    padding-bottom: 5px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
}
