@import "../../styles/partials/mixins";

.emergency-call {
  pointer-events: initial;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 5px;
  .icon {
    display: flex;
    margin-left: 3px;
  }
  @include tabStyle;
  .callerid {
    min-width: 80px;
    max-width: 140px;
    margin-right: 15px;
    font-size: 14px;
    line-height: 21px;
    @include ellipsis;
    span {
      font-size: 12px;
      opacity: .5;
    }
  }
  .timer {
    margin-right: 10px;
    font-size: 10px;
  }
  .hangup {
    margin-right: 0;
    cursor: pointer;
    .icon-wrapper {
      padding: 5px;
      border-radius: 2px;
      background-color: var(--red-main);
      svg {
        color: #FFFFFF !important;
      }
    }
  }
  > div {
    margin-right: 5px;
  }
}
