.conversation-title-view {
  display: flex;
  flex-direction: column;
  position: relative;

  >.icons-container {
    margin-right: auto;
    margin-top: 12px;
  }

  > .row {
    display: flex;
    margin-bottom: 4px;
    gap: 5px;

    >.title {
      opacity: 0.5;
      color: var(--COLOR-000533);
      font-size: 8px;
      text-transform: uppercase;
      padding-right: 15px;
    }

    >.id {
      color: var(--COLOR-000533);
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  .conversation-toggle-timeline-button {
    opacity: .25;
    transition: opacity .5s;
  }

  &:hover {
    .conversation-toggle-timeline-button {
      opacity: .6;
      &:hover {
        opacity: 1;
      }
    }
  }

  .expand-icons {
    background: none;
    margin-left: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 6px;
    right: 18px;

    .icon-wrapper {
      background: var(--COLOR-E7E9EE);
      color: var(--COLOR-516395);
      border-radius: 2px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-right: 3px;
      }
    }
  }

  .open {
    color: var(--COLOR-516395);
    font-size: 12px;
  }

  .close {
    color: var(--COLOR-516395);
    font-size: 12px;
  }

  .text {
    font-size: 12px;
    margin-left: 4px;
  }

  .icon-view {
    display: flex;
    margin: 2px;
    border: 1px solid #0005331A;
    padding: 4px;
    color: var(--COLOR-516395);
    border-radius: 3px;
    background-color: white;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.tab-maximize-view {
  .sub-title {
    .conversation-title-view {
      flex-direction: row;
      flex-grow: 2;
      padding-right: 20px;

      > .row {
        align-items: center;
        height: 30px;
        margin-bottom: 0;

        &:last-child {
          margin-left: auto;
        }
      }
    }

    .conversation-title-view-icons {
      max-width: 50%;
      min-width: 340px;
    }
  }
}
