.header-menu {
  display: flex;
  height: 100%;

  > ul {
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-grow: 1;

    li {
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 1px;

      a {
        border-radius: 5px;
        height: 44px;
        font-size: 14px;
        padding: 6px 10px;
        display: flex;
      }

      &.active {
        a {
          background-color: var(--blue-main);

          svg, span {
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .header-menu {
    padding: 0;

    > ul {
      li {
        a {
          height: 48px;

          .icon-wrapper {
            margin-right: 0 !important;
          }

          .ant-typography {
            display: none;
          }
        }
      }
    }
  }
}
