.atlas-widget-timeline {
  font-size: 12px;
  height: 100%;
  position: relative;
  .board {
    position: relative;
    display: flex;
    overflow: auto;
    height: calc(100% - 25px);
  }
  .controls {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    display: flex;
    background: var(--BG-FFFFFF);
    .icon-wrapper {
      padding: 3px;
      background: var(--flex-grey1001);
    }
  }
  .legends {
    padding: 5px 5px 0;
    font-size: 11px;
    overflow: hidden;
    display: flex;
    gap: 8px;
    .legend {
      display: flex;
      align-items: center;
      .box {
        width: 15px;
        height: 12px;
        margin-right: 3px;
        border: 1px solid white;
      }
    }
  }
  .left {
    width: 20%;
    max-width: 150px;
    flex-shrink: 0;
    flex-grow: 0;
    background: var(--BG-FFFFFF);
    z-index: 1;
    min-height: 100%;
    position: sticky;
    left: 0;
    .inner {
      width: 100%;
      height: 100%;
      background: var(--flex-grey1501);
    }
    .tracks {
      background: var(--BG-FFFFFF);
      background: var(--flex-grey1501);
      .track {
        span {
          width: 100%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 6px 10px;
          font-size: 12px;
        }
      }
    }
  }
  .right {
    .scroll {
      .tracks {
        overflow: hidden;
        .track {
          position: relative;
          height: 30px;
          .element {
            position: absolute;
            height: 80%;
            top: 10%;
            background: var(--flex-grey1501);
            border: 1px solid var(--flex-grey1501);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            &.clickable {
              cursor: pointer;
            }
            span {
              overflow: hidden;
              display: flex;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              padding: 1px 4px 0;
              align-items: center;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .head {
    height: 40px;
    width: 100%;
    background: var(--BG-FFFFFF);
    position: sticky;
    top: 0;
    z-index: 2;
    .inner {
      width: 100%;
      height: 100%;
      background: var(--flex-grey1501);
    }
    .track {
      height: 24px;
      width: 100%;
      overflow: hidden;
      border-bottom: 1px solid var(--flex-grey1001);
      .element {
        height: 30px;
        padding: 5px 3px;
        float: left;
        border-right: 1px solid var(--flex-grey1001);
        border-bottom: 1px solid var(--flex-grey1001);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  .tracks {
    width: 100%;
    background: var(--flex-grey1001);
    overflow: hidden;
    .track {
      height: 30px;
      border-bottom: 1px solid var(--flex-grey1001);
    }
  }
}
