@import "../../../styles/partials/mixins";

.user-presence {
  position: relative;
  width: 553px;
  padding: 3px;
  display: flex;
  flex-direction: column;

  > .header {
    width: 100%;
    height: auto;
    padding: 7px 16px;
    display: flex;
    justify-content: space-between;

    .setting-wrp {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        color: var(--header-search-settings-color);
      }
    }

    ul.users-types-wrp {
      min-height: 49px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 12px;
        padding: 4px 8px;
        border-radius: 40px;
        font-size: 12px;
        line-height: 20px;
        min-width: 40px;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        margin-bottom: 6px;
        box-sizing: border-box;
        background: #E8ECEE;
        color: #000533;

        &.active {
          background-color: rgba(70, 94, 251, 1);
          background-color: var(--blue-main);
          color: #FFFFFF;
        }
      }
    }
  }

  > .search {
    position: relative;
    width: 100%;
    padding: 0 10px;

    input {
      display: block;
    }

    .icon-wrapper {
      position: absolute;
      top: 8px;
      right: 20px;
    }
  }

  > .users {
    display: flex;
    flex-direction: column;
    padding: 7px 16px;
    max-height: 350px;
    overflow: auto;
    width: 100%;

    > .user {
      height: 40px;
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .twinned {
        text-transform: uppercase;
        color: var(--COLOR-000533);
        background-color: transparent;
        font-size: 9px;
        font-weight: 700;
        border: 1px solid var(--flex-grey1003);
        opacity: 0.3;
        outline: none;
        border-radius: 6px;
        padding: 2px 4px;
        line-height: 11px;
        margin-left: 5px;
        transition: border .25s;
        display: inline-block;
      }

      .twin-icon {
        margin-left: 5px;
      }

      > .col {
        flex-grow: 1;
        display: inline-flex;
        align-items: center;

        &.user {
          width: 220px;
          @include ellipsis;
          display: flex;


          flex-grow: 0;

          .name {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: var(--color);
            font-size: 10px;
            font-weight: 300;
            flex-grow: 1;
          }

          figure {
            margin-right: 10px;
          }
        }

        &.status {
          .status-wrapper {
            font-weight: 700;
            position: relative;
            display: flex;
            align-items: center;

            .color {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 5px;
            }

            .status {
              font-size: 10px;
            }
          }
        }

        &.buttons {
          flex-grow: 0;
          justify-content: space-between;
          display: flex;

          button, .icon-text-wrapper {
            color: var(--COLOR-000533);
            background-color: transparent;
            font-size: 10px;
            font-weight: 700;
            border: 1px solid var(--flex-grey1003);
            opacity: 0.3;
            outline: none;
            border-radius: 6px;
            padding: 0 8px;
            height: 22px;
            line-height: 11px;
            cursor: pointer;
            margin-left: 5px;
            transition: border .25s;

            svg {
              color: var(--COLOR-000533);
              margin: 0;
              opacity: .5;
            }

            .icon-wrapper {
              display: none;
            }

            &:hover {
              border-color: var(--COLOR-000533);
            }
          }
        }
      }
    }
  }
}
