/* cyrillic-ext */
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./static/Rubik-Medium.ttf);
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./static/Rubik-Bold.ttf);
}
