.header-notifications-popover {
  .list-wrapper {
    .scroll-view {
      max-height: 400px;
    }
  }

  > .header {
    .title {}

    .right {
      display: flex;
    }
  }

  .header-notification {
    padding: 8px 5px;
    cursor: pointer;
    transition: background-color 0.25s;

    &:hover {
      background: var(--flex-grey1001);
    }

    &.not-seen {
      position: relative;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--red-main);
        display: block;
        position: absolute;
        z-index: 1;
      }

      .texts {
        .description {
          font-weight: bold;
        }
      }
    }

    > .header {
      display: flex;
      width: 100%;
      height: auto;
      justify-content: flex-start;
      align-items: center;

      .texts {
        padding-left: 10px;

        .title,
        .description,
        .time {
          font-size: 12px;
          display: block;
        }

        .title {
          opacity: 0.3;
        }

        .time {
          font-size: 10px;
          opacity: 0.8;
        }
      }

      .channel-icon-wrp {
        padding: 4px;
        flex-shrink: 0;
      }
    }
  }
}
