.conversation-edit-popover {
  height: auto;
  position: relative;
  background: var(--COLOR-FDFDFD);
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-height: 100px;

  .conversation-edit-header {
    background-color: var(--COLOR-000533);
    color: var(--COLOR-FFFFFF);
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .select-wrapper {
    .ant-select {
      .ant-select-selector {
        height: 38px;
        padding: 3px 11px;

        .ant-select-selection-item {
          figure,
          .select-btn {
            display: none;
          }
        }
      }
    }

    .ant-select-item-option {
      .avatar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 4px;
      }

      figure {
        margin-right: 7px;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .option-title {
        font-size: 10px;
      }

      .select-btn {
        display: none;
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: 5px;
      }

      &:hover,
      &.ant-select-item-option-selected {
        background-color: unset;

        .select-btn {
          display: flex;
        }
      }
    }
  }

  .search-wrp {
    width: 100%;
    height: auto;
    margin: 4px auto;
    border: 1px solid var(--flex-grey1002);

    .search-content {
      box-sizing: border-box;
      border-radius: 2px;
      display: flex;
      height: 40px;
      justify-content: space-between;

      input {
        height: 100%;
        padding: 0 16px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--COLOR-000533);
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        outline: none;
        background: transparent;
        border: 0;
      }

      .search-icon-wrp {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 10px;
        opacity: 0.7;

        svg {
          color: var(--COLOR-000533);
        }
      }
    }
  }
}
