.stats-report-report {
  h1 {
    margin-bottom: 20px;
  }
  overflow: hidden;

  .a4 {
    padding: 35px;
    background: var(--BG-FFFFFF);
    max-width: 1580px;
    margin: 25px auto 0;
  }

  .stats-widget-wrapper {
    .splits {
      .split-row {
        .split {
          h2 {
            padding: 10px 0 !important;
          }
        }
      }
    }
  }
}
