.conversation-contact-form,
.conversation-form {
  width: 400px;
  padding: 28px 30px 28px 24px;

  h2 {
    color: var(--color);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 2px;
  }
}
