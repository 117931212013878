.conversation-message-draft {
  .conversation-message-content {
    background: rgba(255, 0, 0, 0.1);
    overflow-x: visible !important;
    .body {
      img {
        max-width: 100%;
      }
    }
  }

  &.editable {
    .conversation-message-content {
      background: rgba(200, 200, 200, 0.1);
    }
  }

  .drop-info {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    font-size: 10px;
    text-transform: uppercase;
    padding: 1px 6px;
    background-color: var(--green-main);
    color: white;
  }

  .ai-request {
    padding: 5px 0;
    font-size: 12px;
    .confidence {
      opacity: .7;
      margin-right: 5px;
      color: var(--blue-main);
    }
    .score {
      font-weight: bold;
      color: var(--blue-main);
    }
  }
}
