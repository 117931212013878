.elevenlabs-editor {

  display: flex;

  .audio-player-wrapper {

    margin: 0 25px;

    .audio-player {

      border-radius: 50%;
      width: 48px;
      height: 48px;

      .progress {
        border-radius: 50%;
      }

      .left-button svg {
        width: 24px;
        height: 24px;
        stroke-width: 1px;
      }

    }
  }
}
