.journey-timelines {

  &.multiple {
    .journey-timeline {
      margin-top: 12px;
    }
    .event-type-filter {
      padding-top: 8px;
    }
  }

  .event-filter {
    display: flex;

    .event-type-filter,
    .journey-filter {
      padding: 5px 10px;
      display: flex;
      align-items: center;

      .title {
        margin-right: 10px;
        opacity: .75;
        display: none;
      }
    }

  }

  .journey-timeline {
    width: 100%;
    padding: 5px 40px 5px 15px;
    margin: 5px 0;
    border-radius: 2px;
    opacity: .7;
    transition: opacity .25s;
    background: var(--flex-grey1501);
    position: relative;

    &:hover {
      opacity: 1;
    }

    &.error {
      background: var(--red-main);
      color: #FFFFFF;
    }

    .journey-timeline-date {
      position: absolute;
      background: var(--flex-grey1501);
      font-size: 11px;
      top: -10px;
      left: 5px;
      padding: 2px 4px;
      z-index: 1;
    }

    .wrapper {
      position: relative;
      height: 30px;


      .line {
        height: 2px;
        position: absolute;
        top: 9px;
        background: var(--blue-grey);
        width: 100%;
        z-index: 0;
        opacity: .7;

        .time {
          font-size: 9px;
          position: absolute;
          top: 9px;
          white-space: nowrap;
        }
      }

      .dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: var(--blue-main);
        color: #FFFFFF;
        position: absolute;
        z-index: 1;
        left: 12%;
        top: 4px;
        margin-left: -7px;
        font-size: 8px;
        text-align: center;
        line-height: 13px;
        cursor: help;
        transition: background .25s;
        &:hover {
          background: var(--blue-light);
          z-index: 2;
        }
      }
    }
  }
}

.conversation-timeline-tooltip {
  color: #FFFFFF;
  max-width: 260px;
  ul {
    list-style-type: none;
    padding: 5px 0;
    margin: 0;
    li {
      .row {
        font-size: 11px;
        line-height: 18px;
        display: flex;
        margin-bottom: 3px;
        width: 100%;

        .time {
          background: rgba(255,255,255,.2);
          font-size: 10px;
          line-height: 18px;
          padding: 0 5px;
          border-radius: 3px;
          margin-right: 3px;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }

        .title {
          color: #FFFFFF;
          display: flex;
          align-items: center;
        }

        .description {
          font-size: 10px;
          line-height: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .journey {
          background: rgba(255,255,255,.2);
          padding: 0 5px;
          border-radius: 3px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

      }
      border-bottom: 1px solid rgba(255,255,255,.2);
      padding: 5px 1px;
    }
  }
}
