.stats-report {
  background-color: var(--COLOR-F3F3F5);
  padding-bottom: 25px;
  min-height: 100%;

  .header {
    padding: 10px 5px 2px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    h1 {
      span {
        opacity: .25;
      }
    }
    .buttons {
      margin-left: auto;
      display: flex;
      gap: 5px;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      .icon-wrapper,
      .icon-text-wrapper {
        border-radius: 5px;
        width: 24px;
        height: 28px;
        &.add {
          background: var(--grey-main);
          transition: background-color .25s;
          &:hover {
            background: var(--blue-main);
          }
        }
      }
      .icon-text-wrapper {
        width: auto;
        padding: 0 5px;
        .icon-wrapper {
          margin-right: 0;
        }
        .text {
          color: white;
        }
        svg {
          color: white;
        }

      }
    }
  }
}
