.reply {
  display: flex;

  > .icon-text-wrapper {
    opacity: 0.5;
    margin-right: 5px;
    .text {
      padding-left: 0;
    }
  }

  .time-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid rgb(88, 88, 88);
    border-radius: 5px;
    color: rgb(88, 88, 88);
  }

  .time-counter.priority-1 {
    color: rgba(237, 181, 36, 1);
    border-color: rgba(237, 181, 36, 1);
  }

  .time-counter.priority-2 {
    color: #ED7575;
    border-color: #ED7575;
  }

  .time-counter.priority-3 {
    color: #ED7575;
    border-color: #ED7575;
  }
}
