.stats-report-dashboard {
  .exit-fullscreen {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 5px 15px;
    .icon-wrapper {
      display: block;
    }
  }
  .header {
    padding: 10px 15px 2px;
  }

  .stats-widget-wrapper {
    .splits {
      .split-row {
        .split {
          h2 {
            font-size: 14px !important;
            line-height: 21px !important;
            padding: 3px 8px !important;
            font-weight: lighter;
            background: var(--flex-grey1501);
            text-align: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
