@import "../../../styles/partials/mixins";

.voice-internals {
  padding: 0;
  .voice-internal {
    pointer-events: initial;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 5px;
    @include tabStyle;
    .callerid {
      min-width: 80px;
      max-width: 100px;
      margin-right: 15px;
      font-size: 14px;
      line-height: 21px;
      @include ellipsis;
    }
    .timer {
      margin-right: 10px;
      font-size: 10px;
    }
    .hangup {
      margin-right: 0;
      cursor: pointer;
      .icon-wrapper {
        padding: 5px;
        border-radius: 2px;
        background-color: var(--red-main);
        svg {
          color: #FFFFFF !important;
        }
      }
    }
    > div {
      margin-right: 5px;
    }
  }
}
