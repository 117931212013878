@import "../styles/partials/mixins";

.search-container {
  @include container();

  flex-direction: row;

  > .search-left {
    flex: 1;
    border-right: 1px solid var(--COLOR-E8ECEE);

    >.search-left-list {
      padding: 16px 0;
    }
  }

  >.inbox-right {
    flex: 5;
    overflow-x: hidden;
  }
}
