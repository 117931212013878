@import "../../../styles/partials/mixins";

.conversation-messages-view {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;

  .conversation-messages-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 10px;
    border-left: 1px solid var(--COLOR-F7F7F9);
    box-shadow: 0 1px 0 var(--COLOR-E8ECEE);
    align-items: center;
    min-width: 320px;

    >.title {
      @include title();

      margin-left: 8px;
      color: black;
    }

    >.date {
      margin-left: 10px;
      font-weight: bold;
      font-size: 10px;
      color: #00053380;
    }

    >.status {
      margin-left: auto;
      color: #A8B1CA;
    }

    >.more {
      margin-left: 10px;

      svg {
        color: #000533;
      }

      @include clickable();
    }

    >.close {
      color: #000533;
      margin-left: 10px;

      svg {
        color: #000533;
      }

      @include clickable();
    }
  }

  .conversation-messages-timeline {
    overflow-y: auto;
    padding: 30px;

    .ant-timeline-item {
      padding-bottom: 10px;
      &:first-child {
        >.ant-timeline-item-tail {
          top: 25px;
          height: calc(100% - 24px);
        }
      }

      .ant-timeline-item-tail {
        top: 0;
        height: 100%;
      }

      .ant-timeline-item-head {
        padding-top: 45px;
      }

      .ant-timeline-item-content {
        margin-left: 35px;

        .conversation-message {

        }
      }

      &:last-child {
        >.ant-timeline-item-tail {
          top: 0;
          height: 25px;
        }
      }

      &:first-child {
        &:last-child {
          >.ant-timeline-item-tail {
            display: none;
          }
        }
      }
    }
  }
}
