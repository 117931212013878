.conversation-contact-suggestion-wrapper {
  padding: 10px 70px;

  .conversation-select-contact,
  .conversation-select-suggestion-loading,
  .conversation-select-suggestion {
    padding: 15px 20px;
    border: 1px solid var(--orange-dark);
    border-radius: 4px;
    font-size: 14px;

    .ant-btn {
      margin-left: auto;
      font-size: 12px;
      font-weight: bold;
      border-radius: 5px;

      &:not(.ant-btn-primary) {
        background: white !important;
        color: var(--blue-main) !important;

        span {
          color: var(--blue-main) !important;
        }
      }

      &.ant-btn-primary {
        margin-left: 20px;
      }
    }

    .icon-wrapper {
      svg {
        color: var(--orange-dark);
        margin-right: 8px;
      }
    }

    .icon-text-wrapper {
      font-weight: bold;
      color: var(--COLOR-000533);
    }
  }

  .conversation-select-contact,
  .conversation-select-suggestion-loading {
    display: flex;
    align-items: center;
    justify-items: center;
    background: var(--orange-light);

    .ant-typography {
      color: black;
    }
  }

  .conversation-select-suggestion {
    .conversation-contact-suggestions {
      padding: 5px 10px;
      display: flex;
      align-items: flex-start;

      .conversation-contact-suggestion {
        flex: 1;
        border: 1px solid var(--orange-light);
        padding: 15px;
        margin: 5px;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
        }

        display: flex;
        flex-direction: column;

        .ant-typography {
          label {
            opacity: 0.7;
          }

          margin-bottom: 3px;
          font-size: 13px;
          display: flex;
          align-items: center;

          .ant-tag {
            margin-left: 5px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
