.search-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;

  &.searching {
    .search-icon {
      opacity: 0 !important;
    }
    .searching-wrapper {
      opacity: 1;
    }
  }

  .searching-wrapper {
    .loader {
      width: 15px;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 2px solid;
      border-color: #000 #0000;
      animation: l1 1s infinite;
    }
    @keyframes l1 {to{transform: rotate(.5turn)}}
    position: absolute;
    right: 15px;
    opacity: 0;
    transition: opacity .3s;
  }

  .search-content {
    background-color: var(--COLOR-FDFDFD);
    border-radius: 44px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    position: relative;


    .search-input {

      display: flex;
      width: 100%;

      .search-icon-wrapper {
        height: 100%;
        width: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--color);
        padding-right: 6px;
        cursor: pointer;

        .search-icon {
          opacity: 1;
          transition: opacity .3s;
        }

      }

      input {
        flex-grow: 1;
        border: 0;
        background: transparent;
        border-radius: 44px 0 0 44px;
        height: 100%;
        outline: none;
        padding-left: 12px;
        font-size: 16px;
        color: var(--color);
      }

      input::placeholder {
        color: var(--COLOR-000533);
        opacity: 0.8;
      }

    }

    .search-results-popover {
      width: 100%;
      height: auto;
      min-height: 50px;
      position: absolute;
      top: 100%;
      right: 0;
      display: none;
      padding: 5px 8px;
      z-index: 91;
      flex-direction: column;

      &.active {
        display: flex;
      }


      .return-to-send-wrp {
        width: 100%;
        // height: 40px;
        display: none;
        justify-content: flex-end;
        align-items: center;
        font-size: 8px;
        font-weight: 700;
        color: var(--COLOR-808299);
        line-height: 20px;
        cursor: pointer;
        text-transform: uppercase;

        span {
          color: var(--color);
          margin-right: 3px;
        }
      }

      .search-results-sections {
        width: 100%;
        min-height: 20px;
        border-radius: 4px;
        background: var(--COLOR-FDFDFD);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        margin-top: 5px;

        .search-header {
          width: 100%;
          height: auto;
          padding: 7px 16px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--flex-grey1003);

          .search-types {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            li {
              margin: 8px 8px 8px 0;
              padding: 4px 8px;
              border-radius: 40px;
              font-size: 12px;
              line-height: 20px;
              min-width: 40px;
              cursor: pointer;
              text-align: center;
              font-weight: 400;
              box-sizing: border-box;
              background: #E8ECEE;
              color: #000000;
              transition: background-color 0.25s;
            }

            li.active {
              background-color: var(--blue-main);
              color: #FFFFFF;
            }
          }
        }

        .search-results {
          width: 100%;
          line-height: 20px;
          display: flex;
          flex-direction: column;
          padding: 8px 3px;

        }
      }
    }


  }

  .start-typing {
    text-align: center;
    padding: 5px;
    opacity: 0.5;
  }

  .search-result-overlay {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 80;
    background: transparent;
    width: 100%;
    height: 100vh;
    display: none;
  }

  .search-result-overlay.active {
    display: flex;
  }


}

@media screen and (max-width: 1280px) {
  .header-search-wrp {
    padding: 0 4px;
  }
}

.master-wrp.dark .searching-wrapper .loader {
  border-color: white #0000 !important;
  opacity: 1 !important;
}
