@import "../../styles/partials/mixins";

.form {
  background-color: transparent;

  >.ant-form {
    border: none !important;
  }

  .preview-value {
    padding: 3px 10px;
    background: var(--flex-grey1501);
    display: inline-block;
    border-radius: 3px;
    cursor: not-allowed;
    opacity: .7;
    transition: opacity .25s;
    &:hover {
      opacity: 1;
    }
  }
}

/*
.ant-form-section {
  > .ant-form-item-control {
    display: table;
    > .ant-row {
      display: table-row;
      > .ant-col {
        display: table-cell;
        &.ant-form-item-control {
          width: 90%;
        }
      }
    }
  }
}
 */

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-inline {
  .ant-form-item {
    .ant-form-item-label {
    }
  }
}

.ant-form {
  .ant-form-section {
    > .legend {
      padding: 3px 10px;
      background: var(--flex-grey1003);
      color: var(--COLOR-000533);
      font-weight: bold;
      text-transform: uppercase;
      margin: 10px -8px 5px;
      opacity: .7;
    }
    > .ant-form-item-control {
      > .ant-row {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  // for rules options vertical
  .ant-form-list-item-form .ant-row {
    width: 100%;
  }
  .advanced_options {
    color: var(--blue-main);
    border-top: 1px solid var(--flex-grey1002);
    display: block;
    margin: 30px 0 10px;
    padding: 10px 5px;
    cursor: pointer;
  }
}

.ant-form {
  text-align: left;

  h2.ant-typography {
    margin-bottom: 15px !important;
  }

  .ant-form-item {
    .ant-form-item-label {
      line-height: 34px;
      text-align: left;
    }
  }

  .form-item-select-modal-add {
    width: 22px;
    display: flex;
    justify-content: right;
    justify-items: center;
    align-items: center;
    align-content: center;
    opacity: .7;
    transition: opacity .25s;
    &:hover {
      opacity: 1;
    }
  }

  &.ant-form-section {
    width: 100%;
  }

  .select-wrapper {
    width: 100%;
    position: relative;
    &:hover {
      .select-all {
        opacity: 1;
      }
    }
    .select-all {
      border: 1px solid var(--blue-main);
      display: inline-block;
      padding: 1px 5px;
      background: var(--blue-main);
      color: white;
      position: absolute;
      font-size: 10px;
      border-radius: 4px;
      right: 6px;
      top: 50%;
      margin-top: -10px;
      opacity: 0;
      transition: opacity .5s;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  &.compact > .ant-form-item {
    margin-bottom: 3px;

    & > .ant-form-item-label {
      width: 90px;

      & > label {
        width: 80px;
      }
    }
  }

  .button-cancel {
    margin-right: 5px;
  }
}

.form-submit {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: flex-end;
    justify-content: right;
  }
}

.ant-form-vertical .form-item .ant-form-item-label {
  padding-top: 3px;
  padding-bottom: 1px;
  line-height: 18px;
  label {
    height: 18px;
  }
}

.ant-form-inline {
  > .ant-form-item-control {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    > .ant-form-item {
      margin-right: 15px;
      max-width: 100%;

      .ant-form-inline {
        padding: 0;
      }
    }
  }
}

.ant-form-horizontal > .ant-form-inline > .section-label,
.ant-form-horizontal > .ant-col > .form-item > .ant-form-item-label,
.ant-form-horizontal > .ant-col > .form-item > .ant-row > .ant-form-item-label,
.ant-form-horizontal > .ant-col > .ant-form > .ant-form-item-label,
.ant-form-horizontal > .ant-col > .ant-form > .ant-row > .ant-form-item-label {
  text-align: left;

  & > label {
    & > .text {
      width: 150px;
      line-height: 30px;

      @include ellipsis;
    }

    &.ant-form-item-required {
      &::after {
        position: absolute;
        right: 0;
      }
    }
  }
}

.ant-form-horizontal > .ant-form-inline > .section-label,
.ant-form-horizontal > .ant-col > .form-item > .ant-form-item-label,
.ant-form-horizontal > .ant-col > .ant-form > .ant-form-item-label {
  text-align: left;

  & > label {
    & > .text {
      width: 150px;
      line-height: 30px;

      @include ellipsis;
    }

    &.ant-form-item-required {
      &::after {
        position: absolute;
        right: 0;
      }
    }
  }
}

.ant-form-element-voice_settings > .ant-col > .ant-row > .ant-col > label > .text {
  width: 207px;
}

/*
.ant-form-inline > .ant-col > .ant-row > .ant-col > label > .after {
  display: none;
  &.required {
    display: block;
  }
}
 */

.ant-form-item-label {
  padding-right: 8px;

  > label {
    color: var(--COLOR-000533);
    font-size: 12px;
    height: 26px;

    &.ant-form-item-required {
      &::before {
        display: none;
      }

      &::after {
        display: inline-block;
        margin-right: 6px;
        color: #FF4D4F;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 32px;
        content: "*";
      }
    }

    &::after {
      display: none;
    }

    .after {
      display: inline-block;
      font-size: 18px;
      line-height: 32px;
      content: "*";
      opacity: 0;
      padding: 0 0 0 5px;
    }

    .required {
      content: "*";
      color: #FF4D4F;
      opacity: 1;
    }
  }
}

.ant-form-inline > .ant-col > .ant-row > .ant-col > label > .after:not(.required) {
  display: none;
}

.form-item {
  .ant-select-selector {
    min-width: 120px;
  }

  .form-list-wrapper {
    .drop-element {
      border: 1px dashed var(--flex-grey1003);
      padding: 10px;
      margin: 3px;
      text-align: center;
      text-transform: uppercase;
      background: var(--flex-grey1751);
      color: var(--COLOR-000533);
    }

    .ant-form-row-add {
      padding: 10px 0 3px;
    }

    &.form-list-wrapper-inline {
      display: flex;
      & > .ant-form-list-item,
      & > .ant-form-item {
        justify-items: center;
        justify-content: center;
        align-items: center;
      }
      & > .ant-form-list-item {
        margin-right: 10px;
      }
      & > .drop-element {
        padding: 4px 10px;
        margin: 6px 5px;
      }
    }

    .ant-form-list-item {
      position: relative;
      display: flex;
      align-items: flex-start;

      &.ant-form-list-item-section {
        border-bottom: 1px solid var(--COLOR-E8ECEE);
      }

      .ant-form-list-item-form {
        flex-grow: 1;
        width: calc(100% - 41px);
        padding: 5px 0;
      }

      &.first-item {

      }

      .ant-form-list-item-form-handles {
        margin-top: 14px;
        display: flex;
        gap: 8px;
        flex: 0 0;
        padding: 0 1px 0 4px;
        .ant-form-list-item-form-remove-handle,
        .ant-form-list-item-form-sort-handle {
          color: var(--COLOR-00053380);
          opacity: 0.7;
          transition: opacity 0.2s;
          cursor: pointer;
          flex: 0 0;

          &:hover {
            opacity: 1;
          }
        }
        .ant-form-list-item-form-sort-handle {
          cursor: move;
        }
      }

    }
  }
}

.form-nullable {
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    >button {
      margin-right: 7px;
    }
  }
}

.form-item-elm-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.form-nullable > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
  .form-item-elm-wrapper {
    width: calc(100% - 51px);
  }
}

.form-item-vertical {
  @extend .form-item;

  flex-direction: column;
}

.form-item-horizontal {
  @extend .form-item;

  flex-direction: row-reverse !important;
  justify-content: flex-end;
  flex-grow: 0;

  .ant-form-item-control {
    flex-grow: 0;
  }
}

.ant-input-number.ant-input-number-disabled,
.ant-input.ant-input-disabled,
.ant-radio.ant-radio-disabled .ant-radio-inner,
.ant-checkbox-disabled .ant-checkbox-inner,
.ant-switch.ant-switch-disabled {
  background: var(--flex-grey1001) !important;
}

.ant-input,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-input-affix-wrapper .ant-input,
.quill.editor {
  background: var(--flex-grey150025) !important;
  color: var(--COLOR-000533) !important;
  border: 1px solid var(--flex-grey1003) !important;
  border-radius: 3px !important;

  &::placeholder {
    color: #777777;
  }

  > .ant-select-selector {
    border: none !important;
    background: transparent !important;
  }

  &.ant-picker {
    .ant-picker-input {
      input {
        color: var(--COLOR-000533) !important;
      }
    }
  }
}

.ant-select-selection-placeholder {
  color: #777777;
}

.ant-form-item {
  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-input-affix-wrapper {
          padding: 0;
          border: 0;
          position: relative;
          background: transparent;

          .ant-input {
            padding: 4px 11px !important;
          }

          .ant-input-suffix {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            padding: 0 7px;

            svg {
              color: var(--COLOR-000533) !important;
              opacity: 0.5;
              transition: opacity 0.25s;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.ant-select,
.ant-picker {
  .ant-select-arrow,
  .ant-picker-suffix,
  .ant-picker-clear {
    color: var(--COLOR-000533);
    opacity: 0.5;
    background: transparent !important;
  }

  .ant-picker-clear {
    opacity: 1;
  }
}

.ant-form-item-explain,
.ant-form-item-extra {
  color: #888888;
  font-size: 12px;
  padding-bottom: 5px;
}

.ant-select-multiple .ant-select-selection-item {
  background: var(--flex-grey1751);
  border-color: var(--flex-grey1751);
  .ant-select-selection-item-edit {
    opacity: .75;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.forms-switch {
  background-color: rgba(1, 1, 1, 0.01) !important;
  border: 1px solid rgba(150, 150, 150, 0.2) !important;
  border-radius: 24px;
  height: 26px;

  &.ant-switch-checked {
    background: var(--blue-main) !important;
  }

  >.ant-switch-handle {
    top: 3px;

    &::before {
      background-color: var(--COLOR-E8ECEE);
    }
  }
}

.forms-input-file {
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  .audio-player-wrapper {
    margin-left: 5px;
  }
}

.form-preview {
  margin-left: 5px;
  display: flex;
}

.ant-switch-checked {
  background-color: var(--COLOR-39C6A2);

  >.ant-switch-handle {
    &::before {
      background-color: white;
    }
  }
}

.form-cb {
  .ant-checkbox-inner {
    border: 1px solid #C0CCF2;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }
}

.ant-radio-group.ant-radio-group-outline {
  .ant-radio-button-wrapper {
    color: var(--COLOR-000533) !important;
    background: rgba(150, 150, 150, 0.05);
    border-color: var(--COLOR-0005334D) !important;

    &.ant-radio-button-wrapper-checked {
      background: rgba(150, 150, 150, 0.2);
    }

    &::before {
      background: var(--COLOR-0005334D) !important;
      display: none;
    }
  }
}

.entity-form-edit,
.entity-form-add {
  padding: 15px;
}

.ant-form-text {
  color: var(--COLOR-000533);
  opacity: 0.85;
  font-size: 12px;
}

.sketch-picker {
  input {
    color: #000000;
    width: 100% !important;
    padding: 3px 4px !important;
  }
}

.color-picker-popover {
  min-width: 180px;
  border-radius: 3px;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.ant-form {

  position: relative;

  .ant-btn-primary {
    margin-top: 10px;
  }

  .color-picker-btn {
    width: 24px;
    height: 24px;
    border: 1px solid var(--flex-grey1004);
    border-radius: 2px;
  }

  .form-typeahead-edit-popup {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: var(--BG-FFFFFF);
      opacity: .75;
    }
    .inner {
      position: fixed;
      width: 600px;
      background: var(--BG-FFFFFF);
      top: 10vh;
      left: 50%;
      margin-left: -300px;
      box-shadow: 0 0 30px rgba(0,0,0,.3);

      > .ant-form {
        overflow: auto;
        max-height: calc(75vh);
        padding: 20px;
      }

      > .ant-btn {
        width: 100%;
        text-transform: uppercase;
        border-radius: 0;
      }
    }
  }

  .upload-single {
    display: flex;
    align-items: center;

    .ant-upload-list {
      .ant-upload-list-text-container {
        display: none;

        &:last-child {
          display: block;
        }
      }
    }
  }

  .ant-upload-select {
    margin-right: 5px;

    .icon-text-wrapper {
      display: flex;
    }
  }

  .ant-upload-list {
    .ant-upload-list-text-container {
      .ant-upload-list-item {
        height: auto;
        margin-top: 0;

        .ant-upload-list-item-info {
          padding: 3px 4px;
          font-size: 12px;
          opacity: 0.75;
          transition: opacity 0.25s;

          &:hover {
            background: transparent;
            opacity: 1;
          }

          .ant-upload-list-item-card-actions {
            .ant-btn {
              opacity: 1;
              border: 0 !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }

    color: var(--COLOR-000533);

    .ant-upload-text-icon svg {
      color: var(--COLOR-000533);
      opacity: 0.5;
    }
  }
}

.ant-form-element-settings_nested_metrics {
  .ant-form-element-settings_nested_metrics_metrics {
    margin-right: 0 !important;
    width: auto !important;
  }
  .select-wrapper {
    max-width: 159px;
    .ant-select-selector {
      padding-right: 0 !important;
    }
  }
}

.ant-form-element-metrics_seconds_threshold,
.ant-form-element-settings_metrics_seconds_threshold {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    .postfix {
      padding-left: 8px;
    }
  }
}

.ant-form-text {
  &.postfix,
  &.prefix {
    opacity: .7;
    padding-left: 8px;
    padding-right: 8px;
  }
  &.postfix {
    padding-right: 0;
  }
  &.prefix {
    padding-left: 0;
  }
}

.ant-form .list-delimiter {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: -14px;
  margin-left: 10px;
  background: var(--BG-FFFFFF);
  padding: 5px;
  width: 26px;
  line-height: 16px;
  text-align: center;
  color: var(--blue-main);
}

.form-list-wrapper {
  &.form-list-wrapper-vertical,
  &.form-list-wrapper-inline {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    .list-delimiter {
      top: 50%;
      left: -24px;
      margin-top: -12px;
    }
    .ant-form-list-item {
      border-left: 1px solid var(--COLOR-E8ECEE);
      border-top: 1px solid var(--COLOR-E8ECEE);
      padding: 5px 15px;
      &:hover {
        .ant-form-list-item-form-handles {
          opacity: .75;
        }
      }
      .ant-form-list-item-form-handles {
        top: 0 !important;
        margin-top: 0 !important;
        background-color: var(--BG-FFFFFF);
        padding: 2px 3px;
        border: 1px solid var(--COLOR-E8ECEE);
        right: 0 !important;
        opacity: .1;
        transition: opacity .25s;
        &:hover {
          opacity: 1;
        }
      }
    }
    .ant-form-row-add {
      border-left: 1px solid var(--COLOR-E8ECEE);
      padding: 10px;
    }
  }
  &.form-list-wrapper-vertical {
    & > .ant-form-list-item > .ant-form-list-item-form-handles {
      position: absolute;
      top: 5px;
      right: 8px;
      z-index: 1;
    }
  }
}

.ant-form-list-group-labels {
  .ant-form-item-label {
  }
}

.ant-select-disabled,
.ant-select-item-option-disabled,
.custom-form-component-disabled,
.ant-btn[disabled] {
  background: rgba(200,200,200,.2);
  color: #bfbfbf;
  * {
    color: #bfbfbf !important;
  }
}

.ant-form-element-voice_tariff_list .form-list-wrapper {
  .ant-form-list-item-section {
    border-top: 1px solid var(--COLOR-E8ECEE);
  }
  .ant-form-list-item-section:first-child {
    border-top-width: 0 !important;
  }
  .ant-form-list-item-section:first-child .ant-form-list-item-form .ant-form {
    padding-top: 1px !important;
  }
  .ant-form-list-item-section .ant-form-list-item-form .ant-form {
    padding-bottom: 0 !important;
  }
  .ant-form-list-item-section {
    border-bottom-width: 0 !important;
  }
  .ant-form-row-add {
    padding-top: 0 !important;
  }
}

.ant-form-item-control-input-content textarea {
  margin-bottom: 3px;
}

.ant-form-item {
  textarea {
    margin: 1px 0 3px;
  }
}
