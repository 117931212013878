@import "../../../styles/partials/mixins";

.user-menu-content {
  >.header {
    &:hover {
      .camera {
        opacity: 1 !important;
      }
    }

    .ant-upload {
      > .ant-upload {
        position: relative;
        display: block;
        cursor: pointer;

        .camera {
          position: absolute;
          bottom: -3px;
          right: -3px;
          background: var(--COLOR-000533);

          svg {
            color: var(--COLOR-FFFFFF);
          }

          padding: 3px;
          opacity: 0;
          transition: opacity 0.2s;
        }
      }
    }

    > .avatar {
      cursor: pointer;
    }

    > .title {
      margin-right: auto;
      margin-left: 7px;
      display: flex;
      flex-direction: column;

      > .roles {
        color: var(--COLOR-E8ECEE);
        font-size: 10px;
      }
    }
  }

  > .agentprofiles {
    flex: 1;

    .agentprofile {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      padding: 10px 12px;
      transition: background-color 0.25s;

      &:hover {
        background: var(--flex-grey1001);
      }

      &.selected {
        > .text {
          > .title {
            font-weight: bold;
          }
        }
      }

      > .radio {

      }

      > .text {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        width: 90%;

        > .title {
          font-weight: normal;
          @include ellipsis;
        }

        > .subtitle {
          @include ellipsis;
        }
      }
    }
  }
}
