.stats-widget-wrapper {
  width: 100%;
  height: 100%;
  background: var(--BG-WIDGET);
  padding: 8px;
  margin-bottom: 10px;
  position: relative;
  overflow: auto;
  border-radius: 8px;

  min-height: 130px;

  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 32px;
    bottom: 0;
    z-index: 1;
    background: rgba(255,255,255,.05);
    overflow: hidden;
  }

  .pick-custom-period {
    position: absolute;
    left: 0;
    right: 0;
    top: 32px;
    bottom: 0;
    z-index: 9;
    background: rgba(255,255,255,1);
    overflow: hidden;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    table {
      width: 300px;
    }
  }

  .no-data {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    word-spacing: 5px;
    opacity: .3;
    font-size: 14px;
  }

  &.not-found {
    border: 2px dashed var(--red-main);
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      display: block;
      color: var(--red-main);
    }
    p {
      text-align: center;
      margin-bottom: 0;
      opacity: .5;
      transition: opacity .25s;
      font-size: 12px;
    }
    .buttons {
      margin: 8px 0 10px;
      .icon-text-wrapper {
        background: var(--red-main);
        padding: 3px 10px 2px;
        border-radius: 5px;
        svg, .text {
          color: white;
        }
      }
    }

    &:hover {
      p {
        opacity: 1;
      }
    }
  }

  h3.title {
    margin-bottom: 4px;
    transition: opacity .25s;
    span {
      font-weight: lighter;
      opacity: .4;
      padding-left: 7px;
      margin-left: 7px;
      border-left: 1px solid var(--flex-grey1004);
    }
  }

  &:hover {
    & > .hover-top {
      top: 0;
      opacity: 1;
    }
    & > .title {
      opacity: 0;
    }
  }

  & > .hover-top {
    position: absolute;
    top: -20px;
    opacity: 0;
    left: 0;
    right: 0;
    transition: all .25s;
    overflow: hidden;
    font-size: 12px;
    border-bottom: 1px solid var(--flex-grey1501);
    height: 32px;
    display: flex;
    justify-items: left;
    justify-content: left;
    align-items: center;
    padding: 0 6px;

    & > .infos,
    & > .buttons {
      display: flex;
      transition: opacity .25s;

      & > .button,
      & > .info {
        opacity: .5;
        padding: 7px 2px;
        cursor: pointer;
        &:hover {
          opacity: 1;
          transition: opacity .25s;
        }
      }
    }

    & > .infos {
    }

    & > .buttons {
      margin-left: auto;
    }

  }

  .metric {
    > div {
      padding: 1px 0;
      display: flex;
      .trend {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        .value {
          font-size: 80%;
          padding: 1px 3px;
        }
      }

      /*
      &.trend-indicator-arrow {
        .trend {
          .arrow {
            line-height: 18px;
          }
        }
        &.trend-unchanged {
          .trend {
            .arrow {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }

       */


      &.trend-indicator-background {
        &.trend-positive {
          &.trend-up {
            background: var(--green-main);
          }
          &.trend-down {
            background: var(--red-main);
          }
        }
        &.trend-negative {
          &.trend-down {
            background: var(--green-main);
          }
          &.trend-up {
            background: var(--red-main);
          }
        }
      }

      &.trend-unchanged {
        .trend {
          color: var(--grey-main);
          svg {
            color: var(--grey-main);
          }
        }
      }

      &.trend-positive {
        &.trend-down {
          .trend {
            color: var(--red-main);
            svg {
              color: var(--red-main);
            }
          }
        }
        &.trend-up {
          .trend {
            color: var(--green-main);
            svg {
              color: var(--green-main);
            }
          }
        }
      }

      &.trend-negative {
        &.trend-up {
          .trend {
            color: var(--red-main);
            svg {
              color: var(--red-main);
            }
          }
        }
        &.trend-down {
          .trend {
            color: var(--green-main);
            svg {
              color: var(--green-main);
            }
          }
        }
      }

      .inner {
        display: flex;
        .value {
          white-space: nowrap;
          opacity: .9;
        }
        &:hover {
          opacity: 1;
        }
        .trend {
          font-size: 80%;
          display: flex;
          margin-left: 3px;
          .arrow {
            margin-left: 3px;
          }
        }
      }
    }
  }

  .status-info {
    .info {
      .display-phone {
        flex-shrink: 0;
      }
      display: flex;
      .queue {
        font-size: 10px;
        max-width: 70px;
      }
    }
  }

}
