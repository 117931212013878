#document-viewer {

  position: absolute;
  z-index: 999999;
  background-color: rgba(0,0,0,.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 20px;

  #document-viewer-header {
    width: 100%;
    background-color: var(--flex-grey1001);
    color: #000533;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    svg {
      color: #000533;
    }
    .filename {
      opacity: .8;
      margin-right: 10px;
    }
    .controls {
      margin-left: auto;
      display: flex;
      gap: 10px;
    }
  }

  #document-viewer-body {
    background-color: white;
    width: 100%;
    max-width: 950px;
    z-index: 9;
    display: flex;
    flex-direction: column;
  }

  #react-doc-viewer {

    overflow: hidden auto;


    #header-bar {
      display: none;
    }

    #proxy-renderer {
      color: black;
      #pdf-controls {
        box-shadow: none;
        background-color: transparent;
        left: auto !important;
        right: 0;
        opacity: .6;
        transition: opacity .25s;
        padding: 2px 5px;
        &:hover {
          opacity: 1;
        }
        & > button[disabled] {
          opacity: .25 !important;
          &:hover {
            opacity: .25 !important;
          }
        }
        & > button,
        & > a {
          width: 24px;
          height: 24px;
          box-shadow: none;
          background-color: transparent !important;
          opacity: .6;
          transition: opacity .25s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  #document-viewer-bg {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  #document-viewer-right {
    z-index: 1;
    margin-bottom: auto;
    #document-viewer-close {
      background-color: var(--BG-FFFFFF);
      z-index: 1;
      width: 40px;
      height: 40px;
      cursor: pointer;
      color: white;
      display: flex;
      justify-items: center;
      justify-content: center;
    }
    #document-viewer-file-list {
      margin-top: 0;
      ul {
        list-style-type: none;
        margin: 5px 0 0;
        padding: 5px 0;
        background-color: var(--BG-FFFFFF);
        color: var(--color);
        li {
          font-size: 11px;
          padding: 4px 8px;
          cursor: pointer;
          transition: background-color .25s;
          background-color: var(--flex-grey1001);
          &.active {
            background-color: white;
            color: #000533;
            &:hover {
              background-color: white;
            }
          }
          &:hover {
            background-color: var(--flex-grey1003);
          }
        }
      }
    }
  }


}
