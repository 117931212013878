@import "../../../../../styles/partials/mixins";

.stats-widget-wrapper {
  display: flex;
  flex-direction: column;
  h1 {
    flex-grow: 0;
  }
  .widget-type-text {
    display: flex;
    height: 100%;
    width: 100%;
    .metric-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-around;
      gap: 5px;
      .row {
        display: flex;
        flex-grow: 0;
        justify-content: space-around;
        gap: 5px;
        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
          border-radius: 5px;
          .title {
            position: absolute;
            top: 0;
            left: 2px;
            right: 2px;
            .text {
              @include ellipsis;
            }
          }
          .metric {
            width: 100%;
            display: flex;
            justify-content: center;
            .alignment {
              display: flex;
              border-radius: 10px;
            }
            .data .value {
              border-radius: 10px;
              padding: 4px 5px 5px;
              margin-top: 12px;
            }
            .font-size.trend {
              flex-grow: 0;
              height: auto;
              margin-top: 12px;
              .trend {
                .icon-wrapper {
                }
                .value {
                  padding: 1px 3px;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
