@import "../../../styles/partials/mixins";

.header-user-status {
  height: 100%;
  width: 130px;
  display: flex;
  align-items: center;

  .pause-time-switch-wrp .ant-switch-checked {
    background-color: var(--red-main) !important;
  }

  .box {
    cursor: pointer;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--COLOR-FDFDFD);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    position: relative;

    .chevron {
      height: 100%;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .pause-action-wrp {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    cursor: pointer;
    min-width: 60%;

    &.paused {
      .status-color {
        display: none;
      }
    }

    >.pause-time-switch-wrp {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        margin-right: 3px;
      }

      .status-time {
        width: 100%;
        font-size: 8px;
        display: flex;
        align-items: center;
      }
    }

    .status-title {
      color: var(--COLOR-000533);
      font-size: 14px;
      width: 70px;
      text-align: left;
      display: flex;
      align-items: center;

      .channels {
        margin-right: 5px;
      }

      .status {
        @include ellipsis;
      }
    }
  }
}
