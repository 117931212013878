.customer-journey-wrapper {
  width: 400px;
  padding: 28px 20px 28px 24px;

  .head {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      color: var(--color);
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 2px;
    }

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      span {
        color: var(--COLOR-000533);
        opacity: 0.5;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: block;

        &.circle {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          margin: 0 6px;
          background: var(--COLOR-000533);
          opacity: 0.5;
        }
      }
    }
  }

  .customer-journies {
    .customer-journey-item {
      &:last-child {
        .ant-timeline-item-tail {
          display: none;
        }
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 3px;

    > .date {
      width: 100%;
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
      color: var(--COLOR-000533);
      opacity: 0.7;
      text-align: left;
      margin-bottom: 2px;
      padding-left: 2px;
    }

    > .body {
      width: 100%;
      background: var(--COLOR-FDFEFE);
      border: 1px solid var(--COLOR-E8ECEE);
      color: var(--COLOR-000533);
      border-radius: 4px;
      padding: 16px;
      min-height: 50px;
      display: flex;
      flex-direction: column;

      > .row {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 2px;
        overflow: hidden;

        .id-text {
          color: var(--COLOR-A8B1CA);
          font-weight: 300;
          font-size: 10px;
          line-height: 14px;
          opacity: 0.7;
        }

        .title {
          color: var(--COLOR-A8B1CA);
          font-weight: 300;
          font-size: 12px;
        }

        .status-text {
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          opacity: 0.7;
          margin-left: auto;
        }

        .disposition {
          background: rgba(102, 107, 211, 0.2) !important;
          border: 1px solid rgb(235, 178, 64);
          box-sizing: border-box;
          border-radius: 2px;
          padding: 8px;
          display: flex;
          flex-direction: column;
          margin: 16px 0;
          width: 100%;

          >.text {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: var(--COLOR-000533);
          }

          >.date {
            width: 100%;
            text-align: left;
            color: var(--COLOR-000533);
            opacity: 0.5;
            font-weight: bold;
            font-size: 8px;
            line-height: 11px;
            margin-top: 8px;
          }
        }

        .avatar {
          margin: 0;
          padding: 0;
          background: rgba(81, 99, 149, 1);
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          font-weight: 900;
          font-size: 7px;
          line-height: 8px;
          overflow: hidden;
          text-align: center;
          margin-right: 6px;

          >img {
            max-width: 100%;
            max-height: 100%;
            min-width: 100%;
            min-height: 100%;
            border-radius: 50%;
          }
        }

        .queue-title-wrp {
          .icon-text-wrapper {
            width: 100%;
          }

          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: rgba(128, 130, 153, 1);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;

          span {
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            color: rgba(128, 130, 153, 1);
          }
        }

        .view-btn {
          color: var(--blue-main);
          font-weight: bold;
          font-size: 8px;
          line-height: 11px;
          outline: none;
          background: transparent;
          border: 1px solid var(--blue-main);
          border-radius: 4px;
          text-align: center;
          padding: 2px 4px;
          cursor: pointer;
          white-space: nowrap;
          margin-left: auto;
        }
      }
    }
  }

  .load-more {
    width: 100%;
  }
}
