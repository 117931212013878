@import "../../../styles/partials/mixins";

.tab-maximize-wrapper {
  width: calc(100vw - 415px);
  height: 82vh;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 5, 51, 0.1);
  border: 2px solid var(--COLOR-E8ECEE);
  display: none;
  &.absolute {
    z-index: 1000;
    position: absolute;
    left: 5px;
    bottom: 35px;
  }
  &.visible {
    display: block;
  }
}

.tab-maximize-view {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  pointer-events: initial;
  height: 100%;

  >.body {
    flex-direction: row;
    display: flex;
    flex: 1;
    overflow: hidden;

    >.left {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: var(--COLOR-FDFEFE);

      >.sub-title {
        border-bottom: 1px dashed var(--COLOR-E8ECEE);
        display: flex;
        padding: 10px;
        justify-content: space-between;
        align-items: flex-end;
      }

      >.body-view {
        display: flex;
        flex-direction: row;
        overflow: hidden;

        >.list{

        }
      }
    }

    >.right {
      display: flex;
      flex-direction: column;
      background-color: var(--COLOR-F6F8F9);
      width: 400px;

      .conversation-messages-timeline {
        overflow-y: initial;
      }

      &.large {
        width: 40%;
        overflow: hidden;
      }

      >.customer-journey {
        background-color: transparent;
        border: none;
        margin: 0;
        margin-top: 10px;
      }

      >.secondary-list {
        padding: 0;
      }

      >hr {
        border-color: #6576A2;
        opacity: 0.3;
        width: 100%;
      }

      >.contact-container {
        padding: 35px;
        display: flex;
        flex-direction: column;

        >.contact-view {
          .title {
            @include title();

            color: var(--COLOR-000533);
            opacity: 1;
            margin-bottom: 15px;
          }
        }

        >.icons-container {
          display: flex;
          flex-direction: row;
          margin-left: 35px;
          margin-top: 10px;

          >.icon {
            width: 30px;
            height: 30px;
            border: 1px solid var(--COLOR-0005334D);
            border-radius: 5px;
            color: var(--COLOR-000533);
            background: var(--COLOR-FDFEFE);
            margin-right: 6px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1580px) {
  .tab-maximize-wrapper {
    width: 98vw;
  }
}
