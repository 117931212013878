.voice-call-controller {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;

  &.long {
    .voice-controller-button {
      margin: 0 4px;
    }
  }

  &.short {
    .voice-controller-button {
      > .ant-typography {
        display: none;
      }
    }
  }

  .voice-controller-buttons {
    display: flex;
    justify-items: center;
    justify-content: center;

    .voice-controller-button {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      justify-items: center;
      width: 40px;
      opacity: .5;

      &.active {
        opacity: 1;
        .icon-wrapper {
          cursor: pointer;
        }
      }

      &.voice-controller-button-recording {
        &.is-recording {
          .icon-wrapper {
            border-color: var(--red-main);
            background: var(--red-light);
            svg {
              color: var(--red-main);
            }
          }
        }
      }

      .icon-wrapper {
        background: var(--BG-FFFFFF);
        cursor: wait;
      }

      > .ant-typography {
        font-size: 8px;
        text-transform: uppercase;
        padding: 2px 0;
        text-align: center;
        transition: opacity 0.25s;
      }

      > .icon-wrapper,
      > .icon-text-wrapper {
        transition: box-shadow 0.2s;
        padding: 8px;
        border: 1px solid var(--flex-grey1501);
        box-shadow: 0 4px 12px var(--flex-grey1501);
        margin: 0 3px;
        height: 34px;
      }

      &.voice-controller-hangup {
        width: auto;

        .icon-wrapper,
        .icon-text-wrapper {
          background: var(--red-main);

          svg,
          .ant-typography {
            color: #FFFFFF;
            line-height: 16px;
          }
        }
      }
    }
  }
}
