.dialpad {
  width: 200px;
  display: flex;

  &.locked {
    .key-row .key {
      opacity: .5;
    }
  }

  .dialpad-contents {
    padding: 10px;
    width: 100%;
    background: var(--COLOR-FDFEFE);
    border-radius: 5px;
    position: relative;

    .keys-wrp {
      width: 100%;
      height: auto;
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .key-row {
        width: 100%;
        height: auto;
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .key {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: var(--flex-grey1751);
          color: var(--COLOR-000533);
          cursor: pointer;
          user-select: none;

          &:active {
            background-color: var(--flex-grey1004);
          }

          >.num {
            font-size: 20px;
            width: 100%;
            text-align: center;
            font-weight: 700;
            line-height: 20px;
            margin-top: 6px;
          }

          >.words {
            width: 100%;
            font-size: 10px;
            font-weight: 400;
            text-align: center;
            line-height: 20px;
            display: block;
            height: 20px;
          }
        }
      }
    }

  }
}
