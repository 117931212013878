#ai-assistant {
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  height: 100%;
  iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
}
