.user-settings-modal {
  h2 {
    font-size: 26px;
    font-weight: lighter;
    color: var(--color);
    opacity: 0.8;
  }

  .language-selector {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;

    .ant-typography {
      margin-bottom: 15px;
    }
  }

  .language-selector-select {
    width: 100%;
  }

  .ant-form {
    width: 100%;
  }

  .ant-modal-footer {
    display: none !important;
  }

  .language-selector-label {
    display: flex;
    gap: 8px;
  }

  .form-submit .ant-form-item-control-input-content {
    > button {
      padding: 5px 15px !important;
      border-radius: 2px !important;
    }

    .ant-btn-primary {
      margin-left: 5px;
    }
  }
}
