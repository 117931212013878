.create-conversation-form {
    max-width: 1200px;
    margin: 0 auto;

    .ant-form {
        line-height: 1.5;

        .ant-form-item {
            .ant-col {
                padding: 0;
            }

            // Hide all form labels visually but keep them for screen readers
            .ant-form-item-label {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;
            }

            // Remove the space that the label would normally take
            &.ant-form-item-has-feedback .ant-form-item-children-icon {
                margin-top: 0;
            }
        }

        .create-conversation-header {
            display: flex;
            gap: 10px;
            width: 100%;

            .organization-select,
            .main-channel-select,
            .mail-account-select,
            .source-select,
            .queue-select {
                flex: 1;
            }

            .mail-account-select {
                .ant-form-item-label {
                    height: 0;
                }
            }
        }

        .contact-section {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .contact-search-wrapper {
                display: flex;
                align-items: flex-start;
                gap: 8px;

                .contact-selector {
                    flex: 1;
                }

                .cc-bcc-buttons {
                    display: flex;
                    gap: 4px;
                    flex-shrink: 0;

                    .toggle-button {
                        padding: 4px 8px;
                        height: 32px;
                        min-width: fit-content;

                        &.active {
                            background-color: var(--ant-primary-color);
                            color: white;
                        }
                    }
                }
            }

            .add-contact-form {
                margin-top: 8px;

                .add-contact-form-fields {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 16px;
                    margin-bottom: 16px;

                    .add-contact-form-fields-left,
                    .add-contact-form-fields-right {
                        .ant-form-item {
                            margin-bottom: 16px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .ant-form-item-control {
                                line-height: 1;
                            }
                        }
                    }
                }

                .add-contact-buttons {
                    display: flex;
                    gap: 8px;

                    :nth-child(1) {
                        margin-top: 0; // Removes ant design default margin
                    }

                    .ant-form-item {
                        margin-bottom: 0;
                    }

                    button {
                        min-width: 80px;
                    }
                }
            }

            .contact-search-container {
                display: flex;
                align-items: center;
                gap: 10px;
                position: relative;

                .ant-input {
                    flex: 1;
                }

                .contact-options {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    background-color: white;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    padding: 4px;
                    z-index: 1000;
                }
            }

            .cc-bcc-fields {
                .ant-form-item-label {
                    height: 0;
                }
            }
        }

        .main-section {
            margin-bottom: 32px;

            .fr-element {
                height: 600px;
            }

            .fr-quick-insert {
                .fr-floating-btn {
                    position: relative;
                    left: 270px;
                }
            }

            .ql-editor {
                height: 450px;
            }

            .ant-form-item-label {
                height: 0;
            }
        }

        .bottom-section {
            display: flex;
            flex-direction: column;
            margin-top: 16px;

            .tags-priority-section {
                display: flex;
                gap: 8px;

                .ant-form-item:nth-child(1) {
                    min-width: 150px;
                }

                .priority-select {
                    width: 150px;
                }
            }
        }
    }
}

.phone-number {
    width: 100% !important;
    max-width: 300px !important;
}

.add-contact-button {
    margin: 0 auto;
}

.add-contact-error {
    color: red;
}

.send-button {
    display: flex;
    align-items: center;
    gap: 8px;
}