.ql-wrapper.froala-wrapper {

  .fr-toolbar {
    border: 0;
    border-radius: 0;
    background: rgb(238, 238, 238);

    .fr-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      background: transparent !important;

      svg {
        width: 18px;
        height: 18px;
        margin: 4px;
        color: #333333 !important;

        path {
          fill: #333333 !important;
        }
      }

      svg path {
        fill: var(--color);
      }

      &.fr-active {
        svg path {
          fill: var(--blue-main);
        }
      }

      &:hover {
        background: var(--flex-grey1001) !important;
      }
    }
  }

  .fr-element {
    p {
      margin: 1em 0;
    }
  }

  .fr-btn-grp {
    margin: 0 8px;
  }

  .fr-btn[id^=CustomImportButton_] {
    svg {
      margin: 4px 0 0 4px;
    }
  }

  .fr-expanded {
    height: 38px;
  }

  .fr-wrapper {
    border: 0;
    border-radius: 0;

    /*
    & > div:first-child {
      &:not(.fr-view) {
        display: none !important;
      }
    }

     */

    .mention {
      background: rgba(100, 100, 100, 0.1) !important;
      padding: 2px 5px;
      border-radius: 3px;
    }

    .fr-element {
      font-size: 12px;
      min-height: 140px;
      max-height: 450px;
      color: black;
    }

    .fr-placeholder {
      display: none !important;
    }
  }

  .fr-second-toolbar {
    display: flex;
    height: 44px;
    overflow: hidden;
    border: 0;
    border-radius: 0;
    background: rgb(246, 246, 246);

    /*
    #fr-logo {
      display: none;
    }

     */

    .fr-counter {
      float: left;
      margin-left: 5px;
      font-size: 12px;
      padding: 14px 5px;
      /*
      &:first-child {
        border-right: 1px solid var(--flex-grey1003);
      }

       */

      &.fr-chars-counter-error {
        color: #ff4d4f;
      }
    }
  }

  .editor-additional {
    .char-count {
      padding: 5px 10px;
      font-size: 12px;
      color: #666;

      .exceeded {
        color: #ff4d4f;
      }
    }
  }

  &.disable-rich-text {
    .fr-quick-insert {
      display: none !important;
    }
  }
}

.tribute-container {
  color: #000533;
}