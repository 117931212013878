@import "../../../styles/partials/mixins";

#menu-icon {
  line-height: 106px;
  color: var(--COLOR-000533) !important;
  padding-left: 5px;

  @include clickable;

  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
