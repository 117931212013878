@import "../../styles/partials/mixins";

.contact_custom_event-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;
  height: 100%;
  .contact_custom_event-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 10px;
    border-left: 1px solid var(--COLOR-F7F7F9);
    box-shadow: 0 1px 0 var(--COLOR-E8ECEE);
    align-items: center;
    min-width: 320px;

    >.title {
      @include title();

      margin-left: 8px;
      color: black;
    }

    >.date {
      margin-left: 10px;
      font-weight: bold;
      font-size: 10px;
      color: #00053380;
    }

    >.status {
      margin-left: auto;
      color: #A8B1CA;
    }

    >.more {
      margin-left: 10px;

      svg {
        color: #000533;
      }

      @include clickable();
    }

    >.close {
      color: #000533;

      svg {
        color: #000533;
      }

      @include clickable();
    }
  }

  .contact_custom_event-body {
    padding: 20px;
  }
}
