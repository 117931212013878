.conversation-disposition-select {
  display: flex;
  flex-direction: column;
  flex: 1;

  >.selector {
    border-radius: 5px;
    color: var(--COLOR-000533);
  }

  >.input {
    display: flex;
    margin-top: 8px;
    text-align: left;
    padding: 8px;
    flex: 1;
    border-radius: 5px;
  }

  .buttons {
    margin-top: 8px;
    display: flex;

    .ant-btn {
      border: none;
      flex: 1;
      border-radius: 3px;
      padding: 5px;
      background-color: var(--blue-main);
      color: white;
      cursor: pointer;
      font-size: 14px;

      &:last-child {
        flex: 2;
        margin-left: 5px;
      }
    }
  }
}

.tab-info-view {
  .conversation-disposition-select {
    padding: 0 15px 15px;
  }
}
