@import "../../../../styles/partials/mixins";

@function message-gradient($color) {
  @return linear-gradient(0deg, $color 0%, $color 35%, transparent 100%);
}

.conversation-messages-view .conversation-message {

  color: var(--COLOR-000533);

  &.inbound {
    margin-right: 10px;
    .conversation-message-content {
      background: var(--BG-FFFFFF);
      border: 1px solid var(--flex-grey1001);
      .more-actions {
        background: message-gradient(var(--BG-FFFFFF));
      }
    }

  }
  &.outbound {
    margin-left: 10px;
    .conversation-message-content {
      border: 1px solid var(--flex-grey1001);
      background: var(--COLOR-F6F8F9);
      .more-actions {
        background: message-gradient(var(--COLOR-F6F8F9));
      }
    }

  }

  &.highlighted {
    .conversation-message-content {
      box-shadow: 0 0 5px yellow;
    }
  }

  &.click-highlighted {
    .conversation-message-content {
      box-shadow: 0 0 3px yellow;
    }
  }

  .conversation-message-content {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    position: relative;
    overflow-x: auto;

    pre {
      white-space: pre-wrap;
    }

    >.head {
      color: var(--COLOR-000533);
      font-size: 11px;
      display: flex;
      align-content: center;
      align-items: center;

      > strong {
        white-space: nowrap;
      }

      > .info {
        display: flex;
        flex-direction: column;
        height: 100%;

        >.time {
          color: var(--color);
          font-weight: bold;
          text-align: left;
          font-size: 10px;
        }

        > .title {
          color: var(--color);
          font-size: 16px;
        }

        > .extras {
          display: flex;
          color: var(--COLOR-000533);
          opacity: 0.5;
          font-size: 10px;
          align-items: center;

          > .extras {
            display: flex;
          }

          .t {
            opacity: .6;
            padding-right: 3px;
            &:after {
              content: ':'
            }
            min-width: 33px;
            display: inline-block;
          }

          > .delimiter {
            width: 1px;
            height: 10px;
            background: var(--flex-grey1004);
          }

          >.rec-icon-wrp {
            margin-left: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--gray-color);

            svg,
            span {
              color: var(--gray-color);
            }
          }

          transition: opacity 0.25s;

          &:hover {
            opacity: 1;
          }
        }
      }

      > .user {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: right;
        margin-left: auto;
        height: 32px;

        figure {
          width: 24px;
          flex-shrink: 0;
        }

        >.user-info {
          display: flex;
          flex-direction: column;
          justify-content: right;
          margin-right: 6px;

          span {
            color: var(--gray-color);
            font-size: 10px;
            text-align: right;

            @include ellipsis;
          }
        }
      }

      > .points {
        position: absolute;
        right: 5px;
        top: 5px;
        opacity: .5;
        transition: opacity .25s;

        &:hover {
          opacity: 1;
        }
      }
    }

    >.body {
      margin-top: 12px;
      color: var(--COLOR-000533);
      font-size: 12px;
    }

    > .footer {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;

      >.action {
        padding: 0 6px;
        background: #E0E3EC;
        border-radius: 2px;
        color: var(--COLOR-516395);
        text-align: center;
        cursor: pointer;
        display: flex;
        height: 16px;
        justify-content: center;
        align-items: center;

        svg {
          margin: 0 !important;
        }
      }
    }
  }

  .more-actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding: 25px 10px 25px;
    cursor: pointer;
    .action {
      font-size: 11px;
      background-color: var(--BG-FFFFFF);
      padding: 5px 10px;
      border: 1px solid var(--flex-grey1001);
      color: var(--blue-main);
      text-transform: uppercase;
    }

    &.show-more {
      background: transparent !important;
      padding: 15px;
    }
  }

  .more-wrapper {
    transition: max-height .5s;
    &.has-more {
      overflow-y: hidden;
    }
    &.show-more {
      max-height: none !important;
      padding-bottom: 40px;
    }
  }


}
