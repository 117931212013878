#popup-modals {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1049;

  .popup-modal {
    width: 100%;
    background: rgba(0,0,0,.45);
    padding: 50px 15px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    overflow: auto;

    .popup-modal-inner {
      width: 50%;
      min-width: 800px;
      margin: 0 auto;
      background: var(--BG-FFFFFF);
      padding: 15px;
      position: relative;

      h2 {
        text-align: center;
      }

      .popup-modal-close {
        position: absolute;
        background: var(--color);
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--BG-FFFFFF);
        cursor: pointer;
      }
    }

  }
}
