.tab-info-view {
  display: flex;
  position: relative;
  flex-direction: column;
  background: var(--flex-grey1501);
  min-height: 100%;

  >.col-1 {
    border-bottom: 1px dashed var(--COLOR-E8ECEE);
    display: flex;
    padding: 0 16px 2px;
    margin-top: 15px;
    margin-bottom: 5px;

    >.left{
    }

    >.right {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;

      >.dispose {
        background-color: var(--blue-main);
        padding: 8px;
        border: 1px solid #80829933;
        border-radius: 3px;

        .ant-typography {
          color: white;
          margin-left: 5px;
        }

        .icon-wrapper {
          svg {
            color: white;
          }
        }
      }

      >.dispose-disabled {
        background-color: var(--COLOR-F3F4F7);
      }
    }
  }

  >.col-2 {
    padding: 5px 16px;
    display: flex;
    flex-direction: column;

    .costumer-journey-mini {
      margin-top: 15px;
    }
  }

  >.col-3 {
    padding: 16px;

    >.left {
      flex: 1;
    }

    >.right {
      flex: 1;
    }
  }
}
