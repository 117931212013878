.list-fields {
  max-width: 980px;

  .ant-table,
  .ant-select {
    font-size: 12px;
  }

  table {
    thead {
      tr {
        th {
          padding: 7px 5px 7px 3px;
          min-width: 80px;

          &:first-child {
            padding-left: 14px;
          }

          > div {
            .select-wrapper {
              max-width: 120px;
              margin-right: 14px;
            }

            .remove-btn {
              position: absolute;
              right: 3px;
              top: 50%;
              margin-top: -7px;
              opacity: 0;
              transition: opacity 0.25s;
            }
          }

          &:hover {
            background-color: var(--flex-grey1002) !important;

            .remove-btn {
              opacity: 1;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 7px 1px 7px 3px;
          min-width: 80px;
          opacity: 0.7;
          transition: all 0.25s;

          &:first-child {
            padding-left: 14px;
          }
        }

        &:hover {
          td {
            opacity: 1;
          }
        }
      }
    }
  }
}
