@import "../../../../styles/partials/mixins";

.conversation-more-actions-popover-container {
  min-width: 180px;
  ul {
    list-style-type: none;
    margin: 10px 0;
    padding: 0;
    li {
      padding: 5px 12px;
      border-bottom: 1px solid var(--flex-grey1004);
      font-size: 12px;
      transition: background .25s;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background: var(--flex-grey1004);
      }
    }
  }
}

.conversation-title-view-icons {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .icon-wrapper svg {
    border-color: var(--flex-grey1002);
  }

  .ant-select {
    max-width: 120px;
    margin-right: 2px;

    .ant-select-selector {
      padding: 0 2px 0 8px;
      height: 24px;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        font-size: 12px;
        line-height: 22px;

        @include ellipsis;
      }
    }

    .ant-select-arrow {
      right: 5px;
      font-size: 10px;
      margin-top: -5px;
    }
  }

  .left {
    display: flex;
  }

  .right {
    margin-left: auto;
    display: flex;

    .icon-wrapper {
      display: flex;
      margin: 2px;
      border: 1px solid var(--flex-grey1002);
      padding: 4px;
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }
}
