@import "../../../styles/partials/mixins";

.audio-settings-modal {
  .ant-modal-body {
    text-align: left;
    align-items: start;
  }
  h2 {
    font-size: 26px;
    font-weight: lighter;
    color: var(--color);
    opacity: .8;
    margin: 10px 0 25px;
    text-align: left;
  }
  .hr {
    display: block;
    margin: 10px 15px 20px;
    border-bottom: 1px dashed var(--flex-grey1002);
  }
  .select-wrapper {
    width: 100%;
    margin-bottom: 10px;
    .ant-select-selection-placeholder {
      flex: none;
    }
  }
  .ant-modal-footer {
    display: none !important;
  }

  .form-submit .ant-form-item-control-input-content {
    > button {
      padding: 5px 15px !important;
      border-radius: 2px !important;
    }
    .ant-btn-primary {
      margin-left: 5px;
    }
  }

  .settings-table {
    color: var(--COLOR-000533);
    text-align: left;
    width: 100%;
    > div {
      display: flex;

      .echo-test-passed {
        color: var(--green-main);
        margin-bottom: 8px;
      }
      .echo-test-btn {
        margin-bottom: 10px;
        padding: 4px 25px !important;
      }
      .label {
        padding-top: 3px;
        opacity: .7;
        width: 35%;
      }
      .select {
        width: 65%;
        display: flex;
        .audio-player-wrapper {
          margin-bottom: 9px;
          margin-left: 5px;
        }
        .ant-select-selection-item {
          @include ellipsis;
        }
      }
    }
  }
}
