@import "../../styles/partials/mixins";

.main-header {
  background-color: var(--COLOR-F7F7F9);
  border-bottom: 1px solid var(--COLOR-E8ECEE);
  width: 100%;
  height: 95px;
  padding: 7px 0;

  >.header-contents {
    width: 100%;
    height: 100%;
    display: flex;

    > .col {
      margin: 0 5px;

      &.empty {
        width: 15%;
      }
    }
  }
  .socket-disconnected {
    background: var(--red-main);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 2px 10px;
    font-size: 14px;
    z-index: 99;
    min-height: 10px;
    &:hover {
      transition: all;
      span {
        max-height: 50px;
      }
    }
    span {
      transition: max-height .7s;
      max-height: 0;
      overflow: hidden;
      display: block;
      color: white;
    }
  }
}

@media screen and (max-width: 1600px) {
  .main-header {
    .col {
      &.empty {
        display: none;
      }
    }
  }
}
