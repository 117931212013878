.echo-test-wrapper {
  margin-bottom: 20px;
  margin-top: 10px;

  > .question {
    > .question {
      margin-left: 5px;
    }
  }

}
