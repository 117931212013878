@import "../../../styles/partials/mixins";

.changelog {
  padding: 20px;
  .content {
    .scroll-view {
      padding: 10px;
    }
    h2 {
      margin-bottom: 10px;
    }
    ul.versions {
      li.version {
        padding-bottom: 10px;

        .version {
          margin-left: 10px;
          opacity: .5;
        }

        .title {
          cursor: pointer;
        }

        &.expanded {
          ul.changes {
            max-height: 500px;
          }
        }

        ul.changes {
          list-style-type: none;
          padding: 0;
          margin-top: 5px;
          max-height: 0;
          overflow: hidden;
          transition: max-height .25s;

          li.change {
            font-size: 12px;
            padding: 3px 0 3px 10px;
            border-bottom: 1px solid var(--flex-grey1001);
            position: relative;
            white-space: pre-wrap;
            &:hover {
              .border {
                width: 100%;
                opacity: .2;
              }
              .type, .text {
                opacity: 1;
              }
            }
            .border {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              background-color: grey;
              width: 4px;
              transition: all 1s;
              z-index: -1;
            }
            &.bugfix {
              .border {
                background-color: var(--red-main);
              }
              .type {
                background-color: var(--red-main);
              }
            }
            .type {
              font-size: 9px;
              text-transform: uppercase;
              background-color: grey;
              display: inline-block;
              color: white;
              padding: 1px 3px 0;
              border-radius: 3px;
              margin-right: 6px;
              opacity: .85;
              transition: opacity .25s;
              width: 45px;
              text-align: center;
              height: 16px;
              flex-shrink: 0;
            }
            &.improvement {
              .border {
                background-color: var(--green);
              }
              .type {
                background-color: var(--green);
              }
            }
            .text {
              padding: 2px;
              display: flex;
              align-items: center;
              opacity: .8;
              transition: opacity .25s;
            }
          }
        }
      }
    }
  }
}
