.tab-mini-view {
  .conversation-message-row {
    &.row-right,
    &.row-left {
      display: flex;
      margin: 5px;
      align-items: center;

      > .conversation-message {

        &.highlighted {
          .conversation-message-content {
            box-shadow: 0 0 5px yellow;
          }
        }

        &.click-highlighted {
          .conversation-message-content {
            box-shadow: 0 0 3px yellow;
          }
        }

        .conversation-message-content {
          padding: 5px 10px;
          border-radius: 3px;
          font-size: 12px;
          position: relative;

          > .channel-icon {
            position: absolute;
            top: -5px;
            transition: opacity 0.25s;
            z-index: 2;

            &:hover {
              opacity: 1;
            }
          }
        }

      }
    }

    &.row-right {
      flex-direction: row-reverse;
      justify-content: right;

      >.conversation-message {

        .conversation-message-content {

          border: 1px solid var(--flex-grey1501);
          background: var(--COLOR-F6F8F9);

          > .channel-icon {
            right: -5px;
          }
        }


      }

      > .avatar-wrapper, > .avatar {
        margin-left: 6px;
      }
    }

    &.row-left {
      justify-content: left;

      >.conversation-message {

        .conversation-message-content {
          background: var(--BG-FFFFFF);
          border: 1px solid var(--flex-grey1501);

          > .channel-icon {
            left: -5px;
          }
        }

      }

      > .avatar-wrapper, > .avatar {
        margin-right: 6px;
      }
    }
  }
}
