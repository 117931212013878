/* cyrillic-ext */
@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./static/Manrope-Medium.ttf);
}

@font-face {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./static/Manrope-Bold.ttf);
}
