@import "../../../../../styles/partials/mixins";

.recharts-tooltip-wrapper,
.recharts-legend-wrapper {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    li {
      white-space: nowrap;
      display: flex;
      align-items: center;
      .text {
        font-size: 11px;
      }
      .recharts-legend-item-title,
      .recharts-tooltip-item-title {
        .text {
          white-space: nowrap;
          max-width: 120px !important;
          @include ellipsis;
        }
      }
      .recharts-tooltip-item-title {
        width: 130px;
      }
      .recharts-legend-item-color,
      .recharts-tooltip-item-color {
        width: 20px;
        height: 15px;
        margin-right: 6px;
        border-style: solid;
        .bg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}
.recharts-tooltip-wrapper {
  background: var(--BG-FFFFFF);
  padding: 10px;
  border: 1px solid var(--flex-grey1501);

  ul {
    li {
      .recharts-tooltip-item-title {
        opacity: .8;
      }
      .recharts-tooltip-item-value {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
}

.recharts-tooltip {
  .label {
    margin-bottom: 5px;
  }
  .recharts-legend-item-color {
    width: 12px;
    height: 8px;
  }
}

.recharts-wrapper {
  .recharts-legend-wrapper {
    ul {
      display: flex;
      gap: 5px 13px;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;

      li {
        .text {
          max-width: 110px;
        }
      }
    }
  }

  &.legend-horizontal {
    .recharts-legend-wrapper {
      ul {
        flex-direction: row;
      }
    }
  }
}

.recharts-cartesian-axis.recharts-yAxis .recharts-cartesian-axis-ticks,
.recharts-polar-radius-axis .recharts-polar-radius-axis-ticks {
  g:first-child {
    display: none;
  }
  text {
    white-space: nowrap;
  }
}

.widget-type-bar {
  .recharts-wrapper {

    &.legend-right {
      .recharts-legend-wrapper {
        margin-right: -5px;
      }
    }

    &.legend-left {
      .recharts-legend-wrapper {
        margin-left: -10px;
      }
    }

    &.legend-top {
      .recharts-legend-wrapper {
        margin-top: -12px;
      }
    }

    &.legend-bottom {
      .recharts-legend-wrapper {
        margin-bottom: -2px;
      }
    }

  }
}

.widget-type-line {
  .recharts-wrapper {

    &.legend-right {
      .recharts-legend-wrapper {
        margin-right: -5px;
      }
    }

    &.legend-left {
      .recharts-legend-wrapper {
        margin-left: -10px;
      }
    }

    &.legend-top {
      .recharts-legend-wrapper {
        margin-top: -12px;
      }
    }

    &.legend-bottom {
      .recharts-legend-wrapper {
        margin-bottom: -8px;
      }
    }

  }
}

.widget-type-pie {
  .recharts-wrapper {

    &.legend-right {
      .recharts-legend-wrapper {
        margin-right: 5px;
      }
    }

    &.legend-left {
      .recharts-legend-wrapper {
        margin-left: 5px;
      }
    }

  }
}
