.voice-controller-info {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  font-size: 11px;
  height: 14px;
  margin-bottom: 5px;

  .info-section {
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid var(--flex-grey1501);
    white-space: nowrap;

    &.skrink {
      flex-shrink: 1;
    }

    &:first-child {
      flex-grow: 1;
    }

    &:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }

    .title {
      font-weight: lighter;
      opacity: 0.5;
    }
  }
}
