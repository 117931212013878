.notifications {
  position: absolute;
  top: 125px;
  width: 360px;
  right: 15px;
  overflow-x: hidden;
  background: transparent;
  z-index: 120;

  > .body {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    background: transparent;
  }
}
