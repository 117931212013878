@import "../../../styles/partials/mixins";

.stats-form-sider {
  .content {
    width: 80%;
    min-width: 1200px;
    .side-by-side .scroll-view {
      padding: 15px 5px;
      h2 {
        text-align: left;
        padding-left: 15px;
        text-transform: initial;
        margin-bottom: 8px;
      }
      .create-options {
        padding: 0 15px;
        width: 100%;
        display: flex;
        & > div {
          text-align: center;
          padding: 4px 12px;
          cursor: pointer;
          border-radius: 32px;
          font-size: 11px;
          &.active {
            color: var(--BG-FFFFFF) !important;
            background: var(--color);
            .text, svg {
              color: var(--BG-FFFFFF) !important;
            }
          }
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
    .select-existing,
    .select-template {
      margin: 0 auto;
      position: relative;
      flex-grow: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .widget-list {
        overflow: hidden auto;
        padding: 0 20px 20px;
        height: 100%;
        min-height: 100%;

        .widget-list-head {
          display: flex;
          width: 100%;
          margin-top: 15px;
          .left {
            h3 {
              font-weight: lighter;
              font-size: 14px;
              margin: 5px 0 0;
            }
          }
          .right {
            margin-left: auto;
            position: relative;
            input {
              border-radius: 4px;
              padding: 5px 10px;
              border: 0;
              background: var(--grey-middle);
              outline: none;
              color: #808299;

              &::placeholder {
                opacity: 0.8;
              }
            }
            .icon-wrapper {
              position: absolute;
              right: 8px;
              top: 50%;
              margin-top: -8px;
              svg {
                color: #808299;
              }
            }
          }
        }
        table {
          width: 100%;
          font-size: 12px;
          margin-top: 5px;
          thead {
            background: #E8ECEE;
            tr {
              td {
                color: #808299;
                text-transform: uppercase;
                padding: 5px 3px;
                font-weight: bold;
              }
            }
          }
          tbody {
            tr {
              &.selected {
                background-color: var(--flex-grey1501);
                td {
                  opacity: 1;
                }
              }

              &.widget-item {
                cursor: pointer;
              }
              &.space {
                td {
                  height: 8px;
                }
                &.space-bottom {
                  td {
                    border-bottom: 1px solid var(--flex-grey1501);
                  }
                }
              }
              &:hover {
                td {
                  opacity: 1 !important;
                }
              }
              td {
                opacity: .75;
                transition: all .25s;
                &:first-child {
                  padding-left: 5px;
                }
                &:last-child {
                  padding-right: 5px;
                }
                &.widget-icon {
                  width: 40px;
                  opacity: .25;
                  svg g,
                  svg path {
                    fill: var(--COLOR-000533) !important;
                  }
                }
                &.title {
                  opacity: 1;
                  padding-right: 5px;
                  position: relative;
                  font-weight: bold;
                  font-size: 13px;
                  span {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 2px;
                    bottom: 0;
                    @include ellipsis;
                  }
                }
                &.created_at {
                  width: 100px;
                }
                &.avatar {
                  width: 20px;
                }
              }
            }
          }
        }
      }
      .add-btns {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        gap: 15px;

        .clone {
          display: flex;
          gap: 5px;
          .text {
            opacity: .7;
          }
        }

        .add-btn {
          background: var(--blue-main);
          padding: 10px 20px;
          border-radius: 3px;
          width: 260px;
          font-size: 18px;
          height: auto;
          .text, svg {
            color: white;
          }
          .icon-wrapper {
            margin-right: 0;
          }
          .text {
            text-align: center;
          }
        }
      }
    }

    .entity-form-edit,
    .entity-form-add {
      h2 {
        display: none;
      }
    }
  }
  .side-by-side {
    display: flex;
    height: 100%;
    overflow: hidden;
    & > div {
      width: 50%;
      height: 100%;
      &.preview {
        flex: 1;
        position: relative;
        border-left: 1px solid var(--flex-grey1501);
        padding: 60px 15px;
        background-color: var(--COLOR-F3F3F5);
        overflow: auto;
        display: flex;
        .controllers {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 8px 6px 6px;
          background: var(--flex-grey1501);
          gap: 10px;
          align-items: center;
          justify-content: center;
          z-index: 1;
          label {
            font-weight: bold;
            opacity: .7;
          }
          .ant-select {
            width: 70px;
          }
        }
        .display-view {
        }
        .stats-widget-wrapper {
        }
        .grids {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: .5;
          overflow: hidden;
          .lines {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 0;
            left: 15px;
            right: 0;
            top: 60px;
            bottom: -50px;
            .line {
              position: absolute;
              &.line-x {
                top: -500px;
                bottom: 0;
                border-right: 1px solid var(--flex-grey1501);
              }
              &.line-y {
                left: -500px;
                right: 0;
                border-bottom: 1px solid var(--flex-grey1501);
              }
            }
          }
        }
      }
    }
  }
}
