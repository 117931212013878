@import "../../../styles/partials/mixins";

.contact-selector,
.conversation-selector {
  flex-direction: column;

  .ant-select-selection-search {
    padding-right: 0 !important;
    left: 0 !important;
    right: 0 !important;
    .ant-select-selection-search-input {
      padding-right: 25px !important;
      padding-left: 7px !important;
    }
  }

  .ant-select-selection-item {
    font-size: 12px;

    .row-2 {
      display: none;
    }

    .row-1 {
      .id {
        opacity: 0.6;
        margin-right: 5px;

        &::before {
          content: "#";
        }
      }

      .title {
        color: var(--blue-main);
        font-weight: normal;
        max-width: 200px;
        text-overflow: ellipsis;
      }

      .status {
        display: none;
      }
    }
  }
}

.conversation-select,
.contact-select {
  .ant-select-item {
    border-bottom: 1px solid var(--flex-grey1001);

    .ant-select-item-option-state {
      display: none;
    }

    .ant-select-item-option-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 11px;
      justify-items: flex-start;
      align-items: flex-start !important;
      color: var(--COLOR-000533);

      .row-1,
      .row-2 {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .row-1 {
        .id {
          opacity: 0.6;
          margin-right: 5px;

          &::before {
            content: "#";
          }
        }

        .title {
          color: var(--blue-main);
          font-weight: normal;
          max-width: 200px;

          @include ellipsis;
        }

        .status {
          opacity: 0.4;
          margin-left: auto;
        }
      }

      .row-2 {
        .queue {
          font-weight: bold;
          margin-right: 8px;
        }

        .agent {
          font-weight: bold;
        }
      }

      .primary {
        margin-left: auto;
        background-color: var(--blue-main);
        color: #FFFFFF;
        font-size: 8px;
        padding: 1px 5px;
        border-radius: 8px;
        display: block;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
  }
}
