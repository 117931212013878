.voice-call-modal {
  color: var(--COLOR-000533) !important;
  .ant-modal-content {
    padding: 10px 20px;
    .queue-route-selection {
      width: 100%;
    }
    .ant-select {
      margin-bottom: 5px;
      width: 100%;
      .ant-select-selection-placeholder {
        text-align: left;
      }
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 16px 16px 5px 16px;
    }
    .ant-modal-footer {
      padding: 0 16px 16px 16px;
      border-top: 0;
      .ant-btn-default {
        display: none;
      }
      .ant-btn-primary {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }

}
