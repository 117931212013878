.header-user-presence {
  height: 100%;
  width: 130px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .preview-users {
    height: 60px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--COLOR-FDFDFD);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    position: relative;

    .chevron {
      height: 100%;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
    }

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        height: 100%;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -14px;
        position: relative;

        figure {
          background: linear-gradient(90deg, #EC008C 0%, #FC6767 100%);
        }

        &:first-child {
          margin-left: 0;
        }

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            z-index: #{10 - $i};
          }
        }

        &:hover {
          z-index: 1000;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .header-user-presence {
    padding: 0 6px;
  }
}
