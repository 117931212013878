.call-transcript {
  .call-transcript-inner {

    max-width: 600px;
    width: 100%;
    padding: 15px;
    max-height: 400px;
    overflow-y: auto;

    .call-transcript-info {
      display: flex;
      font-size: 11px;
      gap: 10px;
      padding-bottom: 15px;
      .data {
        display: flex;
        gap: 5px;
        .key {
          opacity: .5;
          &:after {
            content: ":";
          }
        }
      }
    }

    &.has-error {
      background: var(--red-main);
      color: #FFFFFF;
      text-align: center;
      width: 100%;
    }

    &.playing {
      .word {
        opacity: .7;
      }
    }

    .sentence {
      font-size: 12px;
      padding: 5px 15px;
      border-radius: 10px;
      margin-bottom: 5px;
      clear: both;
      position: relative;

      &.playing {
        .word {
          opacity: .9;
        }
      }

      .time {
        font-size: 10px;
        position: absolute;
        top: -13px;
        display: flex;
        gap: 5px;
        color: var(--color);
        background: var(--orange-light);
        padding: 1px 3px 0;
        border-radius: 3px;
        opacity: 0;
        transition: opacity .25s;
      }

      &:hover {
        .time {
          opacity: 1;
          span {
            white-space: nowrap;
          }
        }
      }

      &.speaker-1 {
        background: var(--flex-grey1003);
        float: left;
        .time {
          left: 12px;
        }
      }

      &.speaker-0 {
        background: var(--blue-main);
        float: right;
        color: #FFFFFF;
        .time {
          right: 12px;
        }
      }

      .text {
        .word {
          margin-right: 3px;
          display: inline-block;
          &:last-child {
            margin-right: 0;
          }

          &.unconfident {
            opacity: .8;
          }

          &.playing {
            opacity: 1;
            font-weight: bold;
          }
        }
      }
    }
  }
}
