#logo {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 5px;

  &:hover {
    .icon-wrapper {
      &.theme {
        opacity: .6;
      }
    }
    .version {
      opacity: .6;
    }
  }

  img {
    height: 50px;
    object-fit: contain;
  }

  .icon-wrapper {
    &.theme {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: 12px;
      opacity: .25;
      transition: opacity .5s;
      &:hover {
        opacity: 1;
      }
    }
  }

  .version {
    font-size: 8px;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: -9px;
    opacity: .25;
    transition: opacity .5s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
